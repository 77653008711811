import {useState, useEffect} from 'react'
import {  postCaller ,deleteCaller, updateCaller } from '../../../services/api';
import {MdDelete ,MdPostAdd} from 'react-icons/md'
import  {IoMdSave}  from 'react-icons/io'
import Swal from "sweetalert2";
import '../downline.css'
import ToogleButton from '../../../component/toggleButton/ToogleButton';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import getLocalData from '../../../utilities/LocalStorageData.utilities';

const FancyAssignAdd = ({defaultLimits, userName}) => {
    const [formMode, setFormMode] = useState('Add');
    const [fancyID, setFancyID] = useState(null);
    const [allowAddBookmaker, setAllowAddBookmaker] = useState(true);
    const [fancyData, setFancyData] = useState({
            event_id:userName.event_id,
            match_id:userName.match_id,
            sport_id: userName.sport_id,
            market_type:userName.market_id,
            agent_id: userName.id,
            is_sus: 0,
            is_active: 0,
            is_com: defaultLimits.is_com || 0,
            msg: '',
            min_stake: defaultLimits.min_stake || 100,
            max_stake: defaultLimits.stake_limit,
            bet_delay: parseInt(defaultLimits.delay),
            max_market_limit: defaultLimits.max_market_limit,
            market_name: '',
            runNo: '',
            runYes: '',
            oddsNo: '',
            oddsYes: '',
            size : '',
            back: '',
            lay: '',
            sus_time: userName.sus_time
    });
    const [endDate, setEndDate] = useState(null);
    const [scope, setScope] = useState({})
    const [isAdded,setisAdded] = useState(false)

    useEffect(() => {
        const {markets, result} = getLocalData('scope')
        setScope({markets, result})
      }, []);

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setFancyData({ ...fancyData, end_date: date });
    };

    const handleSubmit = async () => {
        if (formMode === 'Add') {
        const response = await postCaller('api/v1/fancies', {
            event_id:userName.event_id,
            match_id:userName.match_id,
            sport_id: userName.sport_id,
            market_type:userName.market_id,
            agent_id: userName.id,
            is_sus: fancyData.is_sus,
            is_active: fancyData.is_active,
            is_com: fancyData.is_com,
            msg: fancyData.msg,
            min_stake: fancyData.min_stake,
            max_stake: fancyData.max_stake,
            bet_delay: fancyData.bet_delay,
            max_market_limit: fancyData.max_market_limit,
            market_name: fancyData.market_name,
            sus_time: userName.sus_time,
            runNo: fancyData.runNo,
            runYes: fancyData.runYes,
            oddsNo: fancyData.oddsNo,
            oddsYes: fancyData.oddsYes,
            size: fancyData.size,
            back: fancyData.back,
            lay: fancyData.lay,
            end_date: fancyData.end_date
        });
            if(response.success===true){
                const Toast = Swal.mixin({
                  toast: true,
                  background:"black",
                  color:"white",
                  showConfirmButton: false,
                  timer: 2000,
                  width:450,
                  padding:".5rem"
                })
                Toast.fire({
                  icon: 'success',
                  title: "Fancy Added Successfully",
                  background: 'white',
                  color: 'green'
                })
            } else if (response.success===false) {
                const Toast = Swal.mixin({
                    toast: true,
                    background:"black",
                    color:"white",
                    showConfirmButton: false,
                    timer: 2000,
                    width:450,
                    padding:".5rem"
                  })
                  Toast.fire({
                    icon: 'error',
                    title: response.message,
                    background: 'white',
                    color: 'red'
                  })
            }
           const fancyId= response.data.fancyID
           setFancyID(fancyId)
           setFormMode('Update')
        } else if (formMode === 'Update'  ) {
            const response = await updateCaller(`api/v1/fancies/${fancyID}`,{
                is_sus: fancyData.is_sus,
                is_active: fancyData.is_active,
                is_com: fancyData.is_com,
                msg: fancyData.msg,
                min_stake: fancyData.min_stake,
                max_stake: fancyData.max_stake,
                bet_delay: fancyData.bet_delay,
                max_market_limit: fancyData.max_market_limit,
                market_name: fancyData.market_name,
                runNo: fancyData.runNo,
                runYes: fancyData.runYes,
                oddsNo: fancyData.oddsNo,
                oddsYes: fancyData.oddsYes,
                size: fancyData.size,
                back: fancyData.back,
                lay: fancyData.lay,
                end_date: fancyData.end_date
            })
            if(response.success===true){
                const Toast = Swal.mixin({
                  toast: true,
                  background:"black",
                  color:"white",
                  showConfirmButton: false,
                  timer: 2000,
                  width:450,
                  padding:".5rem"
                })
                Toast.fire({
                  icon: 'success',
                  title: "Fancy Updated Successfully",
                  background: 'white',
                  color: 'green'
                })
            }
            
        }
        setisAdded(true)
    }
    const handleDelete = async(item) => {

        !isAdded && setisAdded(false)

        if (!fancyID) {
            const Toast = Swal.mixin({
                toast: true,
                background: "black",
                color: "white",
                showConfirmButton: false,
                timer: 2000,
                width: 450,
                padding: ".5rem"
            })
            Toast.fire({
                icon: 'success',
                title: "Fancy Table Deleted successfully",
                background: 'white',
                color: 'green'
            })
            setAllowAddBookmaker(false)
            return
        }
        
        Swal.fire({
            title: 'Are you sure?',
            text: `You are about to delete a fancy.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed){
                const deleteResponse =await deleteCaller(`api/v1/fancies/${fancyID}`)
                if(deleteResponse.success===true){
                    Swal.fire({
                        title: 'Submitted',
                       text: 'your fancy Deleted Successfully',
                       icon: 'success',
                       showConfirmButton:false,
                       timer:1000,
                       background: 'white',
                       color: 'green'
                    }).then(response=>{
                        if (response) {
                            setAllowAddBookmaker(false)
                        }
                    })
                }
            }})
       
    }
    const handleInputChange = (field, value) => {
        if (field === 'oddsNo') {
            const newOddsNo = parseFloat(value);
        if (!isNaN(newOddsNo) && newOddsNo >= 0 && newOddsNo <= fancyData.oddsYes) {
            setFancyData({
                ...fancyData,
                oddsNo: newOddsNo,
            });
        }
        } else {
            setFancyData({
                ...fancyData,
                [field]: value,
            });
        }
    };
    const handleOddsChange = (field, value) => {
        if (field === 'oddsYes') {
           
            const newOddsYes = parseFloat(value);
            if (!isNaN(newOddsYes) && newOddsYes >= 0 && newOddsYes >= fancyData.oddsNo) {
                setFancyData({
                    ...fancyData,
                    oddsYes: newOddsYes,
                });
            }
        } else {
            setFancyData({
                ...fancyData,
                [field]: value,
            });
        }
    };
    return (
        <div>
        { allowAddBookmaker ?
        <div>
            <table width="100%">
                <thead>
                    <tr  id='fancy-head-coloumn'>
                        <th> SELECTION </th>
                        <th> STAKE </th>
                        <th> DELAY </th>
                        <th> END DATE </th>
                        {["khado_markets", "odd_even_markets"].includes(fancyData.market_type) ? null : (<th> NO (OUTCOME) </th>)}
                        {["khado_markets", "odd_even_markets"].includes(fancyData.market_type) ? null : (<th> YES (OUTCOME) </th>)}
                        {["khado_markets", "odd_even_markets"].includes(fancyData.market_type) ? null : (<th> NO (ODDS) </th>)}
                        {["khado_markets", "odd_even_markets"].includes(fancyData.market_type) ? null : (<th> YES (ODDS) </th>)}
                        {!["khado_markets"].includes(fancyData.market_type) ? null : (<th> SIZE </th>)}
                        {!["odd_even_markets"].includes(fancyData.market_type) ? null : (<th> BACK </th>)}
                        {!["odd_even_markets"].includes(fancyData.market_type) ? null : (<th> LAY </th>)}
                        <th> SUSPEND </th>
                        <th> COMM </th>
                        <th> ACTIVE </th>
                        <th> ACTIONS </th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr className='fancy-head-row'>
                        <td >
                            <input type="text" autoComplete="off" name="marketName" className='fancy-stake-input' value={fancyData.market_name} onChange={(e) => setFancyData({ ...fancyData, market_name: e.target.value })} placeholder='Fancy Selection Name' /> <br />
                            <input type="text" autoComplete="off" name="msg"  className='fancy-stake-input' value={fancyData.msg} onChange={(e) => setFancyData({ ...fancyData, msg: e.target.value })} placeholder='Message' />
                        </td>

                        <td >
                            <input type="number" autoComplete="off" name="min_stake" className='fancy-stake-input 'value={fancyData.min_stake} onChange={(e) =>{  const value = e.target.value;
                            if (value >= 0) {
                                setFancyData({ ...fancyData, min_stake: value });
                            }}} placeholder="Min Stake" disabled/> <br />

                            <input type="number" autoComplete="off" name="max_stake" className='fancy-stake-input' value={fancyData.max_stake} onChange={(e) => {  const value = e.target.value;
                            if (value >= 0) {
                                setFancyData({ ...fancyData, max_stake: value });
                            }}} placeholder='Max Stake' size="5" />
                        </td>

                        <td >
                            <input type="number" autoComplete="off" name="Bet Delay "  className='fancy-stake-input' value={fancyData.bet_delay} onChange={(e) => {  const value = e.target.value;
                            if (value >= 0) {
                                setFancyData({ ...fancyData, bet_delay: value });
                            }}} placeholder='Bet Delay ' /> <br />

                            <input type="number" autoComplete="off" name="max_market_limit"  className='fancy-stake-input' value={fancyData.max_market_limit} onChange={(e) => {  const value =e.target.value;
                            if (value >= 0) {
                                setFancyData({ ...fancyData, max_market_limit: value });
                            }}} placeholder={'max_market_limit'} />
                        </td>

                        <td>
                        <DatePicker
                            name="end_date"
                            selected={endDate}
                            onChange={handleEndDateChange}
                            showTimeSelect
                            timeFormat="HH:mm"
                            placeholderText="End Date & Time"
                            timeIntervals={15}
                            dateFormat="yyyy-MM-dd HH:mm:ss"
                            className='fancy-stake-input'
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        /> <br /></td>

                        {["khado_markets", "odd_even_markets"].includes(fancyData.market_type) ? null : (   <td >
                            <input type="number" autoComplete="off" className='fancy-input' value={fancyData.runNo} onChange={(e) => {  const value = (e.target.value);
                            if (value >= 0) {
                                setFancyData({ ...fancyData, runNo: value });
                            }}} name="runNo" />
                        </td>)}

                        {["khado_markets", "odd_even_markets"].includes(fancyData.market_type) ? null : (  <td >
                            <input type="number" autoComplete="off" name="runYes" className='fancy-input' value={fancyData.runYes}  onChange={(e) => {  const value = e.target.value;
                            if (value === '' || value >= 0) {
                                setFancyData({ ...fancyData, runYes: value });
                            }}}/>
                        </td>)}
                        
                        {["khado_markets", "odd_even_markets"].includes(fancyData.market_type) ? null : (   <td >
                            <input type="number" autoComplete="off" name="oddsNo" className='fancy-input' value={fancyData.oddsNo}  onChange={(e) => {  const value = e.target.value;
                            if (value >= 0) {
                                setFancyData({ ...fancyData, oddsNo: value });
                            }}}/>
                        </td>)}

                        {["khado_markets", "odd_even_markets"].includes(fancyData.market_type) ? null : (  <td >
                            <input type="number" autoComplete="off" name="oddsYes" className='fancy-input' value={fancyData.oddsYes}  onChange={(e) => {  const value = e.target.value;
                            if (value >= 0) {
                                setFancyData({ ...fancyData, oddsYes: value });
                            }}}/>
                        </td>)}

                        {!["khado_markets"].includes(fancyData.market_type) ? null : (  <td >
                            <input type="number" autoComplete="off" name="size" className='fancy-input' value={fancyData.size}  onChange={(e) => {  const value = e.target.value;
                            if (value >= 0) {
                                setFancyData({ ...fancyData, size: value });
                            }}}/>
                        </td>)}

                        {!["odd_even_markets"].includes(fancyData.market_type) ? null : (  <td >
                            <input type="number" autoComplete="off" name="back" className='fancy-input' value={fancyData.back}  onChange={(e) => {  const value = e.target.value;
                            if (value >= 0) {
                                setFancyData({ ...fancyData, back: value });
                            }}}/>
                        </td>)}

                        {!["odd_even_markets"].includes(fancyData.market_type) ? null : (  <td >
                            <input type="number" autoComplete="off" name="lay" className='fancy-input' value={fancyData.lay}  onChange={(e) => {  const value = e.target.value;
                            if (value >= 0) {
                                setFancyData({ ...fancyData, lay: value });
                            }}}/>
                        </td>)}
                        <td >
                        <div className="toggle-password">
                        <ToogleButton  defaultChecked={fancyData.is_sus===1}  value={fancyData.is_sus} onChange={(e) => setFancyData({ ...fancyData, is_sus: e ? 1 : 0 })} />
                        </div>
                        </td>
                        <td>
                            <div className="toggle-password">
                            <ToogleButton defaultChecked={fancyData.is_com===1}  value={fancyData.is_com} onChange={(e) => setFancyData({ ...fancyData, is_com: e ? 1 : 0 })} />
                            </div>
                        </td>
                        <td>
                            <div className="toggle-password">
                            <ToogleButton defaultChecked={fancyData.is_active===1}  value={fancyData.is_active} onChange={(e) => setFancyData({ ...fancyData, is_active: e ? 1 : 0 })} />
                            </div>
                        </td>

                        <td>
                            <div className="toggle-password">
                                <div className='actions_tab'>
                                    <button
                                        className='fancy-submit'
                                        disabled={isAdded && !scope?.markets?.update}
                                        onClick={handleSubmit}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter" || e.key === " " || e.code === "Space") {
                                                e.preventDefault();
                                                handleSubmit();
                                            }
                                        }}
                                    >
                                        {formMode === 'Add' ? (
                                            <MdPostAdd className="icon" />
                                        ) : (
                                            <IoMdSave className="icon" />
                                        )}
                                    </button>
                                    <button
                                        className='fancy-cancel'
                                        disabled={isAdded && !scope?.markets?.delete}
                                        onClick={handleDelete}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter" || e.key === " " || e.code === "Space") {
                                                e.preventDefault();
                                                handleDelete();
                                            }
                                        }}
                                    >
                                        <MdDelete className="icon" />
                                    </button>
                                </div>
                            </div>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div> : null}
        </div>
    )
}

export default FancyAssignAdd