
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { AiOutlineClose } from 'react-icons/ai'
import { getCaller, postCaller, updateCaller } from '../../services/api';
import Swal from "sweetalert2";
import '../customGames/custom.css'
import Button from '../../component/button/Button';
import Select from 'react-select'

const CustomAddLeague = () => {
    const [sportList, setSportList] = useState([]);
    const navigate = useNavigate()
    const { state } = useLocation()
    const updateLeagueData = state?.leagueData ?? {};
    const isAddMode = !updateLeagueData.id
    const validationLeague = Yup.object().shape({
        name: Yup.string()
            .required('League Name is Required'),
        ...isAddMode ? { sport_name: Yup.string().required('Sport Name is Required') } : {},
    });

    // const getUpdateData= async ()=>{
    //     formik.setValues(values=>({
    //         ...values,
    //         name:updateLeagueData.name,
    //         ...isAddMode? {sport_name:updateLeagueData.sport_name}: {},
    //     }))
    // }

    // useEffect(()=>{
    //     getUpdateData()
    // },[])

    useEffect(() => {

        async function getSport() {
            let sportResponse = await getCaller('api/v1/sports/management/getSport');

            setSportList(sportResponse?.data)
        }
        getSport()
    }, [])

    const formik = useFormik({
        initialValues: {
            name: updateLeagueData ? updateLeagueData.name : '',
            ...isAddMode ? { sport_name: "" } : {}
        },
        validationSchema: validationLeague,
        onSubmit: async (values) => {
            let data = {
                name: values.name,
                ...isAddMode ? { sport_name: JSON.parse(values.sport_name).name } : {},
                ...isAddMode ? { sport_id: JSON.parse(values.sport_name).id } : {},
                ...!isAddMode ? { sport_id: updateLeagueData.sport_id } : {},
                ...!isAddMode ? { slug: updateLeagueData.slug } : {}
            }
            if (isAddMode) {
                Swal.fire({
                    title: 'Are you sure?',
                    text: `You want to Add this League (${data.name})`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    allowOutsideClick: () => Swal.isLoading()
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const res = await postCaller(`api/v1/leagues/addLeagues`, data)
                        if (res.success === true) {
                            navigate('/custom_games/custom_league')
                            Swal.fire({
                                title: 'Submitted',
                                text: 'Your League Added Successfully',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1000,
                                background: 'white',
                                color: 'green'
                            }).then(response => {
                                if (response) {

                                }

                            })
                        }
                    }
                })

               
                

            }
            else {

Swal.fire({
    title: 'Are you sure?',
    text: `you want to update this League (${data.name})`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    allowOutsideClick: () => Swal.isLoading()
}).then(async (result) => {
    if (result.isConfirmed) {
    const res = await updateCaller(`api/v1/leagues/updateleagues?id=${updateLeagueData.id}`, values)
     if (res.success === true) {
         navigate('/custom_games/custom_league')
            Swal.fire({
                title: 'Submitted',
                text: 'Your League Updated Successfully',
                icon: 'success',
                showConfirmButton: false,
                timer: 1000,
                background: 'white',
                color: 'green'
            }).then(response => {
                if (response) {

                }
            })
        }
    }
})
            }
        },
    });
    return (
        <>
            {/* <CustomLeague/> */}
            <div className="sport-container">
                <div className='modal-container-two'>
                    <div className='add-agent-head'>
                        <div>
                            {isAddMode ? "Add League" : "Update League"}
                        </div>
                        <div className="ai-close">
                            <AiOutlineClose onClick={() => navigate('/custom_games/custom_league')}

                            />
                        </div>
                    </div>

                    <form onSubmit={formik.handleSubmit} >
                        <div className="add-sport-content">
                            {
                                isAddMode ?
                                    (<div className="add-sport-input">
                                        <label htmlFor="sport"> Sport * </label> <br />
                                        <Select
                                            name="sport_name"
                                            onBlur={formik.handleBlur}
                                            onChange={(selectedOption) => formik.setFieldValue('sport_name', selectedOption.value)}
                                            options={sportList.map((el, i) => ({
                                                value: JSON.stringify(el),
                                                label: el.name
                                            }))}
                                            isSearchable={true}
                                            placeholder="Select Sport"
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    outline: "none",
                                                    height: "2.5rem",
                                                    marginTop: "0.4rem",
                                                    border: "2px solid #cbcbcb",
                                                    borderRadius: "0.25rem",
                                                    width: "100%",
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    zIndex: 20,
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    marginTop: "-7px"
                                                })
                                            }}

                                        />
                                        {formik.errors.sport_name && formik.touched.sport_name && <span className="error" style={{ color: "red" }}>    {formik.errors.sport_name} </span>}
                                    </div>)
                                    : null
                            }
                            <div className="add-sport-input">
                                <label htmlFor="sport"> League Name * </label> <br />
                                <input type="text" autoComplete="off" name="name" value={formik.values.name || ''} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    id="" placeholder='League Name' />
                                {formik.errors.name && formik.touched.name && <span className="error" style={{ color: "red" }}>    {formik.errors.name} </span>}
                            </div>


                        </div>
                        <div className="sport-btn-container">
                            <div>
                                <Button type='submit' className='submit' name={"Submit"} />

                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default CustomAddLeague
