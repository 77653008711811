import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MdDelete, MdEdit } from 'react-icons/md'
import Swal from "sweetalert2";
import '../user/user.css'
import { deleteCaller, getCaller, updateCaller } from '../../services/api'
import ToogleButton from '../../component/toggleButton/ToogleButton'
import Pagination from '../../component/pagination/Pagination';
import Button from '../../component/button/Button';
// import { BiDownload } from 'react-icons/bi';
const ShowManager = () => {
    const navigate = useNavigate()
    const [allManager, setAllManager] = useState([])
    const [totalPage, setTotalPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [fetchedPages, setFetchedPages] = useState({});
    const  limit = 100;
    const [searchField, setSearchField] = useState('');
    const [loading, setLoading] = useState(false);
    let userType = localStorage.getItem('user_role').toLowerCase();
    const showAllManager = async (page=1) => {
        if (fetchedPages[page]) {
            setAllManager(fetchedPages[page]);
            return;
        }

        const response = await getCaller(`api/v1/users/showManager?page=${page}&limit=${limit}`)
        if(response.success === true){
            setAllManager(response?.data)
            setTotalPage(response?.pagination?.totalPages)
            setFetchedPages((prev) => ({ ...prev, [page]: response.data }));
        }
    }
    useEffect(() => {
        if (userType === 'admin') { 
            showAllManager()
        }

    }, [userType])
    
    const onSearchChange = (e) => {
        setSearchField(e.target.value);
    };

    const handlePageChange = async(page) => {
 
    if (page >= 1 && page <= totalPage) {
        setCurrentPage(page);
        await showAllManager(page)
    }
    
  };
    const resultDeclareResult = async (id, currentStatus) => {
        if (loading) return;
        setLoading(true);
        try {
            let newStatus = currentStatus === 1 ? 0 : 1; 
            let message = newStatus === 1 ? 'Result is active' : 'Result is inactive';
            let declareResponse = await updateCaller(`api/v1/users/update/agents?allow_declare_result=${newStatus}&id=${id}`);

            if (declareResponse) {
                showSuccessToast(message);

                setAllManager(prev => {
                    return prev.map(manager => {
                        if (manager.id === id) {
                            return {
                                ...manager,
                                allow_declare_result: newStatus
                            };
                        }
                        return manager;
                    });
                });
            }
        } finally {
            setLoading(false);
        }
    };
    
    const showSuccessToast = (message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 2000,
            width: 300,
            padding: ".5rem",
            background: "#4cbb17",
            color: "white",
        });

        Toast.fire({
            icon: 'success',
            title: message,
            background: 'white',
            color: 'green',
        });
    };
    const filteredManagers = allManager?.filter((manager) =>
        (manager.name && manager.name.toLowerCase().includes(searchField.toLowerCase())) ||
        (manager.user_id && manager.user_id.toLowerCase().includes(searchField.toLowerCase()))
    );

    const handleDeleteUser= async (item)=>{
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to Delete this User (${item.user_id})`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            allowOutsideClick: () => Swal.isLoading()
          }).then(async (result) => {
            if (result.isConfirmed) {
            const res = await deleteCaller(`api/v1/users/deleteUser?id=${item.id}&user_id=${item.user_id}`)
              if(res.success===true){
                Swal.fire({
                    title: 'Submitted',
                   text: 'Your User Deleted Successfully',
                   icon: 'success',
                   showConfirmButton:false,
                   timer:1000,
                   background: 'white',
                   color: 'green',
                }).then(response=>{
                    if(response){
                        setAllManager(allManager.filter((el)=>el.id!==item.id));
                    }
                  })
              }
            } 
          })
    }
    return (
        <>
       <div className='table-border'>
       <div className="wrapper-body">
                        <div className="wrapper-content">
                            <div className="">
                                <input type="search" autoComplete="off" value={searchField} placeholder='Search User' className='result-input' onChange={onSearchChange}/>
                            </div>
                            <div className='agent-btn-container'>
                                {/* <div className="print-btn">
                                    <BiDownload style={{ fontSize: "1.5rem", color: "white" }} />
                                </div> */}
                            <div>
                            <Button className='submit' type='button' name='Add Manager' onClick={() => navigate('/user_create_form')} /> 
                                </div> 
                            </div>
                        </div>
                    </div>
            <table width="100%" >
                <thead>
                    <tr className='custom-row'>
                        <th> # </th>
                        <th> UID </th>
                        <th> NAME </th>
                        <th> P </th>
                        <th> L </th>
                        <th> S </th>
                        <th> Result </th>
                        <th> ACTIONS </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredManagers?.length > 0 ?
                        filteredManagers?.map((el, i) => (
                            <tr className='custom-row' key={i}>
                                <td data-label="#"> {el.id} </td>
                                <td data-label="UID"> {el.user_id} </td>
                                <td data-label="NAME"> {el.name} </td>
                                <td data-label="P" style={{ color: "#11a88d", cursor: "pointer" }} onClick={() => navigate("/user_change_password", {
                                    state: {
                                        passData: el
                                    }
                                })} > P </td>
                                <td data-label="League" style={{ color: "#11a88d", cursor: "pointer"}} onClick={() => navigate("/assign_league_to_manager", {
                                    state: {
                                        passData: el
                                    }
                                })} > L </td>
                                <td data-label="League" style={{ color: "#11a88d", cursor: "pointer"}} onClick={() => navigate("/assign_scope_to_manager", {
                                    state: {
                                        passData: el
                                    }
                                })} > S </td>
                                <td data-label="RESULTS"> 
                                    <ToogleButton defaultChecked={el.allow_declare_result === 1} onChange={() => resultDeclareResult(el.id, el.allow_declare_result)} />
                                </td>
                                <td data-label="ACTIONS"> 
                                <div className='user-btn-action'>
                                   <button className='edit-btn'
                                        onClick={() => navigate('/user_create_form', {
                                            state: {
                                                passData: el
                                            }
                                        })}
                                    >
                                        <MdEdit
                                            style={{
                                                fontSize: '1.5rem',
                                                color: 'white'
                                            }}
                                        />
                                        
                                    </button>
                                    <button className='edit-btn delete-btn'
                                    onClick={()=> handleDeleteUser(el) }
                                    >
                                        <MdDelete
                                            style={{
                                                fontSize: '1.5rem',
                                                color: 'white'
                                            }}
                                        />
                                    </button>
                               
                                    
                                </div>
                                </td>
                            </tr>
                        )):<tr className=''><td className='no-data-coloumn' align='center'  colSpan={10}>No Data</td></tr>
                    }
                </tbody>
            </table>
       </div>
           
              <Pagination
                    currentPage={currentPage}
                    totalPages={totalPage}
                    onPageChange={handlePageChange}
                />
        </>
    )
}

export default ShowManager