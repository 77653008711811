import React, { useEffect, useRef, useState } from 'react';
import ResultTab from './ResultTab';
import { getCaller, postCaller } from '../../services/api';
import Swal from 'sweetalert2';
import '../result/result.css';
import Button from '../../component/button/Button';
import { motion } from 'framer-motion';
import { useFormik } from 'formik';
import SurveillanceModel from '../../component/modal/SurveillanceModel';
import Select from 'react-select';
import Result from './result';
import BookmakerResultTable from './BookmakerResultTable';
import getLocalData from '../../utilities/LocalStorageData.utilities';

const ShowBookmakerResult = () => {
  const [sportList, setSportList] = useState([]);
  const [bookmakerResultData, setBookmakerResultData] = useState([]);
  const [sport, setSport] = useState("");
  const [eventId, setEventId] = useState("");
  const [eventList, setEventList] = useState([]);
  const [bookmakerType, setBookmakerType] = useState("");
  const [bookmakerTypeList, setBookmakerTypeList] = useState([]);
  const [runners, setRunners] = useState([]);
  const [runnerType, setRunnerType] = useState("");
  const [marketExternalId, setMarketExternalId] = useState("");
  const [isOpen, setOpen] = useState(false);
  const bookmakerRef = useRef();
  const resultRef = useRef();
  const eventRef = useRef();
  const sportRef = useRef();
  const [isResultSelected, setIsResultSelected] = useState(false);
  const [callRef, setCallRef] = useState(false);
  const [matchID, setMatchID] = useState('');
  const [pagination, setPagination] = useState([]);
  const [fetchedPages, setFetchedPages] = useState({});
  const [operatorList, setOperatorList] = useState([]);
  const [operatorId, setOperatorId] = useState([]);
  const [scope, setScope] = useState({})
  const operatorRef = useRef();
  const limit = 100;

useEffect(()=>{
  setIsResultSelected(false);
   },[eventId,bookmakerType,sport])
   
  useEffect(() => {
    const {result} = getLocalData('scope')
    const userType = getLocalData('user_role')
    setScope({result, userType})
    async function getSport() {
      let sportResponse = await getCaller('api/v1/sports/management/getSport');
      setSportList(sportResponse?.data);
    }
    getSport();
  }, []);

  const handleSport = async (event) => {
    setEventList([]);
    setEventId("");
    const sport = event.value;
    setSport(event);
    setOperatorId([]);
    setBookmakerType('');
    setRunnerType('');
    const response = await getCaller(`api/v1/events/findEvents?id=${sport}`);
    setEventList(response.data);
  };

  const handleEvent = async (event) => {
    const Event = event.value;
    setEventId(event);
    setOperatorId([]);
    setBookmakerType('');
    setRunnerType('');

    if (Event === "") {
      return;
    }

    const matchID = ((eventList || []).find(m => m.event_id === Event))?.id;
    setMatchID(Event);

    const response = await getCaller(`api/v1/bookmakers/undeclared/match/${Event}`);
    setBookmakerTypeList(response.data);
  };

  const handleBookmakerMarket = async (event) => {
    const bookmaker = event.value;
    setBookmakerType(bookmaker);
    setOperatorId([]);
    setRunnerType('');
    if (bookmaker === "") {
      return;
    }

    const newData = bookmakerTypeList.find(b => b.id === bookmaker);
    if (newData) {
      try {
        setRunners(JSON.parse(newData.runners));
      } catch (error) {
        console.error("Failed to parse runners data:", error);
        setRunners([]);
      }
    } else {
      console.warn("Bookmaker data not found for id:", bookmaker);
      setRunners([]);
    }
  };

  useEffect(() => {
    async function getOperators() {
      const res = await getCaller(`api/v1/results/operatorList`);
      setOperatorList(res.data.operatorList);
    }
    getOperators();
  }, []);

  const options = [{ value: 'all', label: 'Select All' }, ...operatorList.map((el) => ({ value: el, label: el }))];
  const handleOperator = (selectedOptions) => {
    if (selectedOptions.some(option => option.value === 'all')) {
      if (selectedOptions.length === options.length) {
        setOperatorId([]);
      } else {
        setOperatorId(options.slice(1));
      }
    } else {
      setOperatorId(selectedOptions);
    }
  };

  const getOptions = (selectedOptions) => {
    if (selectedOptions?.length === operatorList.length) {
      return operatorList.map((el) => ({ value: el, label: el }));
    }
    return options;
  };

  const handleBookmakerResult = (event) => {
    setIsResultSelected(true);
    setRunnerType(event.value);
    if(event?.value.length){
      const allOperators = operatorList.map(op => ({ value: op, label: op }));
      setOperatorId(allOperators);
    }
  };

  useEffect(() => {
    allBookmakerResult();
  }, []);

  const allBookmakerResult = async (page = 1) => {
    if (fetchedPages[page]) {
      setBookmakerResultData(fetchedPages[page]);
      if (page !== 1) return;
    }

    const res = await getCaller(`api/v1/results/bookmaker?page=${page}&limit=${limit}`);
    setBookmakerResultData(res.data);
    setPagination(res.pagination);
    setFetchedPages((prev) => ({ ...prev, [page]: res.data }));

    if (eventId !== "") {
      const response = await getCaller(`api/v1/bookmakers/undeclared/match/${eventId.value}`);
      setBookmakerTypeList(response.data);
    }
  };

  useEffect(() => {
    const found = bookmakerTypeList?.find(m => m.id === bookmakerType);
    if (found) {
      setMarketExternalId(found.external_id);
    } else {
      setMarketExternalId("");
    }
  }, [bookmakerType, bookmakerTypeList]);

  const formik = useFormik({
    initialValues: {
      runners_name: "",
    },

    onSubmit: async (values, { resetForm }) => {
      const result = JSON.parse(runnerType);
      const data = {
        sport_id: parseInt(sport.value),
        match_id: eventList.find(b => b.event_id === eventId.value)?.id,
        bookmaker_id: bookmakerType,
        result: { sln: result.sln, si: result.si },
        operators_list: operatorId.map(op => op.value),
        market_name: bookmakerTypeList.find(b => b.id === parseInt(bookmakerType))?.market_name,
        selection: result.sln
      };

      Swal.fire({
        title: 'Are you sure?',
        text: `You want to Submit this Result (${result.sln})?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await postCaller(`api/v1/results`, data);
          if (response.success) {
            Swal.fire({
              title: 'Submitted',
              text: response.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1000,
              background: 'white',
              color: 'green'
            }).then(function () {
              setRunnerType("");
              setCallRef(true);
              resetForm();
              allBookmakerResult(1);
              setOperatorId([]);
            });
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              background: "red",
              color: "white",
              showConfirmButton: false,
              timer: 2000,
              width: 300,
              padding: ".5rem"
            });

            Toast.fire({
              icon: 'error',
              iconColor: "white",
              title: response.message
            });
          }
        }
      });
    },
  });

  useEffect(() => {
    if (callRef) {
      if (bookmakerRef.current?.Select || bookmakerRef.current) {
        bookmakerRef.current.setValue("");
      }

      if (resultRef.current?.Select || resultRef.current) {
        resultRef.current.setValue({ label: 'Select Result', value: [] });
      }

      setCallRef(false);
    }
  }, [callRef]);

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      outline: "none",
      height: "2.5rem",
      marginTop: "0.2rem",
      border: "1px solid #cbcbcb",
      borderRadius: "0.25rem",
      width: "100%",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 20,
    }),
    input: (provided) => ({
      ...provided,
      marginTop: "-7px"
    })
  };

  return (
    <>
      <Result />
      <ResultTab />
      <motion.div
        className="wrapper"
        initial={{ opacity: 0, x: '-100vh' }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <form onSubmit={(e) => { e.preventDefault(); setOpen(true); }}>
          <div className="result-sport-container">
            <div className="result-sport-content className-fix">
              <div className="result-fancy-select">
                <label htmlFor="date">Select Sport</label> <br />
                <Select
                  value={sport}
                  onChange={handleSport}
                  options={sportList.map((el) => ({ value: el.id, label: el.name }))}
                  styles={selectStyles}
                  ref={sportRef}
                  placeholder="Select Sport"
                />
              </div>
              <div className="result-event-select" id='bookmaker-select'>
                <label htmlFor="date">Select Events</label> <br />
                <Select
                  value={eventId}
                  onChange={handleEvent}
                  options={eventList.map((el) => ({
                    value: el.event_id,
                    label: `${el.event_name} (${(el.event_date).split('.')[0]})`
                  }))}
                  styles={selectStyles}
                  ref={eventRef}
                  placeholder="Select Event"
                />
              </div>
              <div className="result-event-select" id='bookmaker-select'>
                <label htmlFor="date">Select Bookmaker</label> <br />
                <Select
                  value={bookmakerType ? bookmakerTypeList.map((el) => el.id === bookmakerType).market_name : ''}
                  onChange={handleBookmakerMarket}
                  options={bookmakerTypeList.map((el) => ({ value: el.id, label: el.market_name ?? "n/a" }))}
                  ref={bookmakerRef}
                  styles={selectStyles}
                  placeholder="Select Bookmaker"
                />
              </div>
              <div className="result-event-select" id='bookmaker-select'>
                <label htmlFor="date">Select Result</label> <br />
                <Select
                  value={runnerType ? runners.map((el) => el.si === JSON.parse(JSON.stringify(runnerType))).sln : ''}
                  onChange={handleBookmakerResult}
                  options={runners.length > 0 ? [
                    { value: '' },
                    ...runners.map((el) => ({ value: JSON.stringify(el), label: el.sln })),
                    { value: JSON.stringify({ 'sln': 'tie', 'si': 'tie' }), label: 'Tie' },
                    { value: JSON.stringify({ 'sln': 'abandoned', 'si': 'abandoned' }), label: 'Abandoned' }
                  ] : []}
                  ref={resultRef}
                  placeholder="Select Result"
                  styles={selectStyles}
                />
              </div>
              <div className="result-event-select hFix-100">
                <label htmlFor="operators">Select Operators</label> <br />
                <Select
                  tabIndex={-1}
                  isMulti
                  value={operatorId}
                  onChange={handleOperator}
                  options={getOptions(operatorId)}
                  styles={selectStyles}
                  ref={operatorRef}
                  placeholder="Select Operators"
                />
              </div>
            </div>
            <div className="book-maker-btn">
              <Button className='submit' name='Submit Result' type='submit' disabled={!((isResultSelected && operatorId.length) && (scope?.userType !== 'manager' || scope?.result?.add))}/>
            </div>
          </div>
        </form>
        <BookmakerResultTable
          bookmakerResultData={bookmakerResultData}
          setBookmakerResultData={setBookmakerResultData}
          setBookmakerType={setBookmakerType}
          setEventId={setEventId}
          allBookmakerResult={allBookmakerResult}
          pagination={pagination}
        />
      </motion.div>
      <SurveillanceModel
        isOpen={isOpen}
        setOpen={setOpen}
        onCloseModel={formik.handleSubmit}
        matchID={matchID}
        marketID={marketExternalId}
        marketType={2}
      />
    </>
  );
};

export default ShowBookmakerResult;
