import React,{ useState, useEffect } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { IoSearchOutline, } from 'react-icons/io5'
import Paginations from '../../component/pagination/Paginations'
import Button from '../../component/button/Button'
import { updateCaller } from '../../services/api'
import '../result/result.css'
import Swal from "sweetalert2";
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import getLocalData from '../../utilities/LocalStorageData.utilities'
const MatchResultTable = ({ resultData, setResultData, page, setPage, totalPages, perPage }) => {
  let userType = localStorage?.getItem('user_role')?.toLowerCase();

  const [inputValue, setInputValue] = useState("")

  const [rowOpen, setRowOpen] = useState(null);
  const [showResultStatus, setShowResultStatus] = useState(false);
  const [showRollbackStatus, setShowRollbackStatus] = useState(false);
  const [scope, setScope] = useState()
  const maximo = totalPages
  let completeData = resultData

  useEffect(()=>{
    const {rollback} = getLocalData('scope')
    const userType = getLocalData('user_role')
    setScope({rollback, userType})
  },[])

  const filteredData = completeData?.filter(e => {
    const eventName =  e.event_name ? e.event_name.toLowerCase() : ''
    return (eventName.includes(inputValue.toLowerCase()))
  });

  const handleRollBack = async (e) => {
    const resultID = e.id;

    Swal.fire({
      title: 'Are you sure?',
      text: 'Result is Rollback',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await updateCaller(`api/v1/results/${resultID}/rollback`)
        if (response.success === true) {
          Swal.fire({
            title: 'Submitted',
            text: response.msg,
            icon: 'success',
            showConfirmButton: false,
            timer: 1000,
            background: 'white',
            color: 'green'
          }
          ).then(async function () {
            setResultData(resultData.map(e => e.id !== resultID ? e : { ...e, is_rollback: 1 }))
          })
        } else {

          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            background: "red",
            color: "white",
            showConfirmButton: false,
            timer: 2000,
            width: 300,
            padding: ".5rem"
          })

          Toast.fire({
            icon: 'error',
            iconColor: "white",
            title: response.message
          })

        }
      }
    })
  }

  const toggleRow = (index) => {
    if (rowOpen === index) {
      setRowOpen(null);
    } else {
      setRowOpen(index);
      setShowRollbackStatus(false);
      setShowResultStatus(false);
    }
  };
  const toggleResultStatus = () => {
    setShowResultStatus(!showResultStatus);
    setShowRollbackStatus(false);
  };

  const toggleRollbackStatus = () => {
    setShowRollbackStatus(!showRollbackStatus);
    setShowResultStatus(false);
  };
  return (
    <>
      <div className="table-border" >
        <div className="wrapper-body">
          <div className="wrapper-content-1">
            <div className='searchcard'>
              <input type="text" autoComplete="off" name="" id="" placeholder='Search' className='result-input'
                onChange={event => { setInputValue(event.target.value); }}
                value={inputValue}
              />
              {inputValue ? <div className="ai-close">
                <AiOutlineClose onClick={() => setInputValue('')}
                />
              </div> : <div className='ai-close'> <IoSearchOutline /></div>
              }
            </div>
           
          </div>
        </div>
        <div style={{ overflowX: "auto" }} >
          <table width="100%" >
            <thead>
              <tr className='custom-row' >
                <th> # </th>
                <th><div style={{ width: "60px" }}> MARKET </div> </th>
                <th><div style={{ width: "105px" }}> DECLARED BY </div></th>
                <th><div> SPORT </div></th>
                <th><div> EVENT NAME </div></th>
                <th><div> RESULT </div></th>
                <th><div> DATE </div></th>
                {
                  (scope?.userType === "admin" || (scope?.userType === 'manager' && scope?.rollback.add)) &&  <th> <div> ROLLBACK </div> </th>
                }
              </tr>
            </thead>

            <tbody>
              {
                filteredData?.length > 0 ?
                filteredData.map((e, i) => (
                    <React.Fragment key={i}>
                      <tr className='custom-row' key={i} onClick={() => toggleRow(i)}>
                       
                        <td > <div className='custom'>
                          {rowOpen ===i ? (
                            <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                          ) : (
                            <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                          )}
                          {e?.id ?? "Auto"}
                        </div> </td>
                        <td > {e?.market_name ?? "Auto"} </td>
                        <td > {e?.created_by ?? "Auto"} </td>
                        <td style={{ textTransform: "capitalize" }}> {e.sport_name ? e.sport_name : "No Data"} </td>
                        <td > <div style={{ width: "250px" }}>{e.event_name ? e.event_name : "No Data"} </div> </td>
                        <td > <div style={{ width: "150px", textTransform: "capitalize" }}> {e.selection} </div>  </td>
                        <td > <div style={{ width: "190px" }}>{(new Date(e.created_at)).toLocaleString()}</div> </td>
                        {
                          (scope?.userType === "admin" || (scope?.userType === 'manager' && scope?.rollback.add)) && <td >
                            <div> <Button className='submit' type='button' disabled={(scope?.userType ==='manager' && !scope?.rollback?.add) || !e.is_rollback} name='RollBack' onClick={handleRollBack} /> </div>
                          </td>
                        }
                      </tr>
                      {rowOpen === i && (
                        <>
                          <tr className='custom-row' onClick={toggleResultStatus}>
                            <td colSpan={8}>

                              <div className='result_status'> &nbsp; &nbsp;
                                {showResultStatus ? (
                                  <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                                ) : (
                                  <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                                )}
                                Result Status
                              </div>
                            </td>
                          </tr>
                          {e.result_status && showResultStatus && (
                            <tr className='custom-row'>
                              <td colSpan={9}>
                                <div className='result_status'>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Market</th>
                                        <th>Status</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>Process Count</th>
                                        <th>User Count</th>
                                        <th>Bet Count</th>
                                        <th>End Point</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Object.entries(JSON.parse(e.result_status)).map(([market, data], index) => (
                                        <tr key={index}>
                                          <td>{market}</td>
                                          <td>{data.status}</td>
                                          <td>{(new Date(data.startTime)).toLocaleString() }</td>
                                          <td>{(new Date(data.endTime)).toLocaleString()}</td>
                                          <td>{data.processCount}</td>
                                          <td>{data.userCount}</td>
                                          <td>{data.betCount}</td>
                                          <td>{data.endpoint}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          )}
                          {e.rollback_status ?
                            ( <tr className='custom-row' onClick={toggleRollbackStatus}>
                              <td colSpan={8}>
                                <div className='result_status'> &nbsp; &nbsp;
                                  {showRollbackStatus ? (
                                    <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                                  ) : (
                                    <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                                  )}
                                  Rollback Status

                                </div>
                              </td>
                              </tr>) : ""
                              }
                          {e.rollback_status && showRollbackStatus && (
                            <tr className='custom-row'>
                              <td colSpan={9}>
                                <div className='result_status'>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Market</th>
                                        <th>Status</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>Process Count</th>
                                        <th>User Count</th>
                                        <th>Bet Count</th>
                                        <th>End Point</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Object.entries(JSON.parse(e.rollback_status)).map(([market, data], index) => (
                                        <tr key={index}>
                                          <td>{market}</td>
                                          <td>{data.status}</td>
                                          
                                          <td>{(new Date(data.startTime)).toLocaleString()}</td>
                                          <td>{(new Date(data.endTime)).toLocaleString()}</td>
                                          <td>{data.processCount}</td>
                                          <td>{data.userCount}</td>
                                          <td>{data.betCount}</td>
                                          <td>{data.endpoint}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </React.Fragment>
                  )) : <tr className=''><td className='no-data-coloumn' align='center' colSpan={10}>No Data</td></tr>
              }
            </tbody>
          </table>
        </div>
      </div>
      <Paginations page={page} setPage={setPage} totalPages={totalPages} maximo={maximo} />
    </>
  )
}

export default MatchResultTable