//let token = '8ae4b223-eeeb-4c92-8c33-6f13c7862d73';
import Swal from 'sweetalert2'; // or any other notification library you use
const logoutUser = () => {
    //const res = postCaller(`api/v1/auth/logout?token=${token}`)
    localStorage.removeItem('token');
    window.location.href = "/";
}

const BASE_URL = process.env.REACT_APP_BASE_URL;

// Handle common API request errors
const handleApiError = (error) => {
    console.error('API Error:', error);

    if (error?.response?.status === 401) {
        // Handle unauthorized access (e.g., logout user)
        logoutUser();
        Swal.fire({
            title: 'Session Expired',
            text: 'Please log in again.',
            icon: 'error',
            showConfirmButton: true,
        });
    } else if (error?.response?.data?.message) {
        Swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            showConfirmButton: true,
        });
    } else if (error?.errno === 400) {
        
        Swal.fire({
            title: 'Error',
            text: error.message,
            icon: 'error',
            showConfirmButton: true,
        });
    } else {
        Swal.fire({
            title: 'Error',
            text: 'An unexpected error occurred. Please try again later.',
            icon: 'error',
            showConfirmButton: true,
        });
    }
};

export const getCaller = async (url, isShow = true) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        })

        if (response.status === 401) {
            logoutUser();
        }

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            const error = await response.json();
            isShow && response.statusText !== "Not Found" && (response.statusText !== ""  || response.status !== 404) && handleApiError(error)

            return {
                success: false,
                errno: error.errno,
                message: error.message,
                data: []
            };
        }
    } catch (error) {
        console.log(error);
        return { success: false, message: error?.message || error?.response?.data?.message || "Something Went Wrong", data: [] };
    }
}

export const postCaller = async (url, data) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        })

        if (response.status === 401) {
            logoutUser()
        }
        
        if (response.status === 400) {
            handleApiError(response);
        }

        if (response.ok) {
            const data = await response.json();
            return {
                success: true,
                message: data.message,
                data: data.data,
            };
        } else {
            const error = await response.json();
            handleApiError(error);
            
            return {
                success: false,
                errno: error.errno,
                message: error.message,
                data : {}
            };
        }
    } catch (error) {
        console.log(error);
        return { success: false, message: error?.message || error?.response?.data?.message || "Something Went Wrong", data : {}  };
    }

}

export const updateCaller = async (url, data) => {
    try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
    })

    if (response.status === 401) {
        logoutUser(response)
    }

    if (response.ok) {
        const data = await response.json();
        return {
            success: true,
            message: data.message,
            data: data.data,
        };
    } else {
        const error = await response.json();
        handleApiError(error);
        return {
            success: false,
            errno: error.errno,
            message: error.message,
            data : {}
        };
    }
    } catch (error) {
        console.log(error);
        return { success: false, message: error?.message || error?.response?.data?.message || "Something Went Wrong", data : {} };
    }
}

export const deleteCaller = async (url, data) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        })
        if (response.status === 401) {
            logoutUser()
        }

        if (response.ok) {
            const data = await response.json();
            return {
                success: true,
                message: data.message,
                data: data.data,
            };
        } else {
            const error = await response.json();
            handleApiError(error);
            return {
                success: false,
                errno: error.errno,
                message: error.message,
                data : {}
            };
        }
    } catch (error) {
        console.log(error);
        return { success: false, message: error?.message || error?.response?.data?.message || "Something Went Wrong", data : {}  };
    }
}





