import React, { useState } from "react";
import ToogleButton from "../../../component/toggleButton/ToogleButton";
import { getCaller, updateCaller, deleteCaller } from "../../../services/api";
import { BsChevronDown, BsChevronRight } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import { motion } from "framer-motion";
import Swal from "sweetalert2";
import '../leagueFromSport/leagueManage.css'
import CONSTANTS from "../../../utilities/constants";
//import Modal from "../../../component/modal/Modal";

const MatchAccordian = ({ match, setMatches, matches, index, sportId }) => {

  const [open, setOpen] = useState(false);
  //const [fancyOpen, setFancyOpen] = useState(false);
  // const [modalOpen, setModalOpen] = useState(false)
  // const [messages, setMessages] = useState(false)
  const [markets, setMarkets] = useState([]);
  // const [fancySettingData,setFancySettingData] = useState([])
  const [openMain, setOpenMain] = useState(false);
  const [openFancy, setOpenFancy] = useState(false);
  const [openBookmaker, setOpenBookmaker] = useState(false);

  const getMarketFromMatches = async () => {
    if (markets.length === 0) {
      const matchResponse = await getCaller(`api/v1/markets/marketFindById?id=${match.id}`)
      setMarkets(matchResponse.data);
    }
    setOpen(!open);
  }

  const handleMarketAccordian = () => {
    setOpenMain(!openMain);

  };

  const handleFancyAccordian = () => {
    setOpenFancy(!openFancy)
  };

  const handleBookmakerAccordian = () => {
    setOpenBookmaker(!openBookmaker)
  };

  const handleMatchCity = (fieldName, index, value) => {
    let newFormValues = [...matches]
    newFormValues[index][fieldName] = value;
    setMatches(newFormValues)
  }

  const handleMatchStatuses = async (fieldName, index, value, item) => {
    let newFormValues = [...matches]
    newFormValues[index][fieldName] = value;
    setMatches(newFormValues)

    let title = "";

    switch (fieldName) {
      case "is_active":
        title = value ? "Active" : "Inactive";
        break;
      case "is_populer":
        title = value ? "Marked popular" : "Marked unpopular";
        break;
      default:
        title = CONSTANTS.LEAGUE_STATUS[fieldName].concat(value ? " Enabled" : " Disabled");
        break;
    }

    const res = updateCaller(`api/v1/racing/events/${matches[index].id}`, matches[index])
    if (res) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        background: "black",
        color: "white",
        showConfirmButton: false,
        timer: 2000,
        width: 450,
        padding: ".5rem"
      })
      Toast.fire({
        icon: 'success',
        title: `${title}`,
        background: 'white',
        color: 'green'
      }).then(async function () {
        const res = await getCaller(`api/v1/racing/events/venue/${matches[index].venue_id}`)
        setMatches(res.data);
      })
    }
  }

  const updateCity = async (item) => {
    const response = await updateCaller(`api/v1/events/matchActive?id=${item.id}&league_id=${item.league_id}&sport_id=${item.sport_id}`, matches[index])

    if (response) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        background: "black",
        color: "white",
        showConfirmButton: false,
        timer: 2000,
        width: 500,
        padding: ".5rem"
      })
      Toast.fire({
        icon: 'success',
        title: "City has Changed Successfully",
        background: 'white',
        color: 'green'
      })
    }
    else {
      alert("Not Changes")
    }
  }


  const handleChangeFancy = (fieldName, index, value) => {
    match.fancy_limits[index] = { ...match.fancy_limits[index] };
    match.fancy_limits[index][fieldName] = value;
    matches[index] = match;
    setMatches([...matches]);
  }

  const handleChangeBookmaker = (index, value) => {
    match.bookmaker_limits = { ...match.bookmaker_limits };
    match.bookmaker_limits[index] = value;
    matches[index] = match;
    setMatches([...matches]);
  }

  const updateMatchLeagueSettings = async (id, limitType) => {
    let jsonObject;

    if (limitType === 'market') {
      jsonObject = JSON.parse(JSON.stringify(match.market_limits));
    } else if (limitType === 'fancy') {
      jsonObject = JSON.parse(JSON.stringify(match.fancy_limits));
    } else if (limitType === 'bookmaker') {
      jsonObject = JSON.parse(JSON.stringify(match.bookmaker_limits));
    } else {
      //console.error('Invalid limit type');
      return;
    }
    const endpoint = `api/v1/events/${id.id}/limits/${limitType}`;
    const response = await updateCaller(endpoint, jsonObject);
    if (response) {
      const Toast = Swal.mixin({
        toast: true,
        showConfirmButton: false,
        timer: 2000,
        width: 480,
        padding: ".5rem",
        background: "black",
        color: "white",
      })

      Toast.fire({
        icon: 'success',
        title: response.message,
        background: 'white',
        color: 'green'
      })
    }
  };

  const handleDeleteMatch = (match) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `This Match Deleted`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(async (result) => {
      if (result.isConfirmed) {
        { match.is_deleted = 1 }
        const response = await updateCaller(`api/v1/racing/events/${match.id}`, match)
        if (response.success === true) {
          Swal.fire({
            title: 'Deleted',
            text: 'Your Match is deleted Successfully',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000,
            background: 'white',
            color: 'green'
          }).then(function () {
            const newList = [...matches];
            newList.splice(index, 1);
            setMatches(newList)
          })
        }
      }
    })
  }

  const updateMarket = async (j) => {
    const market = markets[j];
    const { odd_limit, is_active, max_market_limit, inplay_stake_limit, max_stake_limit, bet_delay } = market;
    const response = await updateCaller(`api/v1/racing/updateMarket?match_id=${match.id}&id=${market.marketId}`, { odd_limit, is_active, max_market_limit, inplay_stake_limit, max_stake_limit, bet_delay });

    if (response.success) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        background: "black",
        color: "white",
        showConfirmButton: false,
        timer: 2000,
        width: 500,
        padding: ".5rem"
      })
      Toast.fire({
        icon: 'success',
        title: "Market updated successfully!",
        background: 'white',
        color: 'green'
      })
    }
  }


  return (
    <>
      <div className="match-accordian-body" >
        {/* match accordian  */}
        <table width="95%"  >
          <tbody>
            <tr>
              <td align="left" width="230px"> <div className={`acc-head ${open ? 'active-match' : 'inactive'}`}
                onClick={getMarketFromMatches}>
                <p style={{ display: "flex", alignItems: "center" }} > {!open ? <><BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold", cursor: "pointer" }} /></> : <><BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold", cursor: "pointer" }} /></>}   {match.event_name ? match.event_name : "Events not available"} </p> </div>
              </td>
              <td align="left" width="160px" >
                <div className="acc-para"> <p> {match.is_custom === 1 ? (new Date(match?.event_date?.slice(0, match?.event_date?.length - 5)?.replace('T', " "))).toLocaleString('en-GB') : (new Date(match?.event_date)).toLocaleString('en-GB')} </p> </div>
              </td>
              <td align="left">
                <div className="table-flex">
                  <p className='para-btn'> Active </p>
                  <ToogleButton defaultChecked={match.is_active === 0 ? false : true} onChange={(event) => handleMatchStatuses("is_active", index, event, match)} />
                </div>
              </td>
              <td align="left">
                <div className="table-flex">
                  <p className='para-btn'> Recommended </p>
                  <ToogleButton defaultChecked={match.is_populer === 0 ? false : true} onChange={(event) => handleMatchStatuses("is_populer", index, event, match)} />
                </div>
              </td>
              <td align="left">
                <div className="table-flex">
                  <p className='para-btn'>In play</p>
                  <ToogleButton defaultChecked={match.inplay_status !== 0 ? true : false} onChange={(event) => handleMatchStatuses("inplay_status", index, event, match)} />
                </div>
              </td>
              <td align="left">
                <div className="table-flex">
                  <p className='para-btn'> Volume check </p>
                  <ToogleButton defaultChecked={match.is_odds_check === 0 ? false : true} onChange={(event) => handleMatchStatuses("is_odds_check", index, event, match)} />
                </div>
              </td>
              <td align="left">
                <input type="text" autoComplete="off" name="city" placeholder="City" value={match?.city ?? ""} onChange={(event) => handleMatchCity("city", index, event.target.value)} onBlur={() => { updateCity(match) }} className="city-input" />
              </td>
              <td align="left">
                <div className="delete-icon">
                  <MdDelete
                    style={{ fontSize: "1rem", color: "white" }}
                    onClick={() => handleDeleteMatch(match)}
                    tabIndex={0}
                    onKeyDown={
                      (e) => {
                        if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32) {
                          e.preventDefault(); handleDeleteMatch(match)
                        }
                      }
                    }
                  />
                </div>
              </td>
              <td align="left"> <div className="ten-one">{match.total_markets}</div> </td>
            </tr>
          </tbody>
        </table>

        {/* market accordian  */}
        <div className={`market-accordian-body`}>
          {open && (
              <motion.div className="league-setting-container" style={{ backgroundColor: '#c6c6c6' }} initial={{ opacity: 0, x: '-100vh' }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}>
                <div className={`market-accordian-body`} style={{ display: open ? 'block' : 'none' }}>
                    <div className='market-accordian-body'>
                      <button className={`accordion ${openMain ? 'active' : 'inactive'}`}
                        onClick={handleMarketAccordian}>
                        {!openMain ? <><BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold" }} /></> : <><BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold" }} /></>}
                        All Markets
                      </button>
                    </div>
                    {openMain && (
                      <div className="table-border" >
                      <div className={`market-accordian-body`}>
                        <table width="100%" >
                          <tbody>
                            {(markets || []).map((market, j) => (
                              <tr className='league-setting-row' key={market.marketId}>
                                <td>{market.market_name}</td>
                                <td>
                                  <ToogleButton
                                    defaultChecked={parseInt(market.is_active) === 1}
                                    onChange={(value) => {
                                      const newMarket = { ...market };
                                      newMarket.is_active = value ? 1 : 0;
                                      setMarkets(old => { old[j] = newMarket; return [...old]; })
                                      updateMarket(j)
                                    }}
                                  />
                                </td>
                                <td>
                                  <label htmlFor="stake_limit">Stake Limit</label> <br />
                                  <input autoComplete="off" type='number' disabled={parseInt(market.is_active) === 0} value={market.max_stake_limit}
                                    onChange={(event) => {
                                      const newMarket = { ...market };
                                      newMarket.max_stake_limit = event.target.value;
                                      setMarkets(old => { old[j] = newMarket; return [...old]; })
                                    }}
                                    onBlur={() => updateMarket(j)}
                                    placeholder='stake limit'
                                    className='league-detail-input'
                                  />
                                </td>
                                <td>
                                  <label htmlFor="stake_limit"> Odd Limit</label> <br />
                                  <input autoComplete="off" type='number' disabled={parseInt(market.is_active) === 0} value={market.odd_limit}
                                    onChange={(event) => {
                                      const newMarket = { ...market };
                                      newMarket.odd_limit = event.target.value;
                                      setMarkets(old => { old[j] = newMarket; return [...old]; })
                                    }}
                                    onBlur={() => updateMarket(j)}
                                    placeholder='stake limit'
                                    className='league-detail-input'
                                  />
                                </td>
                                <td>
                                  <label htmlFor="stake_limit">Inplay Stake Limit</label> <br />
                                  <input autoComplete="off" type='number' disabled={parseInt(market.is_active) === 0} value={market.inplay_stake_limit}
                                    onChange={(event) => {
                                      const newMarket = { ...market };
                                      newMarket.inplay_stake_limit = event.target.value;
                                      setMarkets(old => { old[j] = newMarket; return [...old]; })
                                    }}
                                    onBlur={() => updateMarket(j)}
                                    placeholder='stake limit'
                                    className='league-detail-input'
                                  />
                                </td>
                                <td>
                                  <label htmlFor="stake_limit">Max Market Limit</label> <br />
                                  <input autoComplete="off" type='number' disabled={parseInt(market.is_active) === 0} value={market.max_market_limit}
                                    onChange={(event) => {
                                      const newMarket = { ...market };
                                      newMarket.max_market_limit = event.target.value;
                                      setMarkets(old => { old[j] = newMarket; return [...old]; })
                                    }}
                                    onBlur={() => updateMarket(j)}
                                    placeholder='stake limit'
                                    className='league-detail-input'
                                  />
                                </td>
                                <td>
                                  <label htmlFor="stake_limit">Delay</label> <br />
                                  <input autoComplete="off" type='number' disabled={parseInt(market.is_active) === 0} value={market.bet_delay}
                                    onChange={(event) => {
                                      const newMarket = { ...market };
                                      newMarket.bet_delay = event.target.value;
                                      setMarkets(old => { old[j] = newMarket; return [...old]; })
                                    }}
                                    onBlur={() => updateMarket(j)}
                                    placeholder='stake limit'
                                    className='league-detail-input'
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      </div>
                    )}
                    <div>
                      {openFancy && (
                        <div className={`market-accordian-body`}>
                          <table width="100%" >
                            <tbody>
                              {Object.entries(match.fancy_limits).map(([fieldName, keyIndex], j) => (
                                <tr className='league-setting-row' key={j}>
                                  <td>{fieldName.split("_").join(" ")}</td>
                                  <td>
                                    <label htmlFor="stake_limit">Stake Limit</label> <br />
                                    <input type='number' autoComplete="off" value={keyIndex.stake_limit} onChange={(event) => handleChangeFancy('stake_limit', fieldName, event.target.value)}
                                      placeholder='stake limit'
                                      className='league-detail-input'
                                    />
                                  </td>
                                  <td>
                                    <label htmlFor="stake_limit">Max Market Limit</label> <br />
                                    <input type='number' autoComplete="off" value={keyIndex.max_market_limit} onChange={(event) => handleChangeFancy('max_market_limit', fieldName, event.target.value)}
                                      placeholder='stake limit'
                                      className='league-detail-input'
                                    />
                                  </td>
                                  <td>
                                    <label htmlFor="stake_limit">Delay</label> <br />
                                    <input type='number' autoComplete="off" value={keyIndex.delay} onChange={(event) => handleChangeFancy('delay', fieldName, event.target.value)}
                                      placeholder='stake limit'
                                      className='league-detail-input'
                                    />
                                  </td>
                                  <td>
                                    <label htmlFor="stake_limit">Commission</label> <br />
                                    <ToogleButton defaultChecked={keyIndex.is_com === 1 ? true : false} onChange={(value) => handleChangeFancy("is_com", fieldName, value ? 1 : 0)} />
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td> <button className='update-league-btn' type='button' disabled={parseInt(match.is_active) === 0} onClick={() => updateMatchLeagueSettings(match, 'fancy')}> Update </button></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                    <div>
                      {openBookmaker && (
                        <div className={`market-accordian-body`}>
                          <table width="100%" >
                            <tbody>
                              <tr className='league-setting-row'>
                                <td>
                                  <label htmlFor="stake_limit">Stake Limit</label> <br />
                                  <input
                                    type='number' autoComplete="off"
                                    value={match.bookmaker_limits.stake_limit}
                                    onChange={(event) =>
                                      handleChangeBookmaker('stake_limit', event.target.value)
                                    }
                                    placeholder='stake limit'
                                    className='league-detail-input'
                                  />
                                </td>
                                <td>
                                  <label htmlFor="max_market_limit">Max Market Limit</label> <br />
                                  <input
                                    type='number' autoComplete="off"
                                    value={match.bookmaker_limits.max_market_limit}
                                    onChange={(event) =>
                                      handleChangeBookmaker('max_market_limit', event.target.value)
                                    }
                                    placeholder='max market limit'
                                    className='league-detail-input'
                                  />
                                </td>
                                <td>
                                  <label htmlFor="delay">Delay</label> <br />
                                  <input
                                    type='number' autoComplete="off"
                                    value={match.bookmaker_limits.delay}
                                    onChange={(event) => handleChangeBookmaker('delay', event.target.value)}
                                    placeholder='delay'
                                    className='league-detail-input'
                                  />
                                </td>
                                <td>
                                  <label htmlFor="stake_limit">Commission</label> <br />
                                  <ToogleButton defaultChecked={match.bookmaker_limits.is_com === 1 ? true : false} onChange={(value) => handleChangeBookmaker("is_com", value ? 1 : 0)} />
                                </td>
                              </tr>
                              <tr>
                                <td> <button className='update-league-btn' type='button' disabled={parseInt(match.is_active) === 0} onClick={() => updateMatchLeagueSettings(match, 'bookmaker')}> Update </button></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                </div>
              </motion.div>
          )}
        </div>
      </div>
      {/* <Modal message={messages} modalOpen={modalOpen} /> */}
    </>
  )
}
export default MatchAccordian