import React from 'react'
import { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import ToogleButton from '../../../component/toggleButton/ToogleButton';
import { deleteCaller, getCaller, updateCaller, postCaller } from '../../../services/api';
import Swal from "sweetalert2";
import '../downline.css'
import { IoMdSave } from 'react-icons/io'
import Button from '../../../component/button/Button'
import FancyAssignAdd from './FancyAssignAdd'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import Select from 'react-select';
import getLocalData from '../../../utilities/LocalStorageData.utilities';

const FancyAssignMarket = ({ userName }) => {
    const [fancyData, setFancyData] = useState([])
    const [fancyAddComponents, setFancyAddComponents] = useState([]);
    const [defaultLimits, setDefaultLimits] = useState({});
    const [resultData, setResultData] = useState(""); 
    const [resultValue, setResultValue] = useState(null);
    const [addF, setFddF] = useState(false)
    const [agentDetails, setAgentDetails] = useState({});
    const [endDate, setEndDate] = useState(null);
    const [operatorList, setOperatorList] = useState([]);
    const [operator, setOperator] = useState('');
    const [scope,setScope] = useState({})

    useEffect(() => {
        const {markets, result} = getLocalData('scope')
        setScope({markets, result})
        setResultValue("");
      }, []);

   // alert(addF)
    useEffect(() => {
        const getMarketList = async () => {
            setFddF(false)
            const response = await getCaller(`api/v1/fancies/custom/${userName.event_id}/type/${userName.market_id}?agent_id=${userName.id}`)
            const formattedData = response.data.map((item) => {
                let odds = typeof item.odds !== 'object'? JSON.parse(item.odds): item.odds;
                if(!Object.keys(odds).length){
                    odds = {
                        runNo: item.odds.runNo || 0,
                        runYes: item.odds.runYes || 0,
                        oddsNo: item.odds.oddsNo || 0,
                        oddsYes: item.odds.oddsYes || 0,
                        size: item.odds.oddsYes || 0,
                        back: item.odds.back || 0,
                        lay: item.odds.lay || 0,
                    }
                }
                return ({
                    ...item,
                    odds: odds
                })
            });

            setFancyData(formattedData);

                  let limits = (await getCaller(`api/v1/events/${userName.event_id}/limits/fancy`)).data;
                  setDefaultLimits(limits[userName.market_id]);



        }
        getMarketList()

    }, [userName.event_id, userName.market_id, addF])

    useEffect(() => {
        const getAgentDetails = async () => {
          try {
            let agentData = await getCaller(`api/v1/users/${userName.id}`);
            setAgentDetails(agentData.data);
          } catch (error) {
    
          }
        };
    
        getAgentDetails();
      }, [])

      const handleSuspendAll = async (field, id, status) => {
        let updateResponse = await updateCaller(`api/v1/users/updateUser/${id}`, { [field]: status })
        let message = updateResponse.success ? status ? 'Enabled' : 'Disabled' : 'Failed!';
    
        if(updateResponse.success ){
            if(userName?.match_id){
              let updateFancyResponse = await updateCaller(`api/v1/fancies/updateFancy/${userName.event_id}/${id}`, { ['is_sus']: status });
              if(updateFancyResponse.success){
                if(userName?.event_id && userName?.market_id){
                    const response = await getCaller(`api/v1/fancies/custom/${userName.event_id}/type/${userName.market_id}?agent_id=${userName.id}`)
                    const formattedData = response.data.map((item) => {
                        let odds = typeof item.odds !== 'object'? JSON.parse(item.odds): item.odds;
                        if(!Object.keys(odds).length){
                            odds = {
                                runNo: item.odds.runNo || 0,
                                runYes: item.odds.runYes || 0,
                                oddsNo: item.odds.oddsNo || 0,
                                oddsYes: item.odds.oddsYes || 0,
                                size: item.odds.oddsYes || 0,
                                back: item.odds.back || 0,
                                lay: item.odds.lay || 0,
                            }
                        }
                        return ({
                            ...item,
                            odds: odds
                        })
                    });
        
                    setFancyData(formattedData);
        
                          let limits = (await getCaller(`api/v1/events/${userName.event_id}/limits/fancy`)).data;
                          setDefaultLimits(limits[userName.market_id]);
                }
                message = status ? 'Enabled' : 'Disabled';
              } else {
                message = "Failed"
              }
              toasterMessage(updateResponse, message);
            } else {
              toasterMessage(updateResponse, message)
            }
          }
      }
    
      const toasterMessage = (response, message) => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 2000,
          width: 300,
          padding: ".5rem",
          background: "#4cbb17",
          color: "white",
        })
        Toast.fire({
          icon: response.success ? 'success' : 'warning',
          title: message,
          background: 'white',
          color: response.success ? 'green' : 'red',
        });
      }

    const handleInputChange = (index, field, value) => {
        const updatedFancyData = [...fancyData];
        const [outerField, innerField] = field.split('.');
        const inputValue = parseFloat(value);

        if (innerField) {
            if (outerField === 'odds' && (innerField === 'runNo' || innerField === 'runYes'|| innerField === 'oddsNo'|| innerField === 'oddsYes')) {
                if (value < 0) {
                    return; 
                }
            }
            if (outerField === 'odds' && (innerField === 'runNo' || innerField === 'runYes' || innerField === 'oddsNo' || innerField === 'oddsYes')) {
                if (inputValue < 0) {
                    return; 
                }
            }
            updatedFancyData[index][outerField][innerField] = value;
        } else {
            if (['min_stake', 'max_stake', 'bet_delay', 'max_market_limit'].includes(field)) {
                if (value < 0) {
                    return
                }
            } else if(field === "end_date"){
                setEndDate(value);
            }
            updatedFancyData[index][field] = value;
        }

        setFancyData(updatedFancyData);
    };

    const handleSelectResult = (value) => {
        setResultData(value)
        if(value){
            const initialOperator  = operatorList?.map(el => ({ value: el, label: el }));
            setOperator(initialOperator);
        }
    }

    const handleChange = (value)=>{
        setResultValue(value)
    }
     
    async function handleResultAdd() {
 
        let value = JSON.parse(resultData)

        let postdata = {
            fancy_id: value.id,
            market_name: value.market_name,
            match_id: userName.match_id,
            result: resultValue,
            selection: value.selection?? '',
            sport_id: value.sport_id
        }
        

        Swal.fire({
            title: 'Are you sure?',
            text: `You want to Submit this Result (${resultValue})`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await postCaller(`api/v1/results`, postdata);
                if (response.success) {
                    Swal.fire({
                        title: 'Submitted',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000,
                        background: 'white',
                        color: 'green'
                    }).then(function () {
                        setResultData("");
                        setResultValue("");
                        setFddF(true);
                    })
                } else {

                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top',
                        background: "red",
                        color: "white",
                        showConfirmButton: false,
                        timer: 2000,
                        width: 300,
                        padding: ".5rem"
                    })

                    Toast.fire({
                        icon: 'error',
                        iconColor: "white",
                        title: response.message
                    })

                }
            }
        })


    }
    
    useEffect(() => {
        const getData = async() => {
          const response = await getCaller(`api/v1/results/operatorList`)
            const fetchedOperatorList = response?.data?.operatorList
            setOperatorList(fetchedOperatorList)
        }
        getData()
      },[] )
     
    const options = [{ value: 'all', label: 'Select All' }, ...operatorList.map((el) => ({ value: el, label: el }))];

    const handleOperator = (selectedOptions) => {
        if (selectedOptions.some(option => option.value === 'all')) {
            if (selectedOptions.length === options.length) {
            setOperator([]);
            } else {
            setOperator(operatorList.map(el => ({ value: el, label: el })));
            }
        } else {
        
            setOperator(selectedOptions);
        }
    };

    const getOptions = (selectedOptions) => {
        if (selectedOptions?.length === operatorList.length) {
            return operatorList.map((el) => ({ value: el, label: el }));
        }
        return options;
    };
    
    const handleSubmit = async (i) => {
        if(!scope?.markets?.update) return;

        try {
            const response = await updateCaller(`api/v1/fancies/${i.id}`, {
                is_sus: i.is_sus,
                is_active: i.is_active,
                is_com: i.is_com,
                msg: i.msg,
                min_stake: i.min_stake,
                max_stake: i.max_stake,
                bet_delay: i.bet_delay,
                max_market_limit: i.max_market_limit,
                market_name: i.market_name,
                runNo: i.odds.runNo,
                runYes: i.odds.runYes,
                oddsNo: i.odds.oddsNo,
                oddsYes: i.odds.oddsYes,
                size: i.odds.size,
                back: i.odds.back,
                lay: i.odds.lay,
                end_date: i.end_date
            });

            if (response.success === true) {
                const Toast = Swal.mixin({
                    toast: true,
                    background: "black",
                    color: "white",
                    showConfirmButton: false,
                    timer: 2000,
                    width: 450,
                    padding: ".5rem"
                })
                Toast.fire({
                    icon: 'success',
                    title: "Fancy updated Successfully",
                    background: 'white',
                    color: 'green'
                })
            }  else  {     
                const Toast = Swal.mixin({
                    toast: true,
                    background:"black",
                    color:"white",
                    showConfirmButton: false,
                    timer: 2000,
                    width:450,
                    padding:".5rem"
                    })
                    Toast.fire({
                    icon: 'error',
                    title: response.message,
                    background: 'white',
                    color: 'red'
                    })
            }
            
        } catch (error) {
            console.error('Error updating fancyData:', error);
        }
    };

    const handleDelete = async (item) => {
        if(!scope?.markets?.delete) return;

        try {
            Swal.fire({
                title: 'Are you sure?',
                text: `You are about to delete a fancy.`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then(async (result) => {
                if (result.isConfirmed){
            const deleteResponse = await deleteCaller(`api/v1/fancies/${item.id}`);
            if (deleteResponse.success === true) {
                Swal.fire({
                    title: 'Submitted',
                    text: 'Your fancy has been deleted successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000,
                    background: 'white',
                    color: 'green'
                }).then((response) => {
                    if (response) {
                        const updatedFancyData = fancyData.filter((fancy) => fancy.id !== item.id);
                        setFancyData(updatedFancyData);
                        setResultData("");
                        setResultValue("");
                    }
                });
            } else  {
                Swal.fire({
                    title: 'error',
                    text: deleteResponse.message ,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1000,
                    background: 'white',
                    color: 'red'
                })
            }}})
        } catch (error) {
            console.error('Error deleting fancy:', error);
        }
    };

    const addFancyAdd = () => {
        //alert("fff")
        setFancyAddComponents((prevFancyAddComponents) => [
            ...prevFancyAddComponents,
            <FancyAssignAdd defaultLimits={defaultLimits} setFddF={setFddF} key={prevFancyAddComponents.length} marketType={userName.market_type} userName={userName} />
        ]);
    };
   
    return (
        <>
            <div className="book-maker-heading">
                <p> FANCY RESULT DECLARE </p>
                <div className='remove-market-container'>
                </div>
            </div>
             
                <div className="book-maker-content">
                    <div className="book-maker-select">
                        <label htmlFor=""> Select Fancy </label> <br />
                        <select 
                            name="" 
                            id="" 
                            className='fancy-result-input' 
                            value={resultData}
                            onChange={(e) => handleSelectResult( e.target.value)}  placeholder='Select Fancy'
                        >
                            <option value=""selected> Select Fancy</option>
                            {fancyData.map((item, index) => (
                                <option key={index} value={JSON.stringify(item)}> {item.market_name} </option>
                            ))}
                        </select>
                    </div>
                    <div className="book-maker-select">
                        <label htmlFor="">Select Result</label> <br />
                        {
                            (['odd_even_markets']).includes(fancyData[0]?.market_type) ? (
                            <select 
                                name="result_value" 
                                className="select-field" 
                                value={resultValue}  
                                onChange={(e) => handleChange(e.target.value)}
                            >
                                <option value="" disabled>Select Result</option>
                                <option value="even">Even</option>
                                <option value="odd">Odd</option>
                            </select>
                            ) : (
                            <input 
                                type="text" 
                                autoComplete="off" 
                                name="result_value" 
                                value={resultValue ?? ""}  
                                onChange={(e) => handleChange(e.target.value)} 
                                className="select-field" 
                                id="" 
                            />
                            )
                        }
                    </div>
                    <div className='book-maker-select'>
                        <label > Select Operators </label> <br />
                        <Select
                            onChange={ handleOperator }
                            options={getOptions(operator)}
                            value={ operator } 
                            placeholder="Select Operators"
                            isMulti
                        />
                    </div>
                    <div className="book-maker-btn" style={{ marginTop: "1.8rem" }}>
                        <Button type='submit' className='submit' name="Submit Result" onClick={handleResultAdd} disabled={!scope?.result?.add || resultData === "" || resultValue === null || resultValue.trim() === ''}/>
                    </div>
                </div>
            
            <div className="book-maker-match">
                <div> <p> {userName.market_id.split("_").join(" ").toUpperCase()} <span className='match-name'> {userName.event_name} </span>  </p> </div>
                <div className="book-input-submit-1">
                <div className="toggle-password">
                        <div className="suspend-container">
                            <div className="suspend-container-body">
                                <p className="suspend-para"> Suspend All</p>
                                <ToogleButton
                                    defaultChecked={agentDetails.is_suspend_all ? true : false} onChange={(value) => handleSuspendAll("is_suspend_all", agentDetails.id, value ? 1 : 0)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='book-input'> </div>
                    <div>
                        <Button className='submit' type="button" disabled={!scope?.markets?.add}  name="ADD FANCY" onClick={addFancyAdd} />
                    </div>
                </div>
            </div>
            
            <div className="market-match-container" style={{ overflowX: 'auto' }}>

                <table width="100%">
                    <thead>
                        <tr id='fancy-head-coloumn'>
                            <th> SELECTION </th>
                            <th> STAKE </th>
                            <th> DELAY </th>
                            <th> END DATE </th>
                            {["khado_markets", "odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : (<th> NO (OUTCOME) </th>)}
                            {["khado_markets", "odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : (<th> YES (OUTCOME) </th>)}
                            {["khado_markets", "odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : (<th> NO (ODDS) </th>)}
                            {["khado_markets", "odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : (<th> YES (ODDS) </th>)}
                            {!["khado_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : (<th> SIZE </th>)}
                            {!["odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : (<th> BACK </th>)}
                            {!["odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : (<th> LAY </th>)}
                            <th> SUSPEND </th>
                            <th> COMM </th>
                            <th> ACTIVE </th>
                            <th> ACTIONS </th>

                        </tr>
                    </thead>
                    <tbody>
                        {fancyData.map((item, index) => (
                            
                            <tr className='fancy-head-row' key={index}>
                                <td>
                                    <input type="text" autoComplete="off" name={`marketName-${index}`} className='fancy-stake-input' value={item.market_name} onChange={(e) => handleInputChange(index, 'market_name', e.target.value)} placeholder='Fancy Selection Name' />
                                    <br />
                                    <input type="text" autoComplete="off" name={`msg-${index}`} className='fancy-stake-input' value={item.msg} onChange={(e) => handleInputChange(index, 'msg', e.target.value)} placeholder='Message' />
                                </td>
                                <td >
                                    <input type="number" autoComplete="off" name="min_stake" className='fancy-stake-input ' value={item.min_stake} placeholder="Min Stake" disabled/> <br />
                                    <input type="number" autoComplete="off" name="max_stake" className='fancy-stake-input' value={item.max_stake} onChange={(e) => handleInputChange(index, 'max_stake', e.target.value)} placeholder='Max Stake' size="5"/>
                                </td>
                                <td >
                                    <input type="number" autoComplete="off" name="bet_delay " className='fancy-stake-input' value={item.bet_delay} onChange={(e) => handleInputChange(index, 'bet_delay',parseInt( e.target.value))} placeholder='Bet Delay ' /> <br />
                                    <input type="number" autoComplete="off" name="max_market_limit" className='fancy-stake-input' value={item.max_market_limit} onChange={(e) => handleInputChange(index, 'max_market_limit', e.target.value)} placeholder={'max_market_limit'} />
                                </td>

                                <td>
                                    <DatePicker
                                        name="end_date"
                                        selected={endDate}
                                        onChange={(e) => handleInputChange(index, 'end_date', e)}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        placeholderText="End Date & Time"
                                        timeIntervals={15}
                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                        className='fancy-stake-input'
                                        value={item?.end_date != null ? moment(item.end_date).format('YYYY-MM-DD HH:mm:ss') : ""} 
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        /> <br /></td>

                                 {["khado_markets", "odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : ( <td >
                                    <input type="number" autoComplete="off" name="runNo" className=' fancy-input' value={item.odds.runNo} onChange={(e) => handleInputChange(index, 'odds.runNo', e.target.value)} />
                                </td>)}

                                {["khado_markets", "odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : ( <td >
                                    <input type="number" autoComplete="off" name="runYes" className=' fancy-input' value={item.odds.runYes} onChange={(e) => handleInputChange(index, 'odds.runYes', e.target.value)} />
                                </td>)}
                                
                                {["khado_markets", "odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : (  <td >
                                    <input type="number" autoComplete="off" name="oddsNo" className='fancy-input' value={item.odds.oddsNo} onChange={(e) => handleInputChange(index, 'odds.oddsNo', e.target.value)} /><br />
                                    {/* {errors[`${index}_oddsNo`] && <span className="error-message" >{errors[`${index}_oddsNo`]}</span>} */}
                                    </td>)}

                                {["khado_markets", "odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : ( <td >
                                    <input type="number" autoComplete="off" name="oddsYes" className=' fancy-input' value={item.odds.oddsYes} onChange={(e) => handleInputChange(index, 'odds.oddsYes', e.target.value)} />
                                </td>)}
                                
                                {!["khado_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : ( <td >
                                    <input type="number" autoComplete="off" name="size" className=' fancy-input' value={item.odds.size} onChange={(e) => handleInputChange(index, 'odds.size', e.target.value)} />
                                </td>)}

                                {!["odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : ( <td >
                                    <input type="number" autoComplete="off" name="back" className=' fancy-input' value={item.odds.back} onChange={(e) => handleInputChange(index, 'odds.back', e.target.value)} />
                                </td>)}

                                {!["odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : ( <td >
                                    <input type="number" autoComplete="off" name="lay" className=' fancy-input' value={item.odds.lay} onChange={(e) => handleInputChange(index, 'odds.lay', e.target.value)} />
                                </td>)}

                                <td >
                                    <div className="toggle-password">
                                        <ToogleButton defaultChecked={item.is_sus === 1} value={item.is_sus} onChange={(e) => handleInputChange(index, 'is_sus', e ? 1 : 0)} />
                                    </div>
                                </td>

                                <td>
                                    <div className="toggle-password">
                                        <ToogleButton defaultChecked={item.is_com === 1} value={item.is_com} onChange={(e) => handleInputChange(index, 'is_com', e ? 1 : 0)} />
                                    </div>
                                </td>
                                <td>
                                    <div className="toggle-password">
                                        <ToogleButton defaultChecked={item.is_active === 1} value={item.is_active} onChange={(e) => handleInputChange(index, 'is_active', e ? 1 : 0)} />
                                    </div>
                                </td>
                                <td>
                                    <div className="toggle-password">
                                        <div className='actions_tab'>
                                            <button 
                                              className='fancy-submit'
                                              onClick={() => handleSubmit(item)}
                                              tabIndex={0} 
                                              disabled={!scope?.markets?.update}
                                              onKeyDown={(e) => { if (e.key === "Enter" || e.key === " " || e.code === "Space"){
                                                 e.preventDefault(); 
                                                 handleSubmit(item) } }} >
                                                <IoMdSave className="icon" 
                                                />

                                            </button>

                                            <button 
                                             className='fancy-cancel' 
                                             onClick={() => handleDelete(item)}
                                             tabIndex={0} 
                                            disabled={!scope?.markets?.delete}
                                             onKeyDown={(e) => { if (e.key === "Enter" || e.key === " " || e.code === "Space"){
                                                 e.preventDefault(); 
                                                 handleDelete(item) } }} >
                                                <MdDelete className="icon"
                                         />

                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
            <div className="market-match-container" style={{ overflowX: 'auto' }}>
                {fancyAddComponents.map((component, index) => (
                    <div key={index}>{component}</div>
                ))}
            </div>
        </>
    )
}
export default FancyAssignMarket