import React, { useEffect, useState } from 'react'
import { RiFootballFill } from 'react-icons/ri'
import { getCaller } from '../../services/api'
import ErrorBoundary from '../../error/ErrorBoundary'
import '../eventManage/eventmanage.css'
import LeagueDetails from './leagueFromSport/LeagueDetails';
const SportManagement = () => {
  const [eventTab, setEventTab] = useState('')
  const [getSportData, setGetSportData] = useState([]);
  const [leagueData, setLeagueData] = useState([]);
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    const getAllData = async () => {
      const res = await getCaller('api/v1/sports/management/getSport');
      if (res?.data) {
        const cricketSport = res.data.find(sport => sport.slug === 'cricket');
        
        if (cricketSport) {
          const reorderedData = [cricketSport, ...res.data.filter(sport => sport.slug !== 'cricket')];
          setGetSportData(reorderedData);
          await getLeagueFromSport(cricketSport);
        } else {
          setGetSportData(res.data);
          await getLeagueFromSport(res.data[0]);
        }
      }
      setLoading(false);
    }
    getAllData();
  }, []);

  const getLeagueFromSport = async (e) => {
    setLoading(true);
    const res = await getCaller(`api/v1/leagues/leaguesFindById?id=${e.id}`);    
    setLeagueData(res?.data);
    setEventTab(e.id);
    setLoading(false);
  };

  return (
    <>
      <div className="wrapper">
        <div className="wrapper-head">
          <h3> EVENTS MANAGEMENTS </h3>
        </div>
        <div className="event-container">
          {
            getSportData?.length ? getSportData?.map((e) => (
              <div key={e.id} className={`event-tab ${eventTab === e.id ? 'active-event-tab' : null} `}
                onClick={() =>getLeagueFromSport(e)}>
                <div className="event-body">
                  <div className="">
                    <RiFootballFill style={{ fontSize: "1.5rem" }} />
                    <p>  {e?.name}</p>
                  </div>
                </div>
              </div>
            )):<ErrorBoundary/>
          }
        </div>
        <div className="event-content">
          {
            loading ?
             <div className="loader-wrapper"
             >
               <div className='loader'>
              </div>
             </div> : <LeagueDetails  leagueData={leagueData} setLeagueData={setLeagueData}  />
          }
        </div>
      </div>
    </>
  )
}


export default SportManagement;