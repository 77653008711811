import React, { useEffect, useState, useRef } from 'react'
import { getCaller, postCaller } from '../../services/api';
import ResultTab from './ResultTab';
import Swal from "sweetalert2";
import { motion } from "framer-motion";
import '../result/result.css'
import MatchResultTable from './MatchResultTable';
import Button from '../../component/button/Button';
import SurveillanceModel from '../../component/modal/SurveillanceModel';
import Select from 'react-select'
import Result from './result';
import getLocalData from '../../utilities/LocalStorageData.utilities';

const MatchResult = () => {
    const [sportList, setSportList] = useState([]);
    const [sport, setSport] = useState("");
    const [matchId, setMatchId] = useState("");
    const [eventId, setEventId] = useState("");
    const [marketId, setMarketId] = useState("");
    const [resultId, setResultId] = useState("");
    const [marketList, setMarketList] = useState([]);
    const [resultList, setResultList] = useState([]);
    const [resultData, setResultData] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [marketExternalId, setMarketExternalId] = useState("");
    const [isOpen, setOpen] = useState(false);
    const marketRef = useRef();
    const selectionRef = useRef();
    const sportRef = useRef();
    const eventRef = useRef();
    const [callRef, setCallRef] = useState(false);
    const [pagination, setPagination] = useState([]);
    const [fetchedPages, setFetchedPages] = useState({});
    const [operatorList, setOperatorList] = useState([]);
    const [operatorId, setOperatorId] = useState([]);
    const operatorRef = useRef();
    const limit = 100;
    const [scope,setScope] = useState({})

    useEffect(() => {

        const {result} = getLocalData('scope')
        const userType = getLocalData('user_role')
        setScope({result,userType})

        async function getSport() {
            let sportResponse = await getCaller('api/v1/sports/management/getSport');
            setSportList(sportResponse.data)
        }
        getSport()
    }, [])

   
    useEffect(() => {
      
            if (marketList && marketId) {
                const found = marketList.find(m => m.marketId === marketId);
                if (found) {
                    setMarketExternalId(found.market_id);
                } else {
                    setMarketExternalId("");
                }
            } else {
                setMarketExternalId("");
            }
         
    }, [marketId, marketList]);

    useEffect(() => {
        async function getOperators() {
            const res = await getCaller(`api/v1/results/operatorList`);
            const formattedOperators = res.data.operatorList;
            setOperatorList(formattedOperators);    
        }
        getOperators();
    }, []);
    
    const options = [{ value: 'all', label: 'Select All' }, ...operatorList.map((el) => ({ value: el, label: el }))];
    const handleOperator = (selectedOptions) => {
        if (selectedOptions.some(option => option.value === 'all')) {
            if (selectedOptions.length === options.length) {
                setOperatorId([]);
            } else {
                setOperatorId(options.slice(1));
            }
        } else {
            setOperatorId(selectedOptions);
        }
    };
    
    const getOptions = (selectedOptions) => {
        if (selectedOptions?.length === operatorList.length) {
            return operatorList.map((el) => ({ value: el, label: el }));
        }
        return options;
    };

    const handleSport = async (event) => {
        setEventList([]);
        setMatchId("");   
        setMarketId("");   
        setResultList([]);
        setResultId("")
        setOperatorId([]);
        setEventId("");

        const sport = event?.value; 

        if (sport) {
            setSport(event);
            const Response = await getCaller(`api/v1/events/findEvents?id=${sport}`);
            setEventList(Response.data);
        }
    }

    const handleEvent = async (event) => {
        setMarketList([]);
        setMarketId("");
        setResultList([]);
        setResultId("");
        setOperatorId([]);
        const Event = event.value
        if (Event) {
            setMatchId(Event);

            const marketFindResponse = await getCaller(`api/v1/markets/undeclared/match/${Event}`);
            setMarketList(marketFindResponse.data);

            let eventDetail = eventList.find(event => event.id === Event);
            setEventId(eventDetail?.event_id);
        }
    }

    const handleMarket = async (event) => {
        setResultList([]);
        setMarketId("");  
        setResultId("")
        setOperatorId([]);
        const market = event.value;
        setMarketId(market)
        const marketData = marketList.find(m => m.marketId === parseInt(market));
        if (marketData)
            setResultList(JSON.parse(marketData.runners));
        else 
            console.warn("Market data not found for:", market);
    }

    const handleResult = (event) => {
         try {
            const selectedResult = event? JSON.parse(event?.value) : '' ;
            setResultId(selectedResult);

            if (selectedResult) {
                const allOperators = operatorList.map(op => ({ value: op, label: op }));
                setOperatorId(allOperators);
            }
        } catch (error) {
            console.error("Failed to parse result:", error);
        }
    }

    async function addResult() {
        const result = resultId;
        const marketData = marketList.find(m => m.marketId === parseInt(marketId))
        const data = {
            sport_id: parseInt(sport.value),
            match_id: matchId,
            market_id: marketId,
            operators_list: operatorId.map(op => op.value),
            result: resultId,
            market_name: marketData.market_name,
            selection: result.RN,
        };
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to Submit this Result (${result.RN})?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            allowOutsideClick: () => Swal.isLoading()
        }).then(async (resposneData) => {
            if (resposneData.isConfirmed) {
                const response = await postCaller(`api/v1/results`, data)
                if (response.success === true) {
                    Swal.fire({
                        title: 'Submitted',
                        text: 'Your Result is Submitted Successfully',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000,
                        background: 'white',
                        color: 'green'
                    }).then((response) => {
                        if (response) {
                            allResult(1)
                            setCallRef(true)
                            setOperatorId([]);
                        }
                    })
                } else {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top',
                        background: "red",
                        color: "white",
                        showConfirmButton: false,
                        timer: 2000,
                        width: 300,
                        padding: ".5rem"
                    })

                    Toast.fire({
                        icon: 'error',
                        iconColor: "white",
                        title: response.message
                    })

                }
            }
        })
    }

    useEffect(() => {
        allResult()
    }, [])

    const allResult = async (page = 1) => {
        if (fetchedPages[page]) {
            setResultData(fetchedPages[page]);
            if (page !==1) return;
        }
        if(matchId !== ""){
            const marketFindResponse = await getCaller(`api/v1/markets/undeclared/match/${matchId}`)
            setMarketList(marketFindResponse.data)
        }
        const resultResponse = await getCaller(`api/v1/results/market?page=${page}&limit=${limit}`)
        setResultData(resultResponse.data)
        setPagination(resultResponse.pagination)
        setFetchedPages((prev) => ({ ...prev, [page]: resultResponse.data }));
    }

    useEffect(() => {
        if (callRef) {
            /*
            if (sportRef.current.Select || sportRef.current) {
                setSport(null); 
                sportRef.current.setValue(null)
            }

            if (eventRef.current.Select || eventRef.current) {
                eventRef.current.setValue("")
            }
            */
           
            if (marketRef.current.Select || marketRef.current) {
                marketRef.current.setValue("")
            }

            if (selectionRef.current.Select || selectionRef.current) {
                selectionRef.current.setValue("")
            }
           

            setCallRef(false)
        }
    }, [callRef]);

    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            outline: "none",
            height: "2.5rem",
            marginTop: "0.2rem",
            border: "1px solid #cbcbcb",
            borderRadius: "0.25rem",
            width: "100%",
        }),
        menu: (provided, state) => ({
            ...provided,
            zIndex: 20,
        }),
        input: (provided, state) => ({
            ...provided,
            marginTop: "-7px"
        })
    };

    return (
        <>  
            <Result />
            <ResultTab />
            <motion.div className="wrapper" initial={{ opacity: 0, x: '-100vh' }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}>
                <div className="result-sport-container">
                    <div className="result-sport-content className-fix">
                        <div className="result-event-select">
                            <label htmlFor="date"> Select Sport </label> <br />
                            <Select
                                value={sport}
                                onChange={ handleSport}
                                options={sportList.map((el) => ({ value: el.id, label: el.name }))}
                                placeholder="Select Sport"
                                ref={sportRef}
                                styles={selectStyles}
                            />
                        </div>
                        <div className="result-event-select">
                            <label htmlFor="date"> Select Event </label> <br />
                            <Select
                                value={eventId ? {   
                                     value: eventId,
                                     label: `${eventList.find(el => el.event_id === eventId)?.event_name} (${eventList.find(el => el.event_id === eventId)?.event_date.split('.')[0]})`  } : null}
                                onChange={(selectedOption) => handleEvent(selectedOption)}
                                options={eventList.map((el) => ({ value: el.id, label: `${el.event_name} (${((el.event_date).split('.')[0])})` }))}
                                styles={selectStyles}
                                ref={eventRef}
                                placeholder="Select Event"
                            />
                        </div>

                        <div className="result-event-select">
                            <label htmlFor="date"> Select Market </label> <br />
                            <Select
                                value={marketId ? { value: marketId, label: marketList.find(el => el.marketId === parseInt(marketId))?.market_name } : null}
                                onChange={(selectedOption) => handleMarket(selectedOption)}
                                options={marketList.map((el) => ({ value: el.marketId, label: el.market_name }))}
                                placeholder="Select Market"
                                ref={marketRef}
                               
                                styles={selectStyles}
                            />
                        </div>
                        <div className="result-event-select">
                            <label htmlFor="date"> Select Result </label> <br />
                            <Select
                                value={resultId 
                                    ? {
                                        value: JSON.stringify(resultId),
                                        label: resultList?.find(el => el.SID === resultId.SID)?.RN || resultId.RN
                                    } 
                                    : null
                                }
                                onChange={handleResult}
                                options={[
                                    ...(resultList?.map(el => ({
                                        value: JSON.stringify(el),
                                        label: el.RN
                                    })) || []),
                                    {
                                        value: JSON.stringify({ RN: 'tie', SID: 'tie' }),
                                        label: 'Tie'
                                    },
                                    {
                                        value: JSON.stringify({ RN: 'abandoned', SID: 'abandoned' }),
                                        label: 'Abandoned'
                                    }
                                ]}
                                placeholder="Select Result"
                                ref={selectionRef}
                                styles={selectStyles}
                            />
                        </div>
                        <div className="result-event-select hFix-100">
                            <label htmlFor="operators">Select Operators</label> <br />
                            <Select
                                tabIndex={-1}
                                isMulti
                                value={operatorId}
                                onChange={handleOperator}
                                options={getOptions(operatorId)}
                                styles={selectStyles}
                                ref={operatorRef}
                                placeholder="Select Operators"
                            />
                        </div>
                    </div>
                    <div className="book-maker-btn">
                    <Button 
                        className='submit' 
                        type='button' 
                        name='Submit Result' 
                        disabled={!(resultId !== "" && operatorId.length && (scope?.userType?.toLowerCase() !== 'manager' || scope?.result?.add))} 
                        onClick={() => { setOpen(true) }} 
                    />
                    </div>
                </div>
                <MatchResultTable resultData={resultData} setResultData={setResultData}  allResult={allResult} pagination={pagination} limit= {limit}/>
            </motion.div>
            <SurveillanceModel isOpen={isOpen} setOpen={setOpen} onCloseModel={addResult} matchID={eventId} marketID={marketExternalId} marketType={1}></SurveillanceModel>
        </>
    )
}

export default MatchResult