import React, { useEffect, useState } from 'react'
import '../eventManage/eventmanage.css'
import { RiFootballFill } from 'react-icons/ri'
import { getCaller } from '../../services/api'
import ErrorBoundary from '../../error/ErrorBoundary'
import './eventmanage.css'
import HorseRacingVanueLeagueSettings from './racingVenueLeaguesDetails'
const HorseRacingVenueLeagueManagement = () => {
  const [eventTab, setEventTab] = useState(false)
  const [getSportData, setGetSportData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [venueData, setVenueData] = useState([])
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAllData = async () => {
      const res = await getCaller('api/v1/racing/sports');
      setGetSportData(res.data)
      getCountryData(res.data[0])
      setLoading(false);
    }
    getAllData()
  }, [])

  const getCountryData = async (e, i) => {
    setLoading(true);
    const res = await getCaller(`api/v1/racing/country/all?sportId=${e.id}`)
    const resVenue = await getCaller(`api/v1/racing/venue/all?sportId=${e.id}`)

    
    const groupByCode = (arr) => {
      return arr.reduce((acc, item) => {
        const code = item.code;
        if (!acc[code]) {
          acc[code] = [];
        }
        acc[code].push(item);
        return acc;
      }, {});
    };

    const groupedData = groupByCode(resVenue.data);
    //console.log(groupedData);





    let j = 0;
    for (const item1 of res.data) {
      item1.fancylimits = {};
      res.data[j]['fancylimits'] = []
      for (const item2 of resVenue.data) {
       
        if (item1.code == item2.code) {
          res.data[j]['fancylimits'].push( item2)
         
           
         // res.data[j]['fancylimits'][`${item2.name}`] = { ...res.data[j]['fancylimits'][`${item2.name}`], ...{ venueId: item2.id } }
//res.data[j]['fancylimits'][`${item2.name}`] = item2.marketlimits
        }
      }
      j++;
    } 
    setCountryData(res.data)
    setEventTab(e.id)
    setLoading(false);
  }


  return (
    <>
      <div className="wrapper" >
        <div className="wrapper-head">
          <h3>RACING VENUE SETTINGS </h3>
        </div>
        <div className="event-container">
          {
            getSportData?.length ? getSportData?.map((e) => (
              <div key={e.id} className={`event-tab ${eventTab === e.id ? 'active-event-tab' : null} `}
                onClick={() => getCountryData(e)}>
                <div className="event-body">
                  <div className="">
                    <RiFootballFill style={{ fontSize: "1.5rem" }} />
                    <p>  {e?.name}</p>
                  </div>
                </div>
              </div>
            )) : <ErrorBoundary />
          }
        </div>
        <div className="event-content" >
          {
            loading ?
              <div className="loader-wrapper">
                <div className='loader'>
                </div>
              </div> : <HorseRacingVanueLeagueSettings leagueData={countryData} setLeagueData={setCountryData} sportId={eventTab} />
          }
        </div>
      </div>
    </>
  )
}


export default HorseRacingVenueLeagueManagement;