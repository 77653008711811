import { useFormik } from 'formik';
import React, {  useState, useRef} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { postCaller, updateCaller } from '../../services/api';
import { AiOutlineClose } from 'react-icons/ai'
import { BsUpload } from 'react-icons/bs';
import Swal from "sweetalert2";
import * as Yup from 'yup';
import '../customGames/custom.css'
import Button from '../../component/button/Button';
const CustomAddSport = () => {
    const {state} = useLocation()
    const updateData = state?.data??{}
    const isAddMode = !updateData.id
    const [newData,setNewData] = useState(updateData)
    const [sportBannerImage,setSportBannerImage] =useState("http://3.110.13.16:9904/api/v1/aws-s3/download/"+newData.sport_icon || '')
    const [bannerImage, setBannerImage] = useState("http://3.110.13.16:9904/api/v1/aws-s3/download/"+newData.banner_image || '');
    const fileInputRefs = useRef(null);
    const fileInputRef = useRef(null);

    const handleFileChange = (event, type) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
    
        reader.onload = (e) => {
          if(type === 'sport_icon'){
            setSportBannerImage(e.target.result)
          } else{
            setBannerImage(e.target.result);
          }
        };

        uploadImage(file, type);
    
        reader.readAsDataURL(file);
      }
    };
    const uploadImage = async (file,type) => {
      if (!file) {
        return;
      }
    
      try {
        const signedUrlResponse = await postCaller("api/v1/aws-s3/signedUrl", {
          file_name: file.name,
          file_type: file.type,
          file_size: file.size,
        }); 
        const signedUrl = signedUrlResponse.data.url;
        await fetch(signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type,
          },
          body: file,
        });
    
        const updatedNewData = { ...newData, ...type === 'sport_icon'?{sport_icon: signedUrlResponse.data.fileName}: {banner_image: signedUrlResponse.data.fileName}};
        if(type === 'sport_icon'){
          setSportBannerImage("http://3.110.13.16:9904/api/v1/aws-s3/download/"+updatedNewData.sport_icon)
        } else {
          setBannerImage("http://3.110.13.16:9904/api/v1/aws-s3/download/"+updatedNewData.banner_image);
        }   
        setNewData(updatedNewData)
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    };
    
    const validation = Yup.object().shape({
      name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Sport Name is Required'),
      
    });

    const formik = useFormik({
      initialValues: {
        name:newData.name,
        id:newData.id,
        sport_icon: newData.sport_icon ? newData.sport_icon : updateData.sport_icon,
        banner_image: newData.banner_image ?newData.banner_image: updateData.banner_image

      },
      validationSchema:validation,
      onSubmit: async(values) => {
         
          if(isAddMode){

            Swal.fire({
              title: 'Are you sure?',
              text: `you want to add this Sport (${values.name})`,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              allowOutsideClick: () => Swal.isLoading()
            }).then(async (result) => {
              if (result.isConfirmed) {
                const res = await postCaller(`api/v1/sports/addSports`, values)
                if (res.success === true) {
                  navigate('/custom_games/custom_sport')
                  Swal.fire({
                    title: 'Submitted',
                    text: 'Your Sport add Successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000,
                    background: 'white',
                    color: 'green'
                  }).then(response => {
                    if (response) {
                      
                    }

                  })
                }
              }
            })

            
               
              
          }
          else{
           values.sport_icon = newData.sport_icon ? newData.sport_icon :updateData.sport_icon;
            values.banner_image = newData.banner_image ? newData.banner_image: updateData.banner_image
            Swal.fire({
                    title: 'Are you sure?',
                    text: `you want to update this Sport (${values.name})`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    allowOutsideClick: () => Swal.isLoading()
                }).then(async (result) => {
                    if (result.isConfirmed) {
                      const res = await updateCaller(`api/v1/sports/updateSport?id=${updateData.id}`, values)
                        if (res.success === true) {
                          navigate('/custom_games/custom_sport') 
                            Swal.fire({
                                title: 'Submitted',
                                text: 'Your Sport Updated Successfully',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1000,
                                background: 'white',
                                color: 'green'
                            }).then(response => {
                                if (response) {

                                }
                            })
                        }
                    }
                })
          }
      },
    });
  
const navigate  = useNavigate()
  return (
    <>
    {/* <CustomSport/> */}
    <div className="sport-container">
      <div className='modal-container-one'>
        <div className='add-agent-head'>
          <div>
              {isAddMode ? "ADD SPORT" :"UPDATE SPORT"}
          </div>
          <div  className="ai-close">
            <AiOutlineClose onClick={() => navigate('/custom_games/custom_sport') }/>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit }>
          <div className="add-sport-content">
            <div className="add-sport-input">
              <label htmlFor="sport"> Sport Name * </label> <br />
              <input type="text" autoComplete="off" onBlur={formik.handleBlur}  name="name" id="name" value={formik.values.name||""}
                placeholder='Sport Name' onChange={formik.handleChange} />
                {formik.errors.name && formik.touched.name && <span className="error" style={{ color: "red" }}>
                  {formik.errors.name}
                </span>}
                <div className="" >
                  <div className="select-client">
                    <label htmlFor="date"> Sport Icon </label> <br />
                      <div className="image-preview">
                        <img src={sportBannerImage} alt="sport Preview" />
                      </div>

                    <div className="client-input">
                      <input type="file" accept="image/*"
                        onChange={(event) => handleFileChange(event, 'sport_icon')} style={{ display: 'none' }} ref={fileInputRefs} 
                      />
                      <div className="client-upload-btn" onClick={() => fileInputRefs.current.click()} >
                        <BsUpload  style={{ fontSize: '1.5rem',  color: 'white' }}  />
                      </div>
                    </div>
                  </div>  
                  <div>
                    <div className="select-client">
                      <label htmlFor="date">Banner Image</label> <br />
                      <div>
                        <div className="image-preview">
                          <img src={bannerImage} alt="Banner Preview" />
                        </div>
                      </div>
                      
                      <div className="client-input">
                        <input type="file" accept="image/*"
                          onChange={(event) => handleFileChange(event, 'banner_image')} style={{ display: 'none' }} ref={fileInputRef} 
                        />
                        <div className="client-upload-btn" onClick={() => fileInputRef.current.click()}  >
                          <BsUpload style={{  fontSize: '1.5rem', color: 'white', }} />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

            </div>
          </div>
            <div className="sport-btn-container">
              <div>
                <Button type='submit' className='submit'name={"Submit"}/> 
              </div>
            </div>
        </form>
      </div>
    </div>
    </>
  )
}

export default CustomAddSport