import React from 'react'
import { MdEdit } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

const ClientAccordian = ({oneClientData}) => {
    const navigate = useNavigate()
    return (
        <>
        <div style={{overflowX:"auto"}}> 
            <table width="100%">
                <thead>
                    <tr className='custom-row'>
                        <th> Id </th>
                        <th> Sport </th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                    oneClientData.data?.metadata?.length ?  oneClientData.data?.metadata?.map((el,i)=>(
                            <tr className='custom-row' key={i}>
                            <td data-label="#"> {el.id} </td>
                            <td data-label="UID"> {el.name} </td>
                            <td>
                                <div className='user-btn-action'>
                                    <div className='edit-btn'
                                        onClick={() => navigate('/editClient', {
                                            state: {
                                                data:oneClientData,
                                                newData:el
                                            }
                                        })}
                                        >
                                        <MdEdit
                                            style={{
                                                fontSize: '1.5rem',
                                                color: 'white'
                                            }}
                                        />
                                    </div>
                                
                                </div>
                            </td>
                        </tr>
                        )):null
                    }
                </tbody>
            </table>
        </div>
        </>
    )
}

export default ClientAccordian