import Swal from 'sweetalert2';
import '../eventManage/eventmanage.css';
import React, { useState, useEffect } from 'react';
import { getCaller, updateCaller } from '../../services/api';
import ToogleButton from '../../component/toggleButton/ToogleButton';
import { BsChevronRight, BsChevronDown } from 'react-icons/bs';
const ManageTossBookmaker = () => {
    const [marketData, setMarketData] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);

    useEffect(() => {
        const fetchMarketData = async () => {
            try {
                const response = await getCaller('api/v1/bookmakers/getData');
                setMarketData(response.data);
            } catch (error) {
                console.error('Error fetching market data:', error);
            }
        };
        fetchMarketData();
    }, []);

    const toggleEventDetails = (eventName) => {
        setSelectedEvent(selectedEvent === eventName ? null : eventName);
    };

    const handleInputChange = (event, marketId, field) => {
        const updatedData = marketData.map(market => {
            if (market.id === marketId) {
                return {
                    ...market,
                    [field]: event.target.value
                };
            }
            return market;
        });
        setMarketData(updatedData);
    };

    const handleToggleChange = (marketId, field, value) => {
        const updatedData = marketData.map(market => {
            if (market.id === marketId) {
                return {
                    ...market,
                    [field]: value === true ? 1 : 0
                };
            }
            return market;
        });
        setMarketData(updatedData);
    };

    const handleUpdate = async (market) => {
        const updatedBookmakerData = {
            stake_limit: market.stake_limit,
            max_market_limit: market.max_market_limit,
            bet_delay: market.bet_delay,
            time_range: market.time_range,
            is_sus: market.is_sus,
            is_active: market.is_active,
            is_com: market.is_com,
        };
        
        try {
            const response = await updateCaller(`api/v1/bookmakers/updateToss/${market.id}`, updatedBookmakerData);
            if (response.message === 'SUCCESS') {
                Swal.fire({
                    icon: 'success',
                    title: 'Toss bookmaker has been updated successfully.',
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000,
                    background: 'white',
                    color: 'green'
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Update Failed',
                    text: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000,
                    background: 'white',
                    color: 'red'
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to update toss bookmaker.',
                toast: true,
                showConfirmButton: false,
                timer: 2000,
                background: 'white',
                color: 'red'
            });
        }
    };

    return (
        <div className="wrapper">
            <div className="wrapper-head">
                <h3> Market Toss Bookmaker </h3>
            </div>
            <div className="market-section" style={{ padding: '8px' }}>
                {marketData.length ? marketData.map((market, index) => (
                    <React.Fragment key={index}>
                        <div
                            className={`event-header ${selectedEvent === market.event_name ? 'active' : 'inactive'}`}
                            style={{ cursor: 'pointer', marginBottom: '8px', padding: '8px', borderBottom: 'groove', display: 'flex', alignItems: 'center' }}
                            onClick={() => toggleEventDetails(market.event_name)}
                        >
                            <button
                                className={`accordion ${selectedEvent === market.event_name ? 'active' : 'inactive'}`}
                                style={{ background: 'none', border: 'none', fontSize: '15px', marginRight: '10px', cursor: 'pointer' }}
                            >
                                {selectedEvent === market.event_name ? (
                                    <BsChevronDown style={{ fontSize: '1rem', fontWeight: 'bold' }} />
                                ) : (
                                    <BsChevronRight style={{ fontSize: '1rem', fontWeight: 'bold' }} />
                                )}&nbsp;
                                <p>{market.event_name}</p>
                            </button>
                            
                        </div>

                        {selectedEvent === market.event_name && (
                            <div className="table-border tableRes">
                                <table width="100%">
                                    <thead>
                                        <tr className='headingRes'>
                                            <th>Stake Limit</th>
                                            <th>Max Market Limit</th>
                                            <th>Bet Delay</th>
                                            <th>Expire Time</th>
                                            <th>Suspend</th>
                                            <th>Active</th>
                                            <th>Commission</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='custom-row'>
                                            <td>
                                                <input
                                                    autoComplete="off"
                                                    type="number"
                                                    placeholder="Stake Limit"
                                                    value={market.stake_limit}
                                                    onChange={(e) => handleInputChange(e, market.id, 'stake_limit')}
                                                    className="league-detail-input"
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    autoComplete="off"
                                                    type="number"
                                                    placeholder="Max Market Limit"
                                                    value={market.max_market_limit}
                                                    onChange={(e) => handleInputChange(e, market.id, 'max_market_limit')}
                                                    className="league-detail-input"
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    autoComplete="off"
                                                    type="number"
                                                    placeholder="Bet Delay"
                                                    value={market.bet_delay || ''}
                                                    onChange={(e) => handleInputChange(e, market.id, 'bet_delay')}
                                                    className="league-detail-input"
                                                />
                                            </td>
                                            <td>
                                                <select
                                                    value={market.time_range || '1'}
                                                    onChange={(e) => handleInputChange(e, market.id, 'time_range')}
                                                    className="league-detail-input"
                                                >
                                                    <option value="1">1 Hour</option>
                                                    <option value="2">2 Hour</option>
                                                    <option value="3">3 Hour</option>
                                                    <option value="4">4 Hour</option>
                                                </select>
                                            </td>
                                            <td align="left">
                                                <div className="table-flex">
                                                    <ToogleButton
                                                        onChange={(value) => handleToggleChange(market.id, 'is_sus', value)}
                                                        defaultChecked={market.is_sus === 1}
                                                    />
                                                </div>
                                            </td>
                                            <td align="left">
                                                <div className="table-flex">
                                                    <ToogleButton
                                                        onChange={(value) => handleToggleChange(market.id, 'is_active', value)}
                                                        defaultChecked={market.is_active === 1}
                                                    />
                                                </div>
                                            </td>
                                            <td align="left">
                                                <div className="table-flex">
                                                    <ToogleButton
                                                        onChange={(value) => handleToggleChange(market.id, 'is_com', value)}
                                                        defaultChecked={market.is_com === 1}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="custom-row">
                                            <td colSpan={6}>
                                                <button
                                                    className="update-league-btn"
                                                    type="button"
                                                    onClick={() => handleUpdate(market)}
                                                >
                                                    Update
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </React.Fragment>
                )) : (
                    <p>No Data Found</p>
                )}
            </div>
        </div>
    );
};

export default ManageTossBookmaker;
