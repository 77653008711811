import React, { useEffect, useState } from 'react'
import Pagination from '../../component/pagination/Pagination'
import Button from '../../component/button/Button'
import { postCaller } from '../../services/api'
import '../result/result.css'
import Swal from "sweetalert2";
import { BsChevronDown, BsChevronRight } from "react-icons/bs"
import moment from 'moment';
import ServerToBrowserTime from './serverToBrowser';
import getLocalData from '../../utilities/LocalStorageData.utilities'

const MatchResultTable = ({ resultData, setResultData,allResult,totalPage }) => {
  let userType = localStorage?.getItem('user_role')?.toLowerCase();
  const [rowOpen, setRowOpen] = useState(null);
  const [showResultStatus, setShowResultStatus] = useState(false);
  const [showRollbackStatus, setShowRollbackStatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(resultData?.paginationData?.currentPage || 1);
  const [scope, setScope] = useState({})

  useEffect(()=>{
    const {rollback} = getLocalData('scope')
    const userType = getLocalData('user_role')
    setScope({rollback, userType})
  },[])
  let completeData = resultData?.filter(() => true);

 const handlePageChange = async(page) => {
    setCurrentPage(page);
    await allResult(page)
  };

  const handleRollBack = async (e) => {
    const resultID = e.market_id;
    let id
    Swal.fire({
      title: 'Are you sure?',
      text: 'Result is Rollback',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await postCaller(`api/v1/worli/matches/rollback/${resultID}`)
        if (response.success === true) {
          id = response.data.resultId
          Swal.fire({
            title: 'Submitted',
            text: response.msg,
            icon: 'success',
            showConfirmButton: false,
            timer: 1000,
            background: 'white',
            color: 'green',
          }
          ).then(async function () {
            setResultData(resultData.map(e => e.id !== id ? e : { ...e, is_rollback: 1 }))
          })
        } else {

          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            background: "red",
            color: "white",
            showConfirmButton: false,
            timer: 2000,
            width: 300,
            padding: ".5rem"
          })

          Toast.fire({
            icon: 'error',
            iconColor: "white",
            title: response.message
          })

        }
      }
    })
  }

  const toggleRow = (index) => {
    if (rowOpen === index) {
      setRowOpen(null);
    } else {
      setRowOpen(index);
      setShowRollbackStatus(false);
      setShowResultStatus(false);
    }
  };

  const toggleResultStatus = () => {
    setShowResultStatus(!showResultStatus)
    setShowRollbackStatus(false)
  }

  const toggleRollbackStatus = () => {
    setShowRollbackStatus(!showRollbackStatus)
    setShowResultStatus(false)
  }

  return (
    <>
      <div className="table-border" >
        <div className="wrapper-body">
          <div className="wrapper-content-1">
          </div>
        </div>
        <div style={{ overflowX: "auto" }} >
          <table width="100%" >
            <thead>
              <tr className='custom-row' >
                <th> # </th>
                <th><div style={{ width: "60px" }}> Market Title</div> </th>
                <th><div> Market Time </div></th>
                <th><div> Market Type </div></th>
                <th><div> Result </div></th>
                <th><div style={{ width: "105px" }}> Result Time  </div></th>

                {
                  (scope?.userType === "admin" || (scope?.userType === 'manager' && scope?.rollback.add)) && <th> <div> ROLLBACK </div> </th>
                }
              </tr>
            </thead>

            <tbody>
              {
                completeData?.length > 0 ?
                  completeData?.map((e, i) => (
                    <React.Fragment key={i}>
                      <tr className='custom-row' key={i} onClick={() => toggleRow(i)}>
                         <td><div className='result_status'>
                          {rowOpen === i ? (
                            <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                          ) : (
                            <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                          )}
                          {e?.id ?? "Auto"}
                        </div> </td>  
                        <td > {e?.market_title} </td>
                        <td > 
                          <ServerToBrowserTime serverTime={e.market_time} /> 
                          </td>
                        <td style={{ textTransform: "capitalize" }}> {e.market_type} </td>
                        <td > <div >{e.result} </div> </td>
                        <td > <div ><ServerToBrowserTime serverTime={e.result_time} />
                        </div> </td>
                        {
                        (scope?.userType === "admin" || (scope?.userType === 'manager' && scope?.rollback.add)) && <td >
                          <div> <Button className = 'submit' type = 'button' name = 'RollBack'
                            onClick = {() => handleRollBack(e)} disabled = {(scope?.userType ==='manager' && !scope?.rollback?.add) || e.is_rollback} /> </div>
                          </td>
                        }
                      </tr>
                      {
                        rowOpen === i && (
                          <>
                            <tr className='custom-row' onClick={toggleResultStatus}>
                              <td colSpan={8}>
                                <div className='result_status'>&nbsp; &nbsp;
                                  {showResultStatus ? (
                                    <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                                  ) : (
                                    <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                                  )}
                                  Result Status
                                </div>
                              </td>
                            </tr>

                            {e.result_status && showResultStatus && (
                              <tr className='custom-row'>
                                <td colSpan={9}>
                                  <div className='result_status'>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Market</th>
                                          <th>Status</th>
                                          <th>Start Time</th>
                                          <th>End Time</th>
                                          <th>Process Count</th>
                                          <th>User Count</th>
                                          <th>Bet Count</th>
                                          <th>End Point</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Object.entries(JSON.parse(e.result_status)).map(([market, data], index) => (
                                          <tr key={index}>
                                            <td>{market}</td>
                                            <td>{data.status}</td>
                                            <td>{(data.startTime === '-') ? data.startTime:moment.unix(data.startTime).format('YYYY-MM-DD HH:mm') }</td>
                                            <td>{(data.endTime === '-') ? data.endTime : moment.unix(data.endTime).format('YYYY-MM-DD HH:mm')}</td>
                                            <td>{data.processCount}</td>
                                            <td>{data.userCount}</td>
                                            <td>{data.betCount}</td>
                                            <td>{data.endpoint}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            )}

                          { e.is_rollback ? (
                            <tr className='custom-row' onClick={toggleRollbackStatus}>
                              <td colSpan={8}>
                                <div className='result_status'> &nbsp; &nbsp;
                                  {showRollbackStatus ? (
                                    <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                                  ) : (
                                    <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                                  )}
                                  Rollback Status
                                </div>
                              </td>
                            </tr>
                            ) : "" }
                            {e.rollback_status && showRollbackStatus && (
                              <tr className='custom-row'>
                                <td colSpan={9}>
                                  <div className='result_status'>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Market</th>
                                          <th>Status</th>
                                          <th>Start Time</th>
                                          <th>End Time</th>
                                          <th>Process Count</th>
                                          <th>User Count</th>
                                          <th>Bet Count</th>
                                          <th>End Point</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Object.entries(JSON.parse(e.rollback_status)).map(([market, data], index) => (
                                          <tr key={index}>
                                            <td>{market}</td>
                                            <td>{data.status}</td>
                                            <td>{(data.startTime == '-') ? data.startTime : moment.unix(data.startTime).format('YYYY-MM-DD HH:mm')}</td>
                                            <td>{(data.endTime == '-') ? data.endTime : moment.unix(data.endTime).format('YYYY-MM-DD HH:mm')}</td>
                                            <td>{data.processCount}</td>
                                            <td>{data.userCount}</td>
                                            <td>{data.betCount}</td>
                                            <td>{data.endpoint}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </>
                        )
                      }

                    </React.Fragment>
                  )) : <tr className=''><td className='no-data-coloumn' align='center' colSpan={10}>No Data</td></tr>
              }
            </tbody>
          </table>
        </div>
      </div>
      {/* <Pagination page={page} setPage={setPage} setPerPage={setPerPage} maximo={maximo} /> */}
      <Pagination
                    currentPage={currentPage}
                    totalPages={totalPage}
                    onPageChange={handlePageChange}
                />
    </>
  )
}

export default MatchResultTable