import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { postCaller, updateCaller, getCaller } from '../../services/api';
import { AiOutlineClose } from 'react-icons/ai';
import '../customGames/custom.css';
import Button from '../../component/button/Button';
import Swal from 'sweetalert2';
import Select from 'react-select';

const AddWorliMatkaMatch = () => {
  const { state } = useLocation()
  const updateData = state?.data ?? {}
  const isAddMode = !updateData.id
  const [newData] = useState(updateData)
  const [categoryDropDownValue, setCategoryDropDownValue] = useState([])
  const [matchTypeOptions, setMatchTypeOptions] = useState({})
  const [checkedOptions, setCheckedOptions] = useState({});
  const [openBidsTime, setOpenBidsTime] = useState(updateData.open_bids || '');
  const [closeBidsTime, setCloseBidsTIme] = useState(updateData.closeBidsTime || '');
  const [dailyDateOption, setDailyDateOption] = useState([])
  const [updateOptionKey, setUpdateOptionKey] = useState([]);

  const dailyDayOption = [{ value: "MONDAY", label: "MONDAY" }, { value: 'TUESDAY', label: 'TUESDAY' }, { value: 'WEDNESDAY', label: 'WEDNESDAY' }, { value: 'THURSDAY', label: 'THURSDAY' }, { value: 'FRIDAY', label: 'FRIDAY' }, { value: 'SATURDAY', label: 'SATURDAY' }, { value: 'SUNDAY', label: 'SUNDAY' }];

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const response = await getCaller('api/v1/worli/categories/all');
        const categoryData = response.data.data.map((data) => { return { label: data.category_name, value: data.id } })
        setCategoryDropDownValue(categoryData);
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    };

    const fetchMatchTypeOptions = async () => {
      try {

        const response = await getCaller('api/v1/worli/matchtypes')
        setMatchTypeOptions(response.data.data)

      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    }

    fetchDropdownOptions();
    fetchMatchTypeOptions();

    let tempDateValues = [];
    
    for (let i = 1; i <= 31; i++) {
      tempDateValues.push({ label: i, value: i })
    }
    setDailyDateOption(tempDateValues)
  }, []);


  useEffect(() => {
    const checkInitialMatchType = () => {
      if (!isAddMode) {
        const updateMatchType = JSON.parse(updateData.match_type);
        const optionKeys = Object.keys(updateMatchType);
        setUpdateOptionKey(optionKeys);  
        setCheckedOptions(updateMatchType);
      }
    };

    checkInitialMatchType();
  }, [updateData.match_type])


  const handleCheckboxChange = (optionKey) => {
    setCheckedOptions((prevChecked) => {
      const updatedChecked = { ...prevChecked };
      
      if (updatedChecked[optionKey]) {
        delete updatedChecked[optionKey];
      } else {
        updatedChecked[optionKey] = matchTypeOptions[optionKey];
      }
      formik.setFieldValue('matchType', updatedChecked);
      return updatedChecked;
    });
  };
  
  const handleInputChange = (optionKey, field, value) => {
    setCheckedOptions((prevOptions) => {
      const updatedOptions = { ...prevOptions };
      updatedOptions[optionKey][field] = value;
      formik.setFieldValue('matchType', updatedOptions);
      return updatedOptions;
    });
  };
  
  const handleOpenBidsTime = (event) => {
    const currentTime = event.target.value;
    const formattedTime = currentTime.substr(0, 5);
    setOpenBidsTime(formattedTime);
    formik.setFieldValue('openBids', formattedTime);
  };

  const handleCloseBidsTime = (event) => {
    const currentTime = event.target.value;
    const formattedTime = currentTime.substr(0, 5);
    setCloseBidsTIme(formattedTime);
    formik.setFieldValue('closeBids', formattedTime);
  };

  const formik = useFormik({
    initialValues: {
      matchTitle: newData.match_title ?? '',
      categoryId: newData.category_id ?? '',
      openBids: newData.open_bids ?? '',
      closeBids: newData.close_bids ?? '',
      message: newData.message ?? '',
      closedDate: updateData.closed_date ? JSON.parse(updateData.closed_date) : [],
      closedDay: updateData.closed_day ? JSON.parse(updateData.closed_day) : [],
      password: ''
    },
    
    validate: (values) => {
      const errors = {};
      if (!values.matchTitle) {
        errors.matchTitle = 'Match Title is required';
      }
      if (!values.closeBids) {
        errors.closeBids = 'CloseBids is required'
      }
      if (!values.openBids) {
        errors.openBids = 'OpenBids is required'
      }
      if (!values.categoryId) {
        errors.categoryId = 'Category ID is required';
      }
      if (!values.password) {
        errors.password = 'Password is required';
      }
      return errors;
    },

    onSubmit: async (values) => {
      if (isAddMode) {
        values["closedDate"] = values["closedDate"] ?? [];
        values["closedDay"] = values["closedDay"] ?? [];

        const resultConfirmation = await Swal.fire({
          title: 'Are you sure?',
          text: `You want to save this matka?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        });

        if (resultConfirmation.isConfirmed) {
          const res = await postCaller(`api/v1/worli/matches`, values)
          if (res.success === true) {
            Swal.fire({
              title: 'Submitted',
              text: 'Your Match Added Successfully',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000,
              background: 'white',
              color: 'green'
            });
            navigate('/manage/worli-matka')
          }
          else {
            Swal.fire({
              title: 'Error',
              text: res.message,
              icon: 'warning',
              showConfirmButton: false,
              timer: 2000
            });
          }
        }
      } else {
        values['openSuspend'] = updateData.open_suspend ?? '';
        values['closeSuspend'] = updateData.close_suspend ?? '';
        values['categoryId'] = values.categoryId ? String(values.categoryId) : String(updateData.category_id);
        values['suspend'] = updateData.suspend ?? '';
        values['isActive'] = updateData.is_active ?? '';
        values['isDelete'] = updateData.is_deleted ?? '';
        values['id'] = updateData.id ?? '';
        values['message'] = values.message ?? '';
        values['openBids'] = values.openBids ?? updateData.open_bids;
        values['matchType'] = values.matchType ?? JSON.parse(updateData.match_type);
        values['closed_date'] = JSON.stringify(values.closedDate) ?? updateData.closed_date;
        values['closed_day'] = JSON.stringify(values.closedDay) ?? updateData.closed_day;
        delete (values["closedDate"]);
        delete (values["closedDay"]);

        const resultConfirmation = await Swal.fire({
          title: 'Are you sure?',
          text: `You want to update this matka?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        });

        if (resultConfirmation.isConfirmed) {
          const res = await updateCaller(`api/v1/worli/matches/${updateData.id}`, values)
          if (res.success === true) {
            Swal.fire({
              title: 'Submitted',
              text: 'Your Match Updated Successfully',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000,
              background: 'white',
              color: 'green'
            });
            navigate('/manage/worli-matka')
          }
          else {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              background: "red",
              color: "white",
              showConfirmButton: false,
              timer: 2000,
              width: 400,
              padding: ".5rem"
            })

            Toast.fire({
              icon: 'warning',
              iconColor: "white",
              title: res.message
            })
          }
        }
      }
    },
  });

  const navigate = useNavigate()

  return (
      <div className="sport-container">
        <div className='modal-container-one'>
          <div className='add-agent-head'>
            <div>
              {isAddMode ? "Add Worli Matka Match" : "Update Worli Matka Match"}
            </div>
            <div className="ai-close">
              <AiOutlineClose onClick={() => navigate('/manage/worli-matka')} />
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="add-sport-content">
              <div className="add-sport-input">
                <label htmlFor="categoryId">Select Category *</label> <br />

                <Select
                  name="categoryId"
                  onBlur={formik.handleBlur}
                  onChange={(selectedOption) => formik.setFieldValue('categoryId', selectedOption ? selectedOption.value : '')}
                  options={categoryDropDownValue.map(option => ({ value: option.value, label: option.label }))}
                  defaultValue={{value:newData?.category_id ?? '',label:newData?.category_name ?? ''}}
                  placeholder="Select an option"
                />
                <label htmlFor="sport"> Match Name * </label> <br />
                <input
                  type="text"
                  autoComplete="off"
                  name="matchTitle"
                  id="matchTitle"
                  value={formik.values.matchTitle ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder='Match Name'
                />

                <label htmlFor="message"> Message</label>
                <input
                 type="text" 
                 autoComplete="off" 
                 onBlur={formik.handleBlur}
                 name="message" id="message"
                 value={formik.values.message ?? ''}
                 onChange={formik.handleChange} />
                <label htmlFor="matchType"> Match Type * </label> <br />
                <table width="100%" style={{ overflowX: "auto" }}>
                  {isAddMode ? (<thead><tr>
                    <th></th>
                    <th>Option</th>
                    <th>Rate</th>
                    <th>Stake</th>
                  </tr></thead>
                ):(
                <thead>
                  <tr>
                    <th>Option</th>
                    <th>Rate</th>
                    <th>Stake</th>
                  </tr>
                  </thead>
                  )
                  }
                  {isAddMode ? (<tbody>
                    {Object.keys(matchTypeOptions).map((option) => (
                      <tr key={option}>
                        <td style={{ padding: "10px", width: "10%" }}>
                          <input
                            type="checkbox"
                            autoComplete="off"
                            checked={!!checkedOptions[option]}
                            onChange={() => handleCheckboxChange(option)}
                          />
                        </td>
                        <td className="name-container" style={{ padding: "10px" }}>
                          {option}
                        </td>
                        <td>
                          <input
                            type="text"
                            autoComplete="off"
                            value={checkedOptions[option]?.rate ?? matchTypeOptions[option]?.rate}
                            disabled={!checkedOptions[option]}
                            onChange={(e) => handleInputChange(option, 'rate', e.target.value)}
                          />
                        </td>
                        <td style={{ padding: "10px" }}>
                          <input
                            type="text"
                            autoComplete="off"
                            value={checkedOptions[option]?.stake ?? matchTypeOptions[option]?.stake}
                            disabled={!checkedOptions[option]}
                            onChange={(e) => handleInputChange(option, 'stake', e.target.value)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>) : (<tbody>
                    {Object.keys(matchTypeOptions).map((option) => (
                       
                        updateOptionKey.includes(option)&& (<tr key={option}>
                        
                        <td className="name-container" style={{ padding: "10px" }}>
                          {option} 
                        </td>
                        <td>
                          <input
                            type="text"
                            autoComplete="off"
                            value={checkedOptions[option]?.rate ?? matchTypeOptions[option]?.rate}
                            disabled={true}
                            onChange={(e) => handleInputChange(option, 'rate', e.target.value)}
                          />
                        </td>
                        <td style={{ padding: "10px" }}>
                          <input
                            type="text"
                            autoComplete="off"
                            value={checkedOptions[option]?.stake ?? matchTypeOptions[option]?.stake}
                            disabled={!checkedOptions[option]}
                            onChange={(e) => handleInputChange(option, 'stake', e.target.value)}
                          />
                        </td>
                      </tr>) 
                    ))}
                  </tbody>)} 
                  
                </table>
                <label htmlFor="openBids"> Bids Open Time *</label>
                <input
                  type="time"
                  autoComplete="off"
                  id="openBids"
                  name="openBids"
                  value={openBidsTime ||updateData.open_bids|| ''}
                  onChange={handleOpenBidsTime}
                />

                <label htmlFor="closeBids"> Bids Close Time *</label>
                <input
                  type="time"
                  autoComplete="off"
                  id="closeBids"
                  name="closeBids"
                  value={closeBidsTime || updateData.close_bids || ''}
                  onChange={handleCloseBidsTime}
                />
                <label htmlFor="closed-on-label"> Closed On :- </label>
                <br></br>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ width: "40%" }}>
                    <label htmlFor="daily-date-option">Select Date </label> <br />
                    <Select
                      name="daily-day-option"
                      onBlur={formik.handleBlur}
                     
                      value={dailyDateOption.filter(option => 
                        formik.values.closedDate?.includes(option.value)
                    )}
                      onChange={(selectedOption) => {
                        formik.setFieldValue('closedDate', selectedOption.map(option => option.value))
                      }}
                      options={dailyDateOption.map(option => ({ value: option.value, label: option.label }))}
                      isSearchable
                      isMulti
                      className='basic-multi-select'
                      classNamePrefix='select'
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          outline: "none",
                          height: "auto",
                          marginTop: "0.2rem",
                          border: "1px solid #cbcbcb",
                          borderRadius: "0.25rem",
                          width: "100%",
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          zIndex: 20,
                          width: "100%"
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          marginTop: "-7px",

                        })
                      }}

                      placeholder="closed date"

                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <label htmlFor="daily-day-option">Select Day</label> <br />
                    <Select
                      name="daily-day-option"
                      onBlur={formik.handleBlur}
                      value={dailyDayOption.filter(option => 
                        formik.values.closedDay?.includes(option.value)
                    )}
                      onChange={(selectedOption) => formik.setFieldValue('closedDay', selectedOption.map(option => option.value))}
                      options={dailyDayOption.map(option => ({ value: option.value, label: option.label }))}
                      isSearchable
                      isMulti
                      className='basic-multi-select'
                      classNamePrefix='select'
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          outline: "none",
                          height: "auto",
                          marginTop: "0.2rem",
                          border: "1px solid #cbcbcb",
                          borderRadius: "0.25rem",
                          width: "100%",
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          zIndex: 20,
                          width: "100%"
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          marginTop: "-7px",

                        })
                      }}

                      placeholder="closed day"

                    />
                  </div>
                </div>

              </div>
            </div>

             <input
            type="text" 
            style={{ position: 'absolute', left: '-9999px' ,opacity: '0',zIndex: '-11111' }} 
             placeholder='search'
             autoComplete='off'
            /> 

            <div className="sport-btn-container">
              <div style={{ "display": "flex", "gap": "10px" }}>
                <input type='password' autoComplete="new-password" placeholder='Password' name='password' onChange={formik.handleChange} style={{ "border": "#0000007d 4px solid" }} />
                <Button
                  type='submit'
                  className='submit'
                  name="Submit"
                  disabled={Object.keys(formik.errors).length > 0 ||
                     !formik.dirty||
                     (isAddMode && !Object.keys(checkedOptions).some(option => checkedOptions[option] !== undefined))
                  }
                />
              </div>
            </div>
          </form>
        </div>
      </div>
  )
}

export default AddWorliMatkaMatch