import React from 'react'
import { useFormik } from 'formik';
import '../user/user.css';
import Swal from "sweetalert2";
import { AiOutlineClose } from 'react-icons/ai'
import { postCaller,} from '../../services/api';
import {  useNavigate } from 'react-router-dom';

import Button from '../../component/button/Button';
const AddClient = () => {
    const navigate = useNavigate()

    // const isAddMode = !updateData.id
    const formik = useFormik({
        initialValues: {
            client_name: "",
        },
        
        validate: (values) => {
            const errors = {};

            if (!values.client_name.trim()) {
                errors.client_name = 'Client Name is required';
            }

            return errors;
        },
        /*
        onSubmit: async (values) => {
            const res = await postCaller(`api/v1/clients/addClient`, values)
            if(res.success===true){
                navigate('/clients')
            }
            
        },
        */

        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (Object.keys(formik.errors).length === 0) {
                    const res = await postCaller(`api/v1/clients/addClient`, values);
                    if (res.success === true) {
                        Swal.fire({
                            title: 'Submitted',
                            text: 'Client Added Successfully',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1000,
                            background: 'white',
                            color: 'green'
                        }).then(() => {
                            navigate('/clients');
                        });
                    } else {
                        Swal.fire({
                            title: 'Error',
                            text: res.message,
                            icon: 'warning',
                            showConfirmButton: false,
                            timer: 1000
                        });
                    }
                }
            } catch (error) {
                console.error("Error:", error);
            } finally {
                setSubmitting(false);
            }
        }
    });
    return (
        <>
            {/* <AgentDownline/> */}
            <div className="sport-container">
                <div className="modal-container-one">
                    <div className='add-agent-head'>
                        <div>
                            Add Client
                        </div>
                        <div className="ai-close">
                            <AiOutlineClose onClick={() => navigate('/clients')}
                            />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='agent-add-form'>
                            <div className="agent-input-body">
                                <label htmlFor="client_name"> Client Name <span style={{
                                    color: 'red'
                                }}>*</span> </label> <br />
                                <input type="text" autoComplete="off" name="client_name" onBlur={formik.handleBlur} value={formik.values.client_name} onChange={formik.handleChange} className='agent_input' />
                                {/* validation message */}
                                {formik.touched.client_name && formik.errors.client_name ? (
                                    <div className="error">{formik.errors.client_name}</div>
                                ) : null}
                            </div>
                            {/* <div className="select-client">
                                <label htmlFor="date"> Sport Icon </label> <br />
                               <div className="client-input">
                               <select className='select-field' >
                                    <option value="">Select Sport_icon</option>
                                    {
                                        sportClientData.metadata.map((el, i) => (
                                            <option value="" className='option-select'> {el.sport_icon} </option>
                                        ))
                                    }
                                </select>
                                <div className='client-upload-btn'>
                                        <BsUpload
                                            style={{
                                                fontSize: '1.5rem',
                                                color: 'white'
                                            }}
                                        />
                                    </div>
                               </div>
                            </div>
                            <div className="select-client">
                                <label htmlFor="date">Banner Image </label> <br />
                                <div className="client-input">
                                <select className='select-field' >
                                    <option value="">Select Sport_icon</option>
                                    {
                                        sportClientData.metadata.map((el, i) => (
                                            <option value="" key={i}> {el.sport_icon} </option>
                                        ))
                                    }

                                </select>
                                <div className='client-upload-btn'>
                                        <BsUpload
                                            style={{
                                                fontSize: '1.5rem',
                                                color: 'white'
                                            }}
                                        />
                                    </div>
                                    </div>
                            </div> */}
                            <div className="change-password-container">
                                <Button type={'submit'} name={"Submit"} className={"submit"} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddClient