import React, { useState } from 'react'
import { MdDelete } from 'react-icons/md';
import ToogleButton from '../../component/toggleButton/ToogleButton';
import Swal from "sweetalert2";
import { motion } from "framer-motion";
import { getCaller, updateCaller } from '../../services/api';
import ErrorBoundary from '../../error/ErrorBoundary';
import '../eventManage/leagueFromSport/leagueManage.css'
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
const HorseRacingVanueLeagueSettings = ({ leagueData, setLeagueData, leagueSetting, setLeagueSetting }) => {
  const [open, setOpen] = useState([]);
  const [open1, setOpen1] = useState([]);
  const [openMain, setOpenMain] = useState([]);
  const [openMain1, setOpenMain1] = useState([]);
  const [openFancy, setOpenFancy] = useState([]);
  const [openFancy1, setOpenFancy1] = useState([]);
  const [openBookmaker, setOpenBookmaker] = useState([]);

  const toggleAccordian = (index, setOpenState) => {
    setOpenState((prevState) =>
      prevState.includes(index) ? prevState.filter((i) => i !== index) : [...prevState, index]
    );
  };
 const toggleAccordian1 = (index, setOpenState) => {
    setOpenState((prevState) =>
      prevState.includes(index) ? prevState.filter((i) => i !== index) : [...prevState, index]
    );
  };
  const handleLeagueAccordian = (index) => {
    toggleAccordian(index, setOpen);
  };
  const handleLeagueAccordian1 = (index) => {
    toggleAccordian1(index, setOpen1);
  };


  const handleMarketAccordian = (index) => {
    toggleAccordian(index, setOpenMain);
  };
const handleMarketAccordian1 = (index) => {
    toggleAccordian1(index, setOpenMain1);
  };


  const handleFancyAccordian = (index) => {
    toggleAccordian(index, setOpenFancy);
  };
  const handleFancyAccordian1 = (index) => {
    toggleAccordian1(index, setOpenFancy1);
  };
 

  async function handleDeleteLeague(id, i) {
    Swal.fire({
      title: 'Are you sure?',
      text: `This League Delete`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await updateCaller(`api/v1/racing/country/${id}`, { ["is_deleted"]: 1 })
        if (response.success === true) {
          Swal.fire({
            title: 'Submitted',
            text: 'Your League is deleted Successfully',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000,
            background: 'white',
            color: 'green'
          }).then(function () {
            const newList = [...leagueData];
            newList.splice(i, 1);
            setLeagueData(newList)
          })
        } else {
          Swal.fire({
            title: 'Error',
            text: response.message,
            icon: 'warning',
            showConfirmButton: true,
            timer: 5000
          })
        }
      }
    })

  }

  const handleChange = (fieldName, index, value) => {
    let newFormValues = [...leagueData]
    newFormValues[index].marketlimits['*'][fieldName] = value;
    setLeagueData(newFormValues)
  }
 
  const handleChangeFancy = (fieldName, index, value,k,j,val) => {
    if (isNaN(value) || value < 0) {
      value = 0; 
   }
    let newFormValues = [...leagueData]
    //const fancylimitsObject = Object.keys(newFormValues[rowIndex].fancylimits)
   // console.log(newFormValues[index].fancylimits[k].marketlimits['*'].fieldName)
    newFormValues[index].fancylimits[k].marketlimits['*'][fieldName] = value || 0

    setLeagueData(newFormValues)
    updateVenueSetting(val, k, 'venue')
  }

  const handleChangeBookmaker = (key, index, value) => {
    let newFormValues = [...leagueData]
    newFormValues[index].bookmaker_limits[key] = value;
    setLeagueData(newFormValues)
  }

  const updateLeagueSettings = async (id, i, limitType) => {
    const limitObject = limitType === 'country' ? leagueData[i] :
      limitType === 'venue' ? leagueData[i].fancy_limits :
        limitType === 'bookmaker_limits' ? leagueData[i].bookmaker_limits : {};
    // limitObject.marketlimits = JSON.stringify(limitObject.marketlimits)
    const jsonObject = (limitObject)
    const response = await updateCaller(`api/v1/racing/marketlimits`, {  tableName: limitType,jsonObject })
    if (response) {
      const Toast = Swal.mixin({
        toast: true,
        showConfirmButton: false,
        timer: 2000,
        width: 480,
        padding: ".5rem",
        background: "black",
        color: "white",
      })

      Toast.fire({
        icon: 'success',
        title: response.message,
        background: 'white',
        color: 'green'
      })
    }
  }
  const updateVenueSetting = async (value, i, buttonNumber, venueId) => {
    
    const buttonClicked = Object.keys(leagueData[i]) 
    const limitObject = leagueData[i] 
    const jsonObject = (value)
    
    const response = await updateCaller(`api/v1/racing/chanegemarketlimits`, { tableName: buttonNumber, jsonObject })

    let title = jsonObject.marketlimits['*'].is_active ? " Active" : " Inactive";

    if (response) {
      const Toast = Swal.mixin({
        toast: true,
        showConfirmButton: false,
        timer: 2000,
        width: 480,
        padding: ".5rem",
        background: "black",
        color: "white",
      })

      Toast.fire({
        icon: 'success',
        title: title,
        background: 'white',
        color: 'green'
      })
    }
  }

  const toggleActive = (key, index) => {
    let newFormValues = [...leagueData];
    newFormValues[index].marketlimits[key].is_active = newFormValues[index].marketlimits[key].is_active === 0 ? 1 : 0;
    
    setLeagueData(newFormValues);
  };
  
  const toggleActiveFancy = (key, rowIndex, index, j,val) => {
    let newFormValues = [...leagueData];
    //console.log(newFormValues[rowIndex].fancylimits[index].marketlimits[key].is_active)
   // const fancylimitsObject = Object.keys(newFormValues[rowIndex].fancylimits)
    newFormValues[rowIndex].fancylimits[index].marketlimits[key].is_active = newFormValues[rowIndex].fancylimits[index].marketlimits[key].is_active === 0 ? 1 : 0;
    setLeagueData(newFormValues);
    updateVenueSetting(val, index, 'venue')
  };

  const handleLeagueStatuses = async (fieldName, value, index, leagueID) => {
    let newFormValues = [...leagueData]
    newFormValues[index][fieldName] = value ? 1 : 0;
    setLeagueData(newFormValues)
    // return
    let title = "";
    switch (fieldName) {

      case "has_bookmaker":
        title = value ? "Bookmaker Enabled" : "Bookmaker Disabled";
        break;
      case "has_fancy":
        title = value ? "Fancy Enabled" : "Fancy Disabled";
        break;
      case "has_premium_fancy":
        title = value ? "Premium Enabled" : "Premium Disabled";
        break;
      case "is_active":
        title = value ? "All matches Enabled" : "All matches Disabled";
        break;

      default:
        break;
    }
    const res = await updateCaller(`api/v1/racing/country/${leagueID}`, { [fieldName]: value })
    if (res) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        background: "black",
        color: "white",
        showConfirmButton: false,
        timer: 2000,
        width: 450,
        padding: ".5rem"
      })
      Toast.fire({
        icon: 'success',
        title: `${title}`,
        background: 'white',
        color: 'green'
      })
    }
  }
  
  return (
    <div className="">
      {
        leagueData?.length > 0 ? leagueData?.map((el, i) => (
          <motion.div className="league-setting-container" key={i} initial={{ opacity: 0, x: '-100vh' }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}>
            <div className="league-setting-body">
              <button
                className={`accordion ${open.includes(i) ? 'active' : 'inactive'}`}
                onClick={() => handleLeagueAccordian(i)}>
                {!open.includes(i) ? <><BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold" }} /></> : <><BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold" }} /></>} {el.code}
              </button>
              <div className={`league-setting-content `}>
                <div className='league-setting-btn'>  <p className='league-para'> Match Active </p> <ToogleButton defaultChecked={parseInt(el.is_active) === 0 ? false : true} onChange={(event) => handleLeagueStatuses("is_active", event, i, el.id)} /></div>
                <div className="delete-icon league_icon">
                  <MdDelete
                    style={{ fontSize: "1rem", color: "white" }}
                    onClick={() => handleDeleteLeague(el.id, i)}
                    tabIndex={0}
                    onKeyDown={
                      (e) => {
                        if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32) {
                          e.preventDefault(); handleDeleteLeague(el.id, i)
                        }
                      }
                    }
                  />
                </div>
              </div>
            </div>           
            <div className={`market-accordian-body`} style={{ display: open ? 'block' : 'none' }}>
              <table width="100%" >
                <tbody>
                  {open.includes(i) && (     
                    el.fancylimits.map((val, k) => (
                      Object.entries(val.marketlimits).map(([fieldName, keyIndex], j) => (
                      <tr className='league-setting-row' key={j}>
                        <td>{val.name }</td>
                        <td> 
                          <label htmlFor="stake_limit">Active</label> <br />
                          <ToogleButton 
                            defaultChecked={parseInt(keyIndex.is_active) === 1}
                            onChange={(event) => toggleActiveFancy(fieldName, i,k,j,val)}
                          />
                        </td>
                        <td>
                          <label htmlFor="stake_limit">Stake Limit</label> <br />
                            <input type='number' autoComplete="off" disabled={parseInt(keyIndex.is_active) === 0} value={keyIndex.stake_limit} onChange={(event) => handleChangeFancy('stake_limit', i, event.target.value, k, j,val)}
                            placeholder='stake limit'
                            className='league-detail-input'
                          />
                        </td>
                        <td>
                          <label htmlFor="stake_limit"> Odd Limit</label> <br />
                          <input type='number' autoComplete="off" disabled={parseInt(keyIndex.is_active) === 0} value={keyIndex.odd_limit} onChange={(event) => handleChangeFancy('odd_limit', i, event.target.value, k, j,val)}
                            placeholder='stake limit'
                            className='league-detail-input'
                          />
                        </td>
                        <td>
                          <label htmlFor="stake_limit">Inplay Stake Limit</label> <br />
                          <input type='number' autoComplete="off" disabled={parseInt(keyIndex.is_active) === 0} value={keyIndex.inplay_stake_limit} onChange={(event) => handleChangeFancy('inplay_stake_limit', i, event.target.value,k,j,val)}
                            placeholder='stake limit'
                            className='league-detail-input'
                          />
                        </td>
                        <td>
                          <label htmlFor="stake_limit">Max Market Limit</label> <br />
                          <input type='number' autoComplete="off" disabled={parseInt(keyIndex.is_active) === 0} value={keyIndex.max_market_limit} onChange={(event) => handleChangeFancy('max_market_limit', i, event.target.value, k, j,val)}
                            placeholder='stake limit'
                            className='league-detail-input'
                          />
                        </td>
                        <td>
                          <label htmlFor="stake_limit">Delay</label> <br />
                          <input type='number' autoComplete="off" disabled={parseInt(keyIndex.is_active) === 0} value={keyIndex.delay} onChange={(event) => handleChangeFancy('delay', i, event.target.value, k, j,val)}
                            placeholder='stake limit'
                            className='league-detail-input'
                          />
                        </td>
                          {/* <td>
                            <label htmlFor="stake_limit">Action</label> <br />
                            <button className='update-league-btn' type='button' disabled={parseInt(val.is_active) === 0} 
                              onClick={() => updateVenueSetting(val, k, 'venue')}> Update </button>
                          </td> */}
                      </tr>
                      ))
                    ))
                  )}
                </tbody>
              </table>
            </div> 
          </motion.div>
        )) : <ErrorBoundary />
      }
    </div>
  )
}
export default HorseRacingVanueLeagueSettings