import React, { useState } from 'react';
import ToogleButton from '../../component/toggleButton/ToogleButton';
import { postCaller, getCaller } from '../../services/api';
import { AiOutlineClose, AiOutlineCloseCircle } from 'react-icons/ai';
import { motion } from "framer-motion";
import '../user/user.css';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import Button from '../../component/button/Button';
import CONSTANTS from "../../utilities/constants";
let disableFlag = true;

const UserCreateForm = () => {
  const [ipAddress, setIpAddress] = useState('');
  const [ipAddresses, setIpAddresses] = useState([]);
  const [addressName, setAddressName] = useState('');
  const navigate = useNavigate();

  let userType = localStorage.getItem('user_role').toLowerCase();

  const [data, setData] = useState({
    user_id: {
      value: "",
      error: false
    },
    name: {
      value: "",
      error: false
    },
    password: {
      value: "",
      error: false
    },
    confirmPassword: {
      value: "",
      error: false
    },
    managerPassword: {
      value: "",
      error: false
    },
    show_bet: {
      value: 0,
      error: false
    },
    show_book: {
      value: 0,
      error: false
    },
    allow_declare_result: {
      value: 0,
      error: false
    },
    // is_locked: {
    //   value: 0,
    //   error: false
    // },
  });

  const ipAddressRegex = /^(?:\d{1,3}\.){3}\d{1,3}$|^(?:[a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}$/;
  
  const handleAddIp = () => {
    const trimmedIpAddress = ipAddress.trim();

    if (trimmedIpAddress && addressName) {
      if (!ipAddresses.some(item => item.ip === trimmedIpAddress)) {
        if (ipAddressRegex.test(trimmedIpAddress)) {
          const newIpEntry = { ip: trimmedIpAddress, name: addressName };
          const newIpAddresses = [...ipAddresses, newIpEntry];

          setIpAddresses(newIpAddresses);
          setData(prevData => ({
            ...prevData,
            ip_address_list: {
              value: newIpAddresses,
              error: false
            }
          }));
          setIpAddress('');
          setAddressName('');
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Invalid IP Address',
            text: 'Please enter a valid IP address format.',
            toast: true,
            position: 'top',
            background: 'red',
            color: 'white',
            showConfirmButton: false,
            timer: 2000,
            width: 400,
            padding: '.5rem'
          });
        }
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Duplicate IP Address',
          text: 'This IP address has already been added.',
          toast: true,
          position: 'top',
          background: 'red',
          color: 'white',
          showConfirmButton: false,
          timer: 2000,
          width: 400,
          padding: '.5rem'
        });
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Both fields are required',
        text: 'Please enter both IP address and address name.',
        toast: true,
        position: 'top',
        background: 'red',
        color: 'white',
        showConfirmButton: false,
        timer: 2000,
        width: 400,
        padding: '.5rem'
      });
    }
  };

  const handleRemoveIp = (index) => {
    setIpAddresses(ipAddresses.filter((_, i) => i !== index));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddIp();
    }
  };

  const validateRequired = (field, value) => {
    if (!value && value !== 0) {
      return CONSTANTS.AGENT_FIELDS[field] + " is required!"
    }
    return "";
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setData(prevData => ({
      ...prevData,
      [name]: { ...prevData[name], value }
    }));

    if(name === "user_id"){
      checkUsernameAvailability(value);
    }

    validateAndSetField(name, value);
  };

  const checkButtonState = () => {
    let flag = true;
    for (const field in data) {
      
      if (data[field].value.toString() !== "" || field === "ip_address_list") {
        flag = false;
      } else {        
        flag = true;
        break;
      }
    }

    return flag || disableFlag;
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    if(name === "user_id"){
      checkUsernameAvailability(value);
    }
    
    // validateAndSetField(name, value);
  };

  const validateAndSetField = (field, value) => {
    setData(prevData => {
      const newData = { ...prevData };
      newData[field] = { ...newData[field], value };

      // Validation
      const error = validateRequired(field, value);
      if (field === 'user_id') {
        const regex = /\s/g;  
        if (regex.test(value)) {
          newData[field].error = "Whitespace is not allowed.";
        } else {
          newData[field].error = error;
        }
      } else if (field === 'confirmPassword' && value !== newData.password.value) {
        newData[field].error = "Confirm password does not match.";
        disableFlag = true;
      } else if (field === 'password') {
        if(value !== newData.confirmPassword.value){
          newData['confirmPassword'].error = "Confirm password does not match.";
          disableFlag = true;
        } else {
          newData['confirmPassword'].error = "";
          disableFlag = false;
        }
      } else {
        newData[field].error = error;
        disableFlag = false;
      }

      return newData;
    });
  };

  const checkUsernameAvailability = async (username) => {
    try {
      if (!username) { return;}

      let error = "";
      if(/\s/g.test(username)){
        return
      }
      const res = await getCaller(`api/v1/users/checkAvailability/${username}`);

      if (res.data.available !== true) {
        error = "User ID already exists";
        disableFlag = true;
      } else {
        error = "";
        disableFlag = false;
      }

      setData(prevData => ({
        ...prevData,
        user_id: {
          ...prevData.user_id,
          error: error
        }
      }));
    } catch (error) {
        console.error(error);
    }
  };

  const submitForm = async (event) => {
    try {
      event.preventDefault()
      let hasError = false;
      const values = {};
      for (const field in data) {
        if (validateAndSetField(field, data[field].value)) {
          hasError = true;
        }
        values[field] = data[field].value;
      }
      if (hasError) { return; }

      delete values.confirmPassword
      values.user_role = userType === 'admin' ? 'MANAGER' : 'AGENT';

      Swal.fire({
        title: 'Are you sure?',
        text: `You Want to Create Agent`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        allowOutsideClick: () => Swal.isLoading()
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await postCaller(`api/v1/users/addAgents`, values)
          if (res.success === true) {
            Swal.fire({
              title: 'Submitted',
              text: res.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1000,
              background: 'white',
              color: 'green'
            }
            ).then(async function () {
              navigate('/user')
            })
          }
          else {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              background: "red",
              color: "white",
              showConfirmButton: false,
              timer: 2000,
              width: 400,
              padding: ".5rem"
            })

            Toast.fire({
              icon: 'warning',
              iconColor: "white",
              title: res.message
            })
          }
        }
      })

    } catch (error) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        background: "red",
        color: "white",
        showConfirmButton: false,
        timer: 2000,
        width: 400,
        padding: ".5rem"
      })

      Toast.fire({
        icon: 'warning',
        iconColor: "white",
        title: "Something went wrong!"
      })
    }
  };
  return (
    <>
      {/* <AgentDownline/> */}
      <div className="sport-container">
        <motion.div className="agent-modal" initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01]
          }}>
          <div className='add-agent-head'>
            {userType === 'manager' ? 'Add Agent' : 'Update Agent'}
            {/* <div> Add Agent</div> */}
            <div className="ai-close">
              <AiOutlineClose onClick={() => navigate('/user')} />
            </div>
          </div>

          <form onSubmit={submitForm}>
            <div className='agent-add-form'>
              <div className="agent-input-body">
                <label htmlFor="user_id"> USER ID <span style={{ color: 'red' }}>*</span> </label> <br />
                <input type="text" autoComplete='off' value={data.user_id.value} onChange={handleChange} onBlur={handleBlur} name='user_id' className='agent_input' /> <br />
                {data.user_id.error ? <span className="error" style={{ color: "red" }}> {data.user_id.error}</span> : null}
              </div>

              <div className="agent-input-body">
                <label htmlFor="name"> NAME <span style={{ color: 'red' }}>*</span> </label> <br />
                <input type="text" autoComplete="off" name='name' value={data.name.value} onChange={handleChange} onBlur={handleBlur} className='agent_input' />
                {data.name.error ? <span className="error" style={{ color: "red" }}>{data.name.error}</span> : null}
              </div>

              <div className="agent-input-body ip-address-form">
              <div className='multiSelect-label-wrap'>
                <label htmlFor="addressName">IP Address Name</label>
                <label htmlFor="ipAddress">IP Address</label>
              </div>
              <div className="input-container">
                <div className="multiSelect-wrap">
                  <div className='multiSelect-inp-wrap'>
                    <input type="text" autoComplete="off" name="addressName" value={addressName} onChange={(e) => setAddressName(e.target.value)} onKeyDown={handleKeyDown} className="agent_input" placeholder="Enter Address Name"/>
                    <span className='multiSelect-divide'></span>
                    <input type="text" autoComplete = "off" name='ipAddress' value={ipAddress} onChange={(e) => setIpAddress(e.target.value)} onKeyDown={handleKeyDown} className="agent_input" placeholder="Enter IP Address"/>
                  </div>
                  {ipAddresses.length > 0 && (
                    <div className="selected-ip-container">
                      {ipAddresses.map((ipEntry, index) => (
                        <div key={index} className="ip-item">
                          {ipEntry.name} ({ipEntry.ip})
                          <AiOutlineCloseCircle className="remove-ip-icon" onClick={() => handleRemoveIp(index)} />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <button type="button" onClick={handleAddIp} className="add-ip-btn">+ Add IP</button>
              </div>
            </div>

              <div className="agent-input-body">
                <label htmlFor="date"> PASSWORD <span style={{ color: 'red' }}>*</span> </label> <br />
                <input type="password" autoComplete="new-password" name='password' value={data.password.value} onChange={handleChange} onBlur={handleBlur} className='agent_input' />
                {data.password.error ? <span className="error" style={{ color: "red" }}>{data.password.error}</span> : null}
              </div>

              <div className="agent-input-body">
                <label htmlFor="password"> CONFIRM PASSWORD <span style={{ color: 'red' }}>*</span> </label> <br />
                <input type="password" autoComplete="off" name='confirmPassword' value={data.confirmPassword.value} onChange={handleChange} onBlur={handleBlur} className='agent_input' />
                {data.confirmPassword.error ? <span className="error" style={{ color: "red" }}>{data.confirmPassword.error}</span> : null}
              </div>

              <div className='agent-permission-container'>
                <p className='agent-permission' > PERMISSION </p>
                <div className='agent-declare-result'>
                  <div className='declare-result'>
                    <div className='r-body'> DECLARED RESULTS </div>
                    <div>
                      <ToogleButton defaultChecked={data.allow_declare_result.value ? true : false} onChange={(value) => validateAndSetField('allow_declare_result', value ? 1 : 0)} />
                    </div>
                  </div>
                  <div className='declare-result'>
                    <div className='r-body'> SHOW BETS </div>
                    <div>
                      <ToogleButton defaultChecked={data.show_bet.value ? true : false} onChange={(value) => validateAndSetField('show_bet', value ? 1 : 0)} />
                    </div>
                  </div>
                  <div className='declare-result'>
                    <div className='r-body'> SHOW BOOKS </div>
                    <div>
                      <ToogleButton defaultChecked={data.show_book.value ? true : false} onChange={(value) => validateAndSetField('show_book', value ? 1 : 0)} />
                    </div>
                  </div>
                  {/* <div className='declare-result'>
                    <div className='r-body'> LOCK </div>
                    <div>
                      <ToogleButton defaultChecked={data.is_locked.value ? true : false} onChange={(value) => validateAndSetField('is_locked', value ? 1 : 0)} />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className='agent-password-container'>
              <input type="password" autoComplete="off" name='managerPassword' value={data.managerPassword.value} onChange={handleChange} onBlur={handleBlur} className="password-input" placeholder='Password' /> <br />
              <div >
                <Button disabled={data.user_id.error ? true : checkButtonState()} name={'Submit'} type={'submit'} className={'submit'} />
              </div>
            </div>
            {data.managerPassword.error ? <span className="error" style={{ color: "red", marginLeft: "1rem" }}>
              {data.managerPassword.error}
            </span> : null}
          </form>
        </motion.div >
      </div >
    </>
  )
};

export default UserCreateForm;

