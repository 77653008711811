import React, { useEffect, useState } from 'react';
import { getCaller, postCaller, updateCaller } from '../../services/api';
import { HiMinusCircle } from 'react-icons/hi';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { AiOutlineClose } from 'react-icons/ai';
import '../customGames/custom.css';
import { useFormik } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '../../component/button/Button';
import moment from 'moment-timezone';
import Select from 'react-select';
import Swal from "sweetalert2";

const CustomAddMatch = () => {
    const [sportList, setSportList] = useState([]);
    const [dateError, setDateError] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [leagueList, setLeagueList] = useState([]);
    const { state } = useLocation();
    const navigate = useNavigate();
    const updateMatchData = state?.matchData ?? {};
    const isAddMode = !updateMatchData.id;
    
    const handleDateChange = (date) => {
        setSelectedDate(date);
        setDateError("");
    };

    useEffect(() => {

        async function getSport() {
            let sportResponse = await getCaller('api/v1/sports/management/getSport');
            setSportList(sportResponse?.data)
        }
        if (isAddMode) {
            getSport()
        }

    }, [isAddMode]);

    useEffect(() => {
        if (!isAddMode && updateMatchData) {
            formik.setFieldValue('event_name', updateMatchData.event_name || "", false);
            
            setSelectedDate(updateMatchData.event_date ? new Date(updateMatchData.event_date) : null);
    
            const runners = Array.isArray(updateMatchData.selection_runners)
                ? updateMatchData.selection_runners.map(runner => ({ selection_runners: runner.RN }))
                : JSON.parse(updateMatchData.selection_runners || '[]').map(runner => ({ selection_runners: runner.RN }));
    
            formik.setFieldValue('runners', runners, false);
        }
    }, [isAddMode, updateMatchData]);

    const validationMatch = Yup.object().shape({
        event_name: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Match Name is Required'),
        ...isAddMode ? {
            event_id: Yup.number()
                .min(-999999999999999, 'Too Short!')
                .max(999999999999999, 'Too Long!')
                .required('Match Id is Required')
        } : {},
        ...isAddMode ? {
            sport_id: Yup.number()
                .required('Sport is Required')
        } : {},
        ...isAddMode ? {
            league_id: Yup.number()
                .required('League is Required')
        } : {},
        ...isAddMode ? {
            runners: Yup.array().of(
                Yup.object().shape({
                    selection_runners: Yup.string()
                        .matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed')
                        .min(2, 'Too Short!')
                        .max(50, 'Too Long!')
                        .required('Runner Name is Required'),
                })
            ).min(1).required('Runner is Required')
        } : {},
    });

    const formik = useFormik({
        initialValues: {
            ...isAddMode ? { sport_id: "" } : {},
            event_name: updateMatchData.event_name || "",
            ...isAddMode ? { event_id: Date.now() } : {},
            ...isAddMode ? { event_date: "" } : {},
            runners: Array.isArray(updateMatchData.selection_runners) ? updateMatchData.selection_runners.map(runner => ({
                selection_runners: runner.RN
            }))
            : [{ selection_runners: "" }],
            ...isAddMode ? { league_id: "" } : {},
        },
        validationSchema: validationMatch,
        onSubmit: async (values, { resetForm }) => {
            values = { ...values };
             
            if (isAddMode) {
                values.runners = values.runners.map(e => (e.selection_runners))

                if (!selectedDate) { setDateError("Date not Provided"); return; }
                setDateError("");   
                                
                // values.event_date = moment(selectedDate, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').format('YYYY-MM-DD HH:mm:ss');
                const formattedDate = moment(new Date(selectedDate).toISOString());
                values.event_date = moment(formattedDate).utc().format('YYYY-MM-DD HH:mm:ss');

                Swal.fire({
                    title: 'Are you sure?',
                    text: `you want to add this match (${values.event_name})`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    allowOutsideClick: () => Swal.isLoading()
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const res = await postCaller(`api/v1/events/addMatch`, values)
                        if (res.success === true) {
                            navigate('/custom_games/custom_matches');
                            Swal.fire({
                                title: 'Submitted',
                                text: 'Your Match added Successfully',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1000,
                                background: 'white',
                                color: 'green'
                            })
                        }
                    }
                })
            }
            else {
                // values.event_date = moment(selectedDate, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').format('YYYY-MM-DD HH:mm:ss');
                const formattedDate = moment(new Date(selectedDate).toISOString());
                values.event_date = moment(formattedDate).utc().format('YYYY-MM-DD HH:mm:ss');
                
                values.runners = values.runners.map(e => (e.selection_runners));
                Swal.fire({
                    title: 'Are you sure?',
                    text: `You want to update this match (${values.event_name})?`,
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    allowOutsideClick: () => Swal.isLoading()
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const res = await updateCaller(`api/v1/events/updateMatch?id=${updateMatchData.id}`, values)
                        if (res.success === true) {
                            navigate('/custom_games/custom_matches');
                            Swal.fire({
                                title: 'Submitted',
                                text: 'Your Match Updated Successfully',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1000,
                                background: 'white',
                                color: 'green'
                            })
                        }
                    }
                })
            }
        },
    });


    const addInputField = () => {
        formik.setFieldValue('runners', [...formik.values.runners, { selection_runners: "" }], false)
    };

    const removeInputFields = (i) => {
        formik.values.runners.splice(i, 1);
        formik.setFieldValue('runners', [...formik.values.runners])
    };


    useEffect(() => {
        const handleSport = async (sport) => {
            if (sport === "") {
                setLeagueList([]);
                return;
            }
            if (isAddMode) {
                try {
                    let leagueResponse = await getCaller(`api/v1/leagues/leaguesFindById?id=${sport}`);
                    setLeagueList(leagueResponse.data);
                } catch (err) {
                    console.error(err);
                }
            }
        };

        handleSport(formik.values.sport_id)
            .then()
            .catch(err => console.error(err));
    }, [formik.values.sport_id, isAddMode]);


    return (
            <div className="sport-container">
                <div className="agent-modal">
                    <div className='add-agent-head'>
                        <div> {isAddMode ? "Add Match" : "Update Match"} </div>
                        <div className="ai-close">
                            <AiOutlineClose onClick={() => navigate('/custom_games/custom_matches')}/>
                        </div>
                    </div>

                    <form onSubmit={formik.handleSubmit}>
                        <div className="add-sport-content">
                            {
                                isAddMode ? (
                                    <>
                                        <div className="add-sport-input" style={{ marginTop: '-7px' }}>
                                            <label htmlFor="sport"> Sport <span style={{ color: 'red' }}>*</span> </label> <br />
                                            <Select
                                                name="sport_id"
                                                onChange={(selectedOption) => formik.setFieldValue('sport_id', selectedOption?.value)}
                                                options={sportList.map((el) => ({
                                                    value: el.id,
                                                    label: el.name
                                                }))}
                                                placeholder="Select Sport"
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        outline: "none",
                                                        height: "2.5rem",
                                                        marginTop: "0.2rem",
                                                        border: "1px solid #cbcbcb",
                                                        borderRadius: "0.25rem",
                                                        width: "100%",
                                                    }),
                                                    menu: (provided, state) => ({
                                                        ...provided,
                                                        zIndex: 20,
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        marginTop: "-7px"
                                                    })
                                                }}
                                            />
                                            {formik.errors.sport_id && formik.touched.sport_id && <span className="error" style={{ color: "red" }}>
                                                {formik.errors.sport_id}
                                            </span>}
                                        </div>
                                        
                                        <div className="add-sport-input" style={{ marginTop: '15px' }}>
                                            <label htmlFor="sport"> League <span style={{ color: 'red' }}>*</span> </label> <br />
                                            <Select
                                                onChange={(selectedOption) => formik.setFieldValue('league_id', selectedOption?.value)}
                                                options={leagueList.map((el) => ({ value: el.id, label: el.name }))}
                                                placeholder="Select League"
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        outline: "none",
                                                        height: "2.5rem",
                                                        marginTop: "0.2rem",
                                                        border: "1px solid #cbcbcb",
                                                        borderRadius: "0.25rem",
                                                        width: "100%",
                                                    }),
                                                    menu: (provided, state) => ({
                                                        ...provided,
                                                        zIndex: 20,
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        marginTop: "-7px"
                                                    })
                                                }}
                                            />
                                            {formik.errors.league_id && formik.touched.league_id && <span className="error" style={{ color: "red" }}>
                                                {formik.errors.league_id}
                                            </span>}
                                        </div>

                                        <div className="add-sport-input" style={{ marginTop: '15px' }}>
                                            <label htmlFor="sport"> Match Id <span style={{ color: 'red' }}>*</span> </label> <br />
                                            <input type="text" autoComplete="off" onBlur={formik.handleBlur} name="event_id" value={formik.values.event_id}  placeholder='Match Id' onChange={formik.handleChange} />
                                            {formik.errors.event_id && formik.touched.event_id && <span className="error" style={{ color: "red" }}>
                                                {formik.errors.event_id}
                                            </span>}
                                        </div>
                                    </>
                                ) : null
                            }
                            
                            <div className="add-sport-input" style={{ marginTop: isAddMode ? '15px' : '-7px' }}>
                                <label htmlFor="sport"> Match Name <span style={{ color: 'red' }}>*</span> </label> <br />
                                <input type="text" autoComplete="off" onBlur={formik.handleBlur} name="event_name" value={formik.values.event_name} placeholder='Match Name' onChange={formik.handleChange} />
                                {formik.errors.event_name && formik.touched.event_name && <span className="error" style={{ color: "red" }}>
                                    {formik.errors.event_name}
                                </span>}
                            </div>
                
                            <div className="add-sport-input" style={{ marginTop: '15px' }}>
                                <label htmlFor="sport"> Event Date and Time <span style={{ color: 'red' }}>*</span> </label> <br />
                                <div className="date-picker-wrapper">
                                    <DatePicker
                                        name="event_date"
                                        selected={selectedDate}
                                        onChange={handleDateChange}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        placeholderText='Date & Time'
                                        timeIntervals={15}
                                        clearButtonClassName='s'
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        onKeyDown={(e) => {
                                             e.preventDefault();
                                        }}
                                    />
                                    {dateError && <span className="error" style={{ color: "red" }}>
                                        {dateError}
                                    </span>}
                                </div>
                            </div>

                            {
                                formik.values.runners.map((data, index) => {
                                    return (
                                        <div className="add-sport-input" style={{ marginTop: '15px' }} key={index}>
                                            <label htmlFor="sport"> Runners <span style={{ color: 'red' }}>*</span> </label> <br />
                                            <input type="text" autoComplete="off" name={`runners.${index}.selection_runners`} onChange={ formik.handleChange } id="sport" value={formik.values.runners[index].selection_runners} placeholder='Runners' />
                                          
                                            {
                                                    formik.errors.runners?.[index]?.selection_runners && 
                                                    formik.touched.runners?.[index]?.selection_runners && (
                                                        <span className="error" style={{ color: "red" }}>
                                                            {formik.errors.runners[index].selection_runners}
                                                        </span>
                                                )}
                                        </div>
                                    )
                                })
                            }

                       
                            <div className="" style={{ marginTop: '15px' }}>
                                {formik.values.runners.length > 1 && (
                                    <button type='button' className='add-field' onClick={removeInputFields}>
                                        <HiMinusCircle /> Remove Field
                                    </button>
                                )}
                                <button type='button' className='add-field' style={{ marginTop: '10px'}} onClick={addInputField}>
                                    <BsPlusCircleFill /> Add Field
                                </button>
                            </div>

                        </div>

                        <div className="sport-btn-container">
                            <div>
                                <Button type='submit' className='submit' name="Submit" disabled={!(formik.isValid && formik.dirty)} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
    )
};

export default CustomAddMatch;