import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { postCaller } from '../../services/api';
import '../layout.css';
import { TbLogout } from 'react-icons/tb';
import getLocalData from '../../utilities/LocalStorageData.utilities';

const SideBar = ({ menuOpen, setMenuOpen }) => {
  const [scope, setScope] = useState({});

  useEffect(() => {
    const data = getLocalData('scope');
    const userType = getLocalData('user_role');
    setScope({ userType: userType, result: data?.result, event: data?.event });
  }, []);

  let token = getLocalData('token');
  const navigate = useNavigate();
  const location = useLocation();

  const logoutHandle = async () => {
    await postCaller(`api/v1/auth/logout?token=${token}`);
    localStorage.clear('token');
    navigate('/');
  }

  useEffect(() => {
    if (scope?.userType === 'agent' && location.pathname !== '/agent/manage_market') {
      navigate('/agent/manage_market');
      setMenuOpen(false);

    }
  }, [scope?.userType, location.pathname, navigate, setMenuOpen]);

  return (
    <>
      {
        scope?.userType === 'agent' ? <ul className={`side-top ${menuOpen ? 'active-menu-agent' : ""} `}>
        </ul> :
          <div className={`side-left ${menuOpen ? 'active-menu' : ""} `}>
            <ul>
              {/* <li className='tablink'> <Link to="#" className='quick-link'> <FaAngleRight style={{ color: "red" }} />  Quick Links </Link> </li> */}
              {scope?.userType === 'admin' ? <li className={`tablink ${location.pathname.includes('user') ? 'active-side' : null}`} onClick={() => { navigate('/user'); setMenuOpen(false) }}>
                Manager  </li> : null}

              {scope?.userType === 'manager' ? <li className={`tablink ${location.pathname.includes('user') ? 'active-side' : null}`} onClick={() => { navigate('/user'); setMenuOpen(false) }}>
                Agents  </li> : null}

              {scope?.userType === 'agent' || scope?.userType === 'admin' ? null : <li className={`tablink ${location.pathname.includes('downline') ? 'active-side' : null}`} onClick={() => { navigate('/downline'); setMenuOpen(false) }}>
                Downline  </li>}

              {scope?.userType === 'admin' ? <li className={`tablink ${location.pathname.includes('clients') ? 'active-side' : null}`} onClick={() => { navigate('/clients'); setMenuOpen(false) }}>
                Clients  </li> : null}

              {scope?.userType === 'admin' ? <li className={`tablink ${location.pathname === '/league_setting' ? 'active-side' : null}`} onClick={() => { navigate('/league_setting'); setMenuOpen(false) }}>
                League Settings  </li> : null}

              {<li className={`tablink ${location.pathname === '/manage_sky_fancy' ? 'active-side' : null}`} onClick={() => { navigate('/manage_sky_fancy'); setMenuOpen(false) }}>
                Manage 3rd Party Fancy  </li>}

              {<li className={`tablink ${location.pathname === '/manage_sky_bookmaker' ? 'active-side' : null}`} onClick={() => { navigate('/manage_sky_bookmaker'); setMenuOpen(false) }}>
                Manage 3rd Party Bookmaker  </li>}

              {scope?.userType === 'admin' ? <li className={`tablink ${location.pathname === '/manage_toss_bookmaker' ? 'active-side' : null}`} onClick={() => { navigate('/manage_toss_bookmaker'); setMenuOpen(false) }}>
                Manage Toss Bookmaker  </li> : null}

              {((scope?.event?.read && scope?.userType === 'manager') || scope?.userType === 'admin') && <li className={`tablink ${location.pathname === '/eventmanagement' ? 'active-side' : null}`} onClick={() => { navigate('/eventmanagement'); setMenuOpen(false) }}>
                Event Management  </li>}

              {((scope?.event?.add && scope?.userType === 'manager') || scope?.userType === 'admin') && <li className={`tablink ${location.pathname === '/addevent' ? 'active-side' : null}`} onClick={() => { navigate('/addevent'); setMenuOpen(false) }}>
                Add Event  </li>}

              {((scope?.result?.read && scope?.userType === 'manager') || scope?.userType === 'admin') && <li className={`tablink ${location.pathname.includes('/results') ? 'active-side' : null}`} onClick={() => { navigate('/results/match_result'); setMenuOpen(false) }}>
                Results  </li>}

              {scope?.userType === 'admin' ? <li className={`tablink ${location.pathname.includes('/bets') ? 'active-side' : null}`} onClick={() => { navigate('/bets'); setMenuOpen(false) }}>
                Bets  </li> : null}

              {scope?.userType === 'admin' ? <li className={`tablink ${location.pathname.includes('/custom') ? 'active-side' : null}`} onClick={() => { navigate('/custom_games/custom_sport'); setMenuOpen(false) }}>
                Custom Games  </li> : null}

              {scope?.userType === 'admin' ? <li className={`tablink ${location.pathname.includes('/setting') ? 'active-side' : null}`} onClick={() => { navigate('/setting/market_settings' || '/setting/AddMarketSetting'); setMenuOpen(false) }}>
                Settings  </li> : null}

              {scope?.userType === 'admin' ? <li className={`tablink ${location.pathname == '/manage/worli-matka' ? 'active-side' : null}`} onClick={() => { navigate('/manage/worli-matka'); setMenuOpen(false) }}>
                Manage Worli Matka  </li> : null}

              {/* {userType === 'admin' ? <li className = {`tablink ${location.pathname.includes('/worli-matka-result') ? 'active-side' : null}`} onClick = {() => {navigate('/worli-matka-result/match_result'); setMenuOpen(false)}}>
                Worli Matka Result  </li>:null} */}

              {/* {  userType==='admin' ?<li className={`tablink ${location.pathname=='/worli-matka-bets' ? 'active-side' : null}`} onClick={() =>{ navigate('/worli-matka-bets');setMenuOpen(false)}}>
                Worli Matka Bets  </li>:null} */}

              {scope?.userType === 'admin' ? <li className={`tablink ${location.pathname === '/addracingevent' ? 'active-side' : null}`} onClick={() => { navigate('/addracingevent'); setMenuOpen(false) }}>
                Add Racing Event  </li> : null}

              {scope?.userType === 'admin' ? <li className={`tablink ${location.pathname === '/league_setting/racingcountry' ? 'active-side' : null}`} onClick={() => { navigate('/league_setting/racingcountry'); setMenuOpen(false) }}>
                Racing Country Settings  </li> : null}

              {scope?.userType === 'admin' ? <li className={`tablink ${location.pathname === '/league_setting/racingvenue' ? 'active-side' : null}`} onClick={() => { navigate('/league_setting/racingvenue'); setMenuOpen(false) }}>
                Racing Venue Settings  </li> : null}

              {scope?.userType === 'admin' ? <li className={`tablink ${location.pathname === '/eventmanagement/racing' ? 'active-side' : null}`} onClick={() => { navigate('/eventmanagement/racing'); setMenuOpen(false) }}>
                Racing Event Management  </li> : null}

              {/* {  userType==='admin' ?<li className={`tablink ${location.pathname=='/result/racing' ? 'active-side' : null}`} onClick={() =>{ navigate('/result/racing');setMenuOpen(false)}}>
                Racing Results  </li>:null} */}

              <li className={`tablink ${location.pathname === '/' ? 'active-side' : null}`} onClick={logoutHandle}>
                <TbLogout style={{ fontSize: "1.5rem" }} /> Log Out
              </li>
            </ul>
          </div>
      }
    </>
  )
};

export default SideBar;