import React, {  useState, useRef} from 'react'
import { useFormik } from 'formik';
import '../user/user.css'
import { motion } from "framer-motion";
import { AiOutlineClose } from 'react-icons/ai'
import {  postCaller, updateCaller,} from '../../services/api';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../component/button/Button';
import { BsUpload } from 'react-icons/bs';
import * as Yup from "yup";

const EditClient = () => {
  const {state} = useLocation()
  const sportData = state?.data??{}
  const newSportData= state?.newData??{}
  const [newData,setNewData] = useState(newSportData)
  const [sportBannerImage,setSportBannerImage] =useState("http://3.110.13.16:9904/api/v1/aws-s3/download/"+newData.banner_image || '')
  const [iconUrl,setIconUrl] =useState("http://3.110.13.16:9904/api/v1/aws-s3/download/"+newData.sport_icon || ''); 
  const navigate = useNavigate()
  const fileInputRefs = useRef(null);
  const fileInputRef = useRef(null);

  const uploadSportIcon= async(file, type)=>{
    if (!file) {
      return;
    }
    try {
      const signedUrlResponse = await postCaller("api/v1/aws-s3/signedUrl", {
        file_name: file.name,
        file_type: file.type,
        file_size: file.size,
      }); 

      const signedUrl = signedUrlResponse.data.url;

      await fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      });
  

      const updatedNewData = { ...newData, ...type === 'sport_icon'?{sport_icon: signedUrlResponse.data.fileName}: {banner_image: signedUrlResponse.data.fileName} };
      if(type === 'sport_icon'){
        setIconUrl("http://3.110.13.16:9904/api/v1/aws-s3/download/"+updatedNewData.sport_icon)
      }else{
        setSportBannerImage("http://3.110.13.16:9904/api/v1/aws-s3/download/"+updatedNewData.banner_image);
      }
      
      
      setNewData(updatedNewData)
     
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  }



const handleFileChange = (event, type) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();

    reader.onload = (e) => {
      if(type === 'sport_icon'){
        setIconUrl(e.target.result)
      } else{
        setSportBannerImage(e.target.result);
      }
    };

    
    uploadSportIcon(file, type);

    reader.readAsDataURL(file);
  }
};

  const validationSchema = Yup.object({
    client_name: Yup.string().required('Client name is required')
  });

  // const isAddMode = !updateData.id
        const formik = useFormik({
            initialValues: {
                client_name:sportData.data.client_name || '',       
            },
            validationSchema: validationSchema,

            onSubmit: async (values) => {
                const client_name = values.client_name;
                const id = newData.id;

                const dataToSend = {
                  client_name,
                  metadata: sportData.data.metadata.map(metadataItem => {
                    if(metadataItem.id === id){
                      metadataItem.sport_icon = newData.sport_icon;
                      metadataItem.banner_image = newData.banner_image;
                    }
                    return metadataItem;
                  })
                };

              await updateCaller(`api/v1/clients/updateClient?client_id=${sportData.data.client_id}`,dataToSend)
              navigate('/clients')
            },
          });
  return (
    <>
    <div className="sport-container"> 
  <motion.div className="agent-modal"  initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.8,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01]
      }}>
  <div className='add-agent-head'>
                <div>
        Update Clients
                </div>
                <div  className="ai-close">
               <AiOutlineClose onClick={() => navigate('/clients') } 
                />
               </div>
            </div>
   <form onSubmit={formik.handleSubmit}>
    <div className='agent-add-form'>
                <div className="agent-input-body">
                    <label htmlFor="date"> Client Name <span style={{
                        color: 'red'
                    }}>*</span> </label> <br />
                    <input type="text" autoComplete='off'  name='client_name' onBlur={formik.handleBlur} value={formik.values.client_name} className='agent_input' onChange={formik.handleChange} /> <br />  
                    {formik.touched.client_name && formik.errors.client_name ? (
                      <div style={{ color: 'red' }}>{formik.errors.client_name}</div>
                    ) : null}
                    <br />
                </div>
                
                                { 
                              //  formik.values.metadata.map((metadataItem) => (

                                    <div className=""  >
                                      <div className="select-client">
                                    <label htmlFor="date"> Sport Icon </label> <br />
  
                                          <div className="image-preview">
                                            <img src={`${iconUrl}`} alt="sport Preview" />
                                          </div>
 
                                 <div className="client-input">
                            
                                  <input
                                              type="file"
                                              accept="image/*"
                                              onChange={(event) => handleFileChange(event, 'sport_icon')}
                                              style={{ display: 'none' }}
                                              ref={fileInputRefs} 
                                            />
                                 <div className="client-upload-btn"
                                              onClick={() => fileInputRefs.current.click()}  >
                                         <BsUpload
                                             style={{
                                                 fontSize: '1.5rem',
                                                 color: 'white'
                                             }}
                                         />
                                     </div>
                                </div>
                                </div>  
                                      
                                      <div>
                                        <div className="select-client">
                                          <label htmlFor="date">Banner Image</label> <br />
                                          <div>
                                          
                                          <div className="image-preview">
                                            <img src={sportBannerImage} alt="Banner Preview" />
                                          </div>
                                        

                                          </div>
                                         
                                          <div className="client-input">
                                            <input
                                              type="file"
                                              accept="image/*"
                                              onChange={(event) => handleFileChange(event, 'banner_image')}
                                              style={{ display: 'none' }}
                                              ref={fileInputRef} 
                                            />
                                            <div
                                              className="client-upload-btn"
                                              onClick={() => fileInputRef.current.click()}  
                                            >
                                              <BsUpload
                                                style={{
                                                  fontSize: '1.5rem',
                                                  color: 'white',
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                // ))
                                }
                                 
            <div className='agent-password-container'>
                <div >
                <Button name={'Submit'} type={'submit'} className={'submit'} />
                </div>
            </div>
            </div>
          
    </form>
  </motion.div>
   </div>
    </>
  )
}

export default EditClient