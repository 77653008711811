import React, { useState } from 'react'
import { MdDelete } from 'react-icons/md';
import ToogleButton from '../../component/toggleButton/ToogleButton';
import Swal from "sweetalert2";
import { motion } from "framer-motion";
import { getCaller, updateCaller,postCaller } from '../../services/api';
import ErrorBoundary from '../../error/ErrorBoundary';
import '../eventManage/leagueFromSport/leagueManage.css'
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import CONSTANTS from "../../utilities/constants";

const LeagueSettingsDetails = ({ leagueData, setLeagueData, leagueSetting, setLeagueSetting }) => {
  const [open, setOpen] = useState([]);
  const [openMain, setOpenMain] = useState([]);
  const [openFancy, setOpenFancy] = useState([]);
  const [openBookmaker, setOpenBookmaker] = useState([]);

  const toggleAccordian = (index, setOpenState) => {
    setOpenState((prevState) =>
      prevState.includes(index) ? prevState.filter((i) => i !== index) : [...prevState, index]
    );
  };

  const handleLeagueAccordian = (index) => {
    toggleAccordian(index, setOpen);
  };

  const handleMarketAccordian = (index) => {
    toggleAccordian(index, setOpenMain);
  };

  const handleFancyAccordian = (index) => {
    toggleAccordian(index, setOpenFancy);
  };

  const handleBookmakerAccordian = (index) => {
    toggleAccordian(index, setOpenBookmaker)
  };

  const handleLeagueStatus = async (fieldName, index, value, id) => {
    let newVal = JSON.parse(JSON.stringify(leagueData));
    newVal[index][fieldName] = value;
    setLeagueData(newVal);
    const res = await updateCaller(`api/v1/leagues/leaguesActive?sport_id=${id.sport_id}&status=${value === true ? 1 : 0}&id=${id.id}`)
    if (res.success === true) {
      const Toast = Swal.mixin({
        toast: true,
        // position: 'top',
        background: "black",
        color: "white",
        showConfirmButton: false,
        timer: 2000,
        width: 450,
        padding: ".5rem"
      })
      Toast.fire({
        icon: 'success',
        iconColor: "white",
        title: "League Setting Change successfully"
      }).then(async function () {
        const res = await getCaller(`api/v1/leagues/leaguesFindById?id=${id.sport_id}`)
        setLeagueData(res.data);

      })
    }


  }
  async function handleOnDemandLeague(id, sport_id, i){
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to add matches?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await postCaller(`api/v1/leagues/onDemandLeagues?id=${id}&sport_id=${sport_id}`)
        if (response.success === true) {
          Swal.fire({
            title: 'Submitted',
            text: 'Matches added Successfully',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000,
            background: 'white',
            color: 'green'
          })
        } else {
          Swal.fire({
            title: 'Error',
            text: response.message,
            icon: 'warning',
            showConfirmButton: true,
            timer: 5000
          })
        }
      }
    })
  }


  async function handleDeleteLeague(id, sport_id, i) {
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to delete this league?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await updateCaller(`api/v1/leagues/deleteLeagues?id=${id}&sport_id=${sport_id}`)
        if (response.success === true) {
          Swal.fire({
            title: 'Submitted',
            text: 'Your League is deleted Successfully',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000,
            background: 'white',
            color: 'green'
          }).then(function () {
            const newList = [...leagueData];
            newList.splice(i, 1);
            setLeagueData(newList)
          })
        } else {
          Swal.fire({
            title: 'Error',
            text: response.message,
            icon: 'warning',
            showConfirmButton: true,
            timer: 5000
          })
        }
      }
    })

  }

  const toasterMessage = (title) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'center',
      background: "black",
      color: "white",
      showConfirmButton: false,
      timer: 2000,
      width: 450,
      padding: ".5rem"
    })

    Toast.fire({
      icon: 'success',
      title: `${title}`,
      background: 'white',
      color: 'green'
    })
  }

  const handleChange = (fieldName, key, index, value) => {
    if(value < 0) value = 0;
    let newFormValues = [...leagueData]
    newFormValues[index].market_limits[key][fieldName] = value;
    setLeagueData(newFormValues)
  }

  const handleKeyDown = (e) => {
    const invalidKeys = ['+', '-', 'e', '`', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '=', '{', '}', '[', ']', '\\', '|', ':', ';', '"', "'", '<', '>', '?', '/', '~'];
    if (invalidKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleChangeFancy = (fieldName, key, index, value) => {
    if(value < 0) value = 0;
    let newFormValues = [...leagueData]
    newFormValues[index].fancy_limits[key][fieldName] = value;
    setLeagueData(newFormValues)

    if(fieldName === 'is_com'){
      let title = value ? "Commission Enabled" : "Commission Disabled";
      toasterMessage(title);
    }
  }

  const handleChangeBookmaker = (key, index, value) => {
    if(value < 0) value = 0;
    let newFormValues = [...leagueData]
    newFormValues[index].bookmaker_limits[key] = value;
    setLeagueData(newFormValues)

    if(key === 'is_com'){
      let title = value ? "Commission Enabled" : "Commission Disabled"; 
      toasterMessage(title);
    }
  }

  const updateLeagueSettings = async (id, i, limitType) => {
    const limitObject = 
      limitType === 'market_limits' ? leagueData[i].market_limits :
      limitType === 'fancy_limits' ? leagueData[i].fancy_limits :
      limitType === 'bookmaker_limits' ? leagueData[i].bookmaker_limits : {};
  
    const jsonObject = JSON.parse(JSON.stringify(limitObject));
  
    const hasEmptyFields = Object.entries(jsonObject).some(([key, values]) =>
      limitType === 'market_limits' || limitType === 'fancy_limits'
        ? Object.values(values).some(value => value === '')
        : values === ''
    );
  
    if (hasEmptyFields) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'All fields are mandatory.',
        toast: true,
        showConfirmButton: false,
        timer: 2000,
        background: 'white',
        color: 'red'
      });
      return;
    }
  
    try {
      const response = await updateCaller(`api/v1/leagues/leaguesSetting?id=${id.id}&sport_id=${id.sport_id}`, { [limitType]: jsonObject });
      
      const prefix = limitType === 'market_limits' ? 'Main Market' : limitType === 'fancy_limits' ? 'Fancy Market' : limitType === 'bookmaker_limits' ? 'Bookmaker Market' : '';

      if (response) {
        Swal.fire({
          icon: 'success',
          title: `${prefix} Updated Successfully`,
          toast: true,
          showConfirmButton: false,
          timer: 2000,
          background: 'white',
          color: 'green'
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to update league settings.',
        toast: true,
        showConfirmButton: false,
        timer: 2000,
        background: 'white',
        color: 'red'
      });
    }
  };  

  const toggleActive = async (key, index, value) => {
    let newFormValues = [...leagueData];
    newFormValues[index].market_limits[key]['is_active'] = newFormValues[index].market_limits[key]['is_active'] === 0 ? 1 : 0;
    setLeagueData(newFormValues);

    let keyValue = key.replace(/\b\w/g, char => char.toUpperCase());
    let title = `${keyValue}${value ? " Enabled" : " Disabled"}`
    toasterMessage(title);
  };


  const handleLeagueStatuses = async (fieldName, value, index,  leagueID) => {
    let newFormValues = [...leagueData]
    newFormValues[index][fieldName] = value? 1: 0;
    setLeagueData(newFormValues)
    
    const res = await updateCaller(`api/v1/leagues/${leagueID}`, {[fieldName]: value})
    
    if (res) {
      let title = `${CONSTANTS.LEAGUE_STATUS[fieldName]}${value ? " Enabled" : " Disabled"}`
      toasterMessage(title);
    }
  }

  return (
    <div className="league-setting-wrapper">
      {
        leagueData?.length > 0 ? leagueData?.map((el, i) => (
          <motion.div className="league-setting-container" key={i} initial={{ opacity: 0, x: '-100vh' }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}>
            <div className="league-setting-body">
              <button
                className={`accordion ${open.includes(i) ? 'active' : 'inactive'}`}
                onClick={() => handleLeagueAccordian(i)}>
                {!open.includes(i) ? <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold" }} /> : <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold" }} />} {el.name}
              </button>
              <div className={`league-setting-content `}>

                <div className='league-setting-btn'>
                  <p className="league-para"> B </p>
                  <ToogleButton defaultChecked={parseInt(el.has_bookmaker)} onChange={(event) => handleLeagueStatuses("has_bookmaker", event, i, el.id)} />
                </div>
                <div className='league-setting-btn'>
                  <p className="league-para"> F </p>
                  <ToogleButton defaultChecked={parseInt(el.has_fancy)} onChange={(event) => handleLeagueStatuses("has_fancy", event, i, el.id)} />
                </div>
                <div className='league-setting-btn'>
                  <p className="league-para"> P </p>
                  <ToogleButton defaultChecked={parseInt(el.has_premium_fancy)} onChange={(event) => handleLeagueStatuses("has_premium_fancy", event, i, el.id)} />
                </div>

                <div className='league-setting-btn'>  <p className='league-para'> League Active </p> <ToogleButton defaultChecked={parseInt(el.is_active)} onChange={(event) => handleLeagueStatus("is_active", i, event, el)} /></div>
                
                <div className='league-setting-btn'>  <p className='league-para'> Match Active </p> <ToogleButton defaultChecked={parseInt(el.match_is_active)} onChange={(event) => handleLeagueStatuses("match_is_active", event, i, el.id)} /></div>

                <div className='league-setting-btn'>  <p className='league-para'> League Inplay </p> <ToogleButton defaultChecked={parseInt(el.is_inplay)} onChange={(event) => handleLeagueStatuses("is_inplay", event, i, el.id)} /></div>

                <div className='league-setting-btn'>  <p className='league-para'> Volume Check </p> <ToogleButton defaultChecked={parseInt(el.is_odds_check)} onChange={(event) => handleLeagueStatuses("is_odds_check", event, i, el.id)} /></div>

                <div className='league-setting-btn'>  <p className='league-para'> Auto Add Matches </p> <ToogleButton defaultChecked={parseInt(el.auto_add_match)} onChange={(event) => handleLeagueStatuses("auto_add_match", event, i, el.id)} /></div>
                
                <div className='admin-pass-btn'> <button className="button" onClick={() => handleOnDemandLeague(el.id, el.sport_id, i)} >Add Matches</button></div>
                
                <button className="delete-icon">
                  <MdDelete
                    style={{ fontSize: "1rem", color: "white" }}
                    onClick={() => handleDeleteLeague(el.id, el.sport_id, i)}
                    tabIndex={0}
                    onKeyDown={
                      (e) => {
                        if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32) {
                          e.preventDefault(); handleDeleteLeague(el.id, el.sport_id, i)
                        }
                      }
                    }
                  />
                </button>
              </div>
            </div>
            {open.includes(i) && (
              <>
                <div className="market-accordian-body">
                  <button
                    className={`accordion ${openMain.includes(i) ? 'active' : 'inactive'}`}
                    onClick={() => handleMarketAccordian(i)}
                  >
                    {!openMain.includes(i) ? (
                      <BsChevronRight style={{ fontSize: '1rem', fontWeight: 'bold' }} />
                    ) : (
                      <BsChevronDown style={{ fontSize: '1rem', fontWeight: 'bold' }} />
                    )}
                    Main Market
                  </button>
                </div>

                {openMain.includes(i) && (
                  <div className="table-border">
                    <table width="100%">
                      <tbody>
                        {Object.entries(el.market_limits).map(([fieldName, keyIndex], j) => (
                          <tr className="custom-row" key={j}>
                            <td>{fieldName}</td>
                            <td>
                              <ToogleButton
                                defaultChecked={parseInt(keyIndex.is_active)}
                                onChange={(event) => toggleActive(fieldName, i, event)}
                              />
                            </td>
                            <td>
                              <label htmlFor="stake_limit">Stake Limit</label> <br />
                              <input
                                autoComplete="off"
                                type="number"
                                disabled={parseInt(keyIndex.is_active) === 0}
                                value={keyIndex.stake_limit}
                                onChange={(event) => handleChange('stake_limit', fieldName, i, event.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="stake limit"
                                className="league-detail-input"
                                style={{ borderColor: keyIndex.stake_limit === '' ? 'red' : '' }}
                              />
                            </td>
                            <td>
                              <label htmlFor="odd_limit">Odd Limit</label> <br />
                              <input
                                autoComplete="off"
                                type="number"
                                disabled={parseInt(keyIndex.is_active) === 0}
                                value={keyIndex.odd_limit}
                                onChange={(event) => handleChange('odd_limit', fieldName, i, event.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="odd limit"
                                className="league-detail-input"
                                style={{ borderColor: keyIndex.odd_limit === '' ? 'red' : '' }}
                              />
                            </td>
                            <td>
                              <label htmlFor="inplay_stake_limit">Inplay Stake Limit</label> <br />
                              <input
                                autoComplete="off"
                                type="number"
                                disabled={parseInt(keyIndex.is_active) === 0}
                                value={keyIndex.inplay_stake_limit}
                                onChange={(event) => handleChange('inplay_stake_limit', fieldName, i, event.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="inplay stake limit"
                                className="league-detail-input"
                                style={{ borderColor: keyIndex.inplay_stake_limit === '' ? 'red' : '' }}
                              />
                            </td>
                            <td>
                              <label htmlFor="inplay_max_odd_limit">Inplay Max Odd Limit</label> <br />
                              <input
                                autoComplete="off"
                                type="number"
                                disabled={parseInt(keyIndex.is_active) === 0}
                                value={keyIndex.inplay_max_odd_limit}
                                onChange={(event) => handleChange('inplay_max_odd_limit', fieldName, i, event.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="inplay max odd limit"
                                className="league-detail-input"
                                style={{ borderColor: keyIndex.inplay_max_odd_limit === '' ? 'red' : '' }}
                              />
                            </td>
                            <td>
                              <label htmlFor="max_market_limit">Max Market Limit</label> <br />
                              <input
                                autoComplete="off"
                                type="number"
                                disabled={parseInt(keyIndex.is_active) === 0}
                                value={keyIndex.max_market_limit}
                                onChange={(event) => handleChange('max_market_limit', fieldName, i, event.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="max market limit"
                                className="league-detail-input"
                                style={{ borderColor: keyIndex.max_market_limit === '' ? 'red' : '' }}
                              />
                            </td>
                            <td>
                              <label htmlFor="inplay_max_market_limit">Inplay Max Market Limit</label> <br />
                              <input
                                autoComplete="off"
                                type="number"
                                disabled={parseInt(keyIndex.is_active) === 0}
                                value={keyIndex.inplay_max_market_limit}
                                onChange={(event) => handleChange('inplay_max_market_limit', fieldName, i, event.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="inplay max market limit"
                                className="league-detail-input"
                                style={{ borderColor: keyIndex.inplay_max_market_limit === '' ? 'red' : '' }}
                              />
                            </td>
                            <td>
                              <label htmlFor="delay">Delay</label> <br />
                              <input
                                autoComplete="off"
                                type="number"
                                disabled={parseInt(keyIndex.is_active) === 0}
                                value={keyIndex.delay}
                                onChange={(event) => handleChange('delay', fieldName, i, event.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="delay"
                                className="league-detail-input"
                                style={{ borderColor: keyIndex.delay === '' ? 'red' : '' }}
                              />
                            </td>
                          </tr>
                        ))}
                        <tr className="custom-row">
                          <td colSpan={9}>
                            <button
                              className="update-league-btn"
                              type="button"
                              disabled={parseInt(el.is_active) === 0}
                              onClick={() => updateLeagueSettings(el, i, 'market_limits')}
                            >
                              Update
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

                <div className="market-accordian-body">
                  <button
                    className={`accordion ${openFancy.includes(i) ? 'active' : 'inactive'}`}
                    onClick={() => handleFancyAccordian(i)}
                  >
                    {!openFancy.includes(i) ? (
                      <BsChevronRight style={{ fontSize: '1rem', fontWeight: 'bold' }} />
                    ) : (
                      <BsChevronDown style={{ fontSize: '1rem', fontWeight: 'bold' }} />
                    )}
                    Fancy Market
                  </button>
                </div>

                {openFancy.includes(i) && (
                  <div className="table-border">
                    <table width="100%">
                      <tbody>
                        {Object.entries(el.fancy_limits).map(([fieldName, keyIndex], j) => (
                          <tr className="custom-row" key={j}>
                            <td>{fieldName.split('_').join(' ')}</td>
                            <td>
                              <label htmlFor="stake_limit">Stake Limit</label> <br />
                              <input
                                autoComplete="off"
                                type="number"
                                value={keyIndex.stake_limit}
                                onChange={(event) => handleChangeFancy('stake_limit', fieldName, i, event.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="stake limit"
                                className="league-detail-input"
                                style={{ borderColor: keyIndex.stake_limit === '' ? 'red' : '' }}
                              />
                            </td>
                            <td>
                              <label htmlFor="odd_limit">Odd Limit</label> <br />
                              <input
                                autoComplete="off"
                                type="number"
                                value={keyIndex.odd_limit}
                                onChange={(event) => handleChangeFancy('odd_limit', fieldName, i, event.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="odd limit"
                                className="league-detail-input"
                                style={{ borderColor: keyIndex.odd_limit === '' ? 'red' : '' }}
                              />
                            </td>
                            <td>
                              <label htmlFor="inplay_stake_limit">Inplay Stake Limit</label> <br />
                              <input
                                autoComplete="off"
                                type="number"
                                value={keyIndex.inplay_stake_limit}
                                onChange={(event) => handleChangeFancy('inplay_stake_limit', fieldName, i, event.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="inplay stake limit"
                                className="league-detail-input"
                                style={{ borderColor: keyIndex.inplay_stake_limit === '' ? 'red' : '' }}
                              />
                            </td>
                            <td>
                              <label htmlFor="inplay_max_odd_limit">Inplay Max Odd Limit</label> <br />
                              <input
                                autoComplete="off"
                                type="number"
                                value={keyIndex.inplay_max_odd_limit}
                                onChange={(event) => handleChangeFancy('inplay_max_odd_limit', fieldName, i, event.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="inplay max odd limit"
                                className="league-detail-input"
                                style={{ borderColor: keyIndex.inplay_max_odd_limit === '' ? 'red' : '' }}
                              />
                            </td>
                            <td>
                              <label htmlFor="max_market_limit">Max Market Limit</label> <br />
                              <input
                                autoComplete="off"
                                type="number"
                                value={keyIndex.max_market_limit}
                                onChange={(event) => handleChangeFancy('max_market_limit', fieldName, i, event.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="max market limit"
                                className="league-detail-input"
                                style={{ borderColor: keyIndex.max_market_limit === '' ? 'red' : '' }}
                              />
                            </td>
                            <td>
                              <label htmlFor="inplay_max_market_limit">Inplay Max Market Limit</label> <br />
                              <input
                                autoComplete="off"
                                type="number"
                                value={keyIndex.inplay_max_market_limit}
                                onChange={(event) => handleChangeFancy('inplay_max_market_limit', fieldName, i, event.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="inplay max market limit"
                                className="league-detail-input"
                                style={{ borderColor: keyIndex.inplay_max_market_limit === '' ? 'red' : '' }}
                              />
                            </td>
                            <td>
                              <label htmlFor="delay">Delay</label> <br />
                              <input
                                autoComplete="off"
                                type="number"
                                value={keyIndex.delay}
                                onChange={(event) => handleChangeFancy('delay', fieldName, i, event.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="delay"
                                className="league-detail-input"
                                style={{ borderColor: keyIndex.delay === '' ? 'red' : '' }}
                              />
                            </td>
                            <td>
                              <label htmlFor="stake_limit">Commission</label> <br />
                              <ToogleButton
                                defaultChecked={keyIndex.is_com === 1}
                                onChange={(value) => handleChangeFancy('is_com', fieldName, i, value ? 1 : 0)}
                              />
                            </td>
                          </tr>
                        ))}
                        <tr className="custom-row">
                          <td colSpan={9}>
                            <button
                              className="update-league-btn"
                              type="button"
                              onClick={() => updateLeagueSettings(el, i, 'fancy_limits')}
                            >
                              Update
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

                <div className="market-accordian-body">
                  <button
                    className={`accordion ${openBookmaker.includes(i) ? 'active' : 'inactive'}`}
                    onClick={() => handleBookmakerAccordian(i)}
                  >
                    {!openBookmaker.includes(i) ? (
                      <BsChevronRight style={{ fontSize: '1rem', fontWeight: 'bold' }} />
                    ) : (
                      <BsChevronDown style={{ fontSize: '1rem', fontWeight: 'bold' }} />
                    )}
                    Bookmaker Market
                  </button>
                </div>

                {openBookmaker.includes(i) && (
                  <div className="table-border">
                    <table width="100%">
                      <tbody>
                        <tr className="custom-row">
                          <td>
                            <label htmlFor="stake_limit">Stake Limit</label> <br />
                            <input
                              type="number"
                              autoComplete="off"
                              value={el.bookmaker_limits.stake_limit}
                              onChange={(event) => handleChangeBookmaker('stake_limit', i, event.target.value)}
                              onKeyDown={handleKeyDown}
                              placeholder="stake limit"
                              className="league-detail-input"
                              style={{ borderColor: el.bookmaker_limits.stake_limit === '' ? 'red' : '' }}
                            />
                          </td>
                          <td>
                            <label htmlFor="odd_limit">Odd Limit</label> <br />
                            <input
                              type="number"
                              autoComplete="off"
                              value={el.bookmaker_limits.odd_limit}
                              onChange={(event) => handleChangeBookmaker('odd_limit', i, event.target.value)}
                              onKeyDown={handleKeyDown}
                              placeholder="odd limit"
                              className="league-detail-input"
                              style={{ borderColor: el.bookmaker_limits.odd_limit === '' ? 'red' : '' }}
                            />
                          </td>
                          <td>
                            <label htmlFor="inplay_stake_limit">Inplay Stake Limit</label> <br />
                            <input
                              type="number"
                              autoComplete="off"
                              value={el.bookmaker_limits.inplay_stake_limit}
                              onChange={(event) => handleChangeBookmaker('inplay_stake_limit', i, event.target.value)}
                              onKeyDown={handleKeyDown}
                              placeholder="inplay stake limit"
                              className="league-detail-input"
                              style={{ borderColor: el.bookmaker_limits.inplay_stake_limit === '' ? 'red' : '' }}
                            />
                          </td>
                          <td>
                            <label htmlFor="inplay_max_odd_limit">Inplay Max Odd Limit</label> <br />
                            <input
                              type="number"
                              autoComplete="off"
                              value={el.bookmaker_limits.inplay_max_odd_limit}
                              onChange={(event) => handleChangeBookmaker('inplay_max_odd_limit', i, event.target.value)}
                              onKeyDown={handleKeyDown}
                              placeholder="inplay max odd limit"
                              className="league-detail-input"
                              style={{ borderColor: el.bookmaker_limits.inplay_max_odd_limit === '' ? 'red' : '' }}
                            />
                          </td>
                          <td>
                            <label htmlFor="max_market_limit">Max Market Limit</label> <br />
                            <input
                              type="number"
                              autoComplete="off"
                              value={el.bookmaker_limits.max_market_limit}
                              onChange={(event) => handleChangeBookmaker('max_market_limit', i, event.target.value)}
                              onKeyDown={handleKeyDown}
                              placeholder="max market limit"
                              className="league-detail-input"
                              style={{ borderColor: el.bookmaker_limits.max_market_limit === '' ? 'red' : '' }}
                            />
                          </td>
                          <td>
                            <label htmlFor="inplay_max_market_limit">Inplay Max Market Limit</label> <br />
                            <input
                              type="number"
                              autoComplete="off"
                              value={el.bookmaker_limits.inplay_max_market_limit}
                              onChange={(event) => handleChangeBookmaker('inplay_max_market_limit', i, event.target.value)}
                              onKeyDown={handleKeyDown}
                              placeholder="inplay max market limit"
                              className="league-detail-input"
                              style={{ borderColor: el.bookmaker_limits.inplay_max_market_limit === '' ? 'red' : '' }}
                            />
                          </td>
                          <td>
                            <label htmlFor="delay">Delay</label> <br />
                            <input
                              type="number"
                              autoComplete="off"
                              value={el.bookmaker_limits.delay}
                              onChange={(event) => handleChangeBookmaker('delay', i, event.target.value)}
                              onKeyDown={handleKeyDown}
                              placeholder="delay"
                              className="league-detail-input"
                              style={{ borderColor: el.bookmaker_limits.delay === '' ? 'red' : '' }}
                            />
                          </td>
                          <td>
                            <label htmlFor="stake_limit">Commission</label> <br />
                            <ToogleButton
                              defaultChecked={el.bookmaker_limits.is_com === 1}
                              onChange={(value) => handleChangeBookmaker('is_com', i, value ? 1 : 0)}
                            />
                          </td>
                        </tr>
                        <tr className="custom-row">
                          <td colSpan={8}>
                            <button
                              className="update-league-btn"
                              type="button"
                              disabled={parseInt(el.is_active) === 0}
                              onClick={() => updateLeagueSettings(el, i, 'bookmaker_limits')}
                            >
                              Update
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

              </>
            )
            }
          </motion.div>
        )) : <ErrorBoundary />
      }

    </div>
  )
}
export default LeagueSettingsDetails