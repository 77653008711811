import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import ResultTab from './ResultTab';
import Button from '../../component/button/Button';
import { getCaller, postCaller } from '../../services/api';
import { AiOutlineClose } from 'react-icons/ai';
import Swal from 'sweetalert2';
import '../result/result.css';
import { IoSearchOutline } from 'react-icons/io5';
import Pagination from '../../component/pagination/Pagination';
import SurveillanceModel from '../../component/modal/SurveillanceModel';
import Select from 'react-select';
import Result from './result';
import getLocalData from '../../utilities/LocalStorageData.utilities';

const PendingBookmaker = () => {
  const [inputValue, setInputValue] = useState('');
  const [curRunner, setCurRunner] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [pendingBookmakerList, setPendingBookmakerList] = useState([]);
  const [fetchedPages, setFetchedPages] = useState({});
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sportList, setSportList] = useState([]);
  const [sport, setSport] = useState('');
  const [eventId, setEventId] = useState('');
  const [eventList, setEventList] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [index, setIndex] = useState(-1);
  const limit = 100;
  const intervalRef = useRef(null); // Ref to store the interval ID
  const [searchField, setSearchField] = useState('');
  const [operatorList, setOperatorList] = useState([]);
  const [operator, setOperator] = useState('');
  const [scope, setScope] = useState()

  useEffect(() => {
    const {result} = getLocalData('scope')
    const userType = getLocalData('user_role')
    setScope({result, userType})
    async function fetchSports() {
      let sportResponse = await getCaller('api/v1/sports/management/getSport');
      setSportList(sportResponse.data);
    }
    fetchSports();
  }, []);

  const handleSport = async (event) => {
    setEventList([]);
    setEventId('');
    const selectedSport = event.value;
    setSport(selectedSport);
    const eventResponse = await getCaller(`api/v1/events/findEvents?id=${selectedSport}`);
    setEventList(eventResponse.data);
    setPendingBookmakerList([]);
  };

  const handleEvent = async (event) => {
    const newEventId = event.value;
    if (newEventId !== eventId) {
      setEventId(newEventId);
      setCurrentPage(1);
      setFetchedPages({});
      allBookmakerPendingResult(newEventId, 1);
    }
  };

  useEffect(() => {
    if (eventId) {
      allBookmakerPendingResult(eventId, currentPage);
      setCurRunner([]);
    }
    return () => clearInterval(intervalRef.current);
  }, [eventId, currentPage]);
  
  useEffect(() => {
    const getData = async() => {
      const response = await getCaller(`api/v1/results/operatorList`)
        const fetchedOperatorList = response?.data?.operatorList
        setOperatorList(fetchedOperatorList)
    }
    getData()
  },[])

  const allBookmakerPendingResult = async (currentEventId, page = 1) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    const initialOperator = {};
    intervalRef.current = setInterval(async () => {
      const res = await getCaller(`api/v1/bookmakers/undeclared?event=${currentEventId}&sport=${sport}&page=${page}&limit=${limit}`);
      res.data.forEach(el => el.result = '');
      if (JSON.stringify(res.data) !== JSON.stringify(pendingBookmakerList)) {
        const fetchPendingData = res.data
        setPendingBookmakerList(fetchPendingData);
        setTotalPage(res?.pagination?.totalPages);
        setFetchedPages((prev) => ({ ...prev, [page]: fetchPendingData }));
       
        fetchPendingData.forEach(e => {
          initialOperator[e.id] = operatorList.map(el => ({ value: el, label: el }));
        });
       
        
      }
    }, 1000);
    setOperator(initialOperator);
  };

  const handlePageChange = async (page) => {
    setCurrentPage(page);
    allBookmakerPendingResult(eventId, page);
  };

  useEffect(() => {
    setCompleteData(pendingBookmakerList);
  }, [inputValue, pendingBookmakerList]);

  const handleBookmakerResult = async(index, value) => {
    const newCurRunner = [...curRunner];
    newCurRunner[index] = value;
    setCurRunner(newCurRunner);
    if(newCurRunner){
      const res = await getCaller(`api/v1/results/operatorList`)
      setOperatorList(res?.data?.operatorList)
    }
  };

  const handleSubmitResult = async () => {
    const i = index;
    if (i === -1) return;

    const result = JSON.parse(curRunner[i]);
    const values = operator[completeData[i].id].map(item => item.value);
    const data = {
      sport_id: completeData[i].sport_id,
      match_id: completeData[i].match_id,
      bookmaker_id: completeData[i].id,
      result: { sln: result.sln, si: result.si },
      market_name: completeData[i].market_name,
      selection: result.sln,
      operators_list:values
    };

    Swal.fire({
      title: 'Are you sure?',
      text: `You want to Submit this Result (${result.sln})?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await postCaller(`api/v1/results`, data);
        if (response.success) {
          Swal.fire({
            title: 'Submitted',
            text: 'Your Result is Submitted Successfully',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000,
            background: 'white',
            color: 'green'
          }).then(() => {
            setPendingBookmakerList(prevList => prevList.filter(b => b.id !== completeData[i].id));
          });
        } else {
          Swal.fire({
            toast: true,
            position: 'top',
            background: 'red',
            color: 'white',
            showConfirmButton: false,
            timer: 2000,
            width: 300,
            padding: '.5rem',
            icon: 'error',
            iconColor: 'white',
            title: response.message
          });
        }
      }
    });
  };

  const filteredManagers = completeData.filter((market) => {
    const marketName = market.market_name ? market.market_name.toLowerCase() : "";
    const eventName = market.event_name ? market.event_name.toLowerCase() : "";
    return marketName.includes(searchField.toLowerCase()) || eventName.includes(searchField.toLowerCase());
  });

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      outline: 'none',
      height: '2.5rem',
      marginTop: '0.2rem',
      border: '1px solid #cbcbcb',
      borderRadius: '0.25rem',
      minWidth : '300px',
      width: '100%'
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 20
    }),
    input: (provided) => ({
      ...provided,
      marginTop: '-7px'
    })
  };

  const options = [{ value: 'all', label: 'Select All' }, ...operatorList.map((el) => ({ value: el, label: el }))];

  const handleOperator = (selectedOptions,id) => {
    if (selectedOptions.some(option => option.value === 'all')) {
      if (selectedOptions.length === options.length) {
        setOperator(prevState => ({ ...prevState, [id]: [] }));
      } else {
        setOperator(prevState => ({ ...prevState, [id]: operatorList.map(el => ({ value: el, label: el })) }));
      }
    } else {
  
      setOperator(prevState => ({ ...prevState, [id]: selectedOptions }));
    }
  };
  
  const getOptions = (selectedOptions) => {
    if (selectedOptions?.length === operatorList.length) {
      return operatorList.map((el) => ({ value: el, label: el }));
    }
    return options;
  }; 

  return (
    <>
      <Result />
      <ResultTab />
      <motion.div
        className="wrapper"
        initial={{ opacity: 0, x: '-100vh' }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <div className="result-sport-content">
          <div className="result-event-select">
            <label htmlFor="date">Select Sport</label>
            <Select
              onChange={handleSport}
              options={sportList.map(el => ({ value: el.id, label: el.name }))}
              styles={selectStyles}
              placeholder="Select Sport"
            />
          </div>
          <div className="result-event-select">
            <label htmlFor="date">Select Events</label>
            <Select
              value={eventId ? {  
                  value: eventId, 
                  label: `${eventList.find(el => el.event_id === eventId)?.event_name} (${eventList.find(el => el.event_id === eventId)?.event_date.split('.')[0]})`  } : null}
              onChange={handleEvent}
              options={eventList.map(el => ({ value: el.event_id, label: `${el.event_name} (${el.event_date.split('.')[0]})` }))}
              styles={selectStyles}
              placeholder="Select Event"
            />
          </div>
          <div className="result-event-select"></div>
          <div className="result-event-select"></div>
          <div className="result-event-select"></div>
        </div>
        <div className="table-border">
          <div className="wrapper-body">
            <div className="wrapper-content-1">
              <div className="searchcard">
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="Search"
                  className="result-input"
                  onChange={(e) => setSearchField(e.target.value)}
                  value={searchField}
                />
                {searchField ? (
                  <div className="ai-close">
                    <AiOutlineClose onClick={() => setSearchField('')} />
                  </div>
                ) : (
                  <div className="ai-close">
                    <IoSearchOutline />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div >
            <table width="100%">
              <thead>
                <tr className="custom-row">
                  <th>#</th>
                  <th>Sport</th>
                  <th>Match</th>
                  <th>Bookmaker Name</th>
                  <th width="250">Result</th>
                  <th width="250">Operator</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredManagers.length > 0 ? (
                  filteredManagers.map((e, i) => (
                    <tr className="custom-row" key={i + e.event_id}>
                      <td>{e.id}</td>
                      <td style={{ textTransform: 'capitalize' }}>{e.sport_name}</td>
                      <td style={{ textTransform: 'capitalize' }}>{e.event_name}</td>
                      <td style={{ textTransform: 'capitalize' }}>{e.market_name}</td>
                      <td>
                        <div className="select-pending-container">
                          <select
                            value={curRunner[i] || ''}
                            className="result-input"
                            onChange={(event) => handleBookmakerResult(i, event.target.value)}
                          >
                            <option value="">Select Result</option>
                            {(JSON.parse(e.runners) || []).map((el, index) => (
                              <option key={index} value={JSON.stringify(el)}>
                                {el.sln}
                              </option>
                            ))}
                            <option value={JSON.stringify({ sln: 'tie', si: 'tie' })}>Tie</option>
                            <option value={JSON.stringify({ sln: 'abandoned', si: 'abandoned' })}>Abandoned</option>
                          </select>
                        </div>
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                          <div className="select-pending-container">
                          <Select
                              tabIndex={-1}
                              onChange={selectedOptions => handleOperator(selectedOptions, e.id)}
                              options={getOptions(operator[e.id])}
                              value={operator[e.id] || ''} 
                              placeholder="Select Operators"
                              isMulti
                          />

                          </div>
                        </td>
                      <td>
                        <div className="pending-result-btn">
                          <Button
                            className="submit"
                            disabled={(!operator[e.id]?.length) || (scope?.userType === 'manager' && !scope?.result?.add) || !curRunner[i]}
                            name="Set Result"
                            type="submit"
                            onClick={() => {
                              setIndex(i);
                              setOpen(true);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="no-data-column" align="center" colSpan={6}>No Data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPage}
          onPageChange={handlePageChange}
        />
      </motion.div>
      <SurveillanceModel
        isOpen={isOpen}
        setOpen={setOpen}
        onCloseModel={handleSubmitResult}
        matchID={completeData[index]?.event_id}
        marketID={completeData[index]?.external_id}
        marketType={2}
      />
    </>
  );
};

export default PendingBookmaker;
