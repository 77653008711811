import React, { useEffect, useState,useRef  } from 'react'
import '../downline.css'
import Swal from "sweetalert2";
import ToogleButton from '../../../component/toggleButton/ToogleButton';
import { callInInterval } from "../../../services/utils";
import { updateCaller, getCaller, postCaller } from "../../../services/api";
import timeConversion from '../../../utilities/timeConversion';
import Select from 'react-select';
import Button from '../../../component/button/Button';
import 'react-datepicker/dist/react-datepicker.css';
import "../admin-style.css";

const AgentGetFancy = ({ marketAssignData, setMarketAssignData, setUpdateTime, isSus }) => {
  let [voidBets, setVoidBets] = useState([])
  let [result, setResult] = useState("");
  let [runnerType, setRunnerType] = useState("")
  const [fancy, setFancy] = useState("")
  const [localMarketData, setLocalMarketData] = useState(marketAssignData);
  const [agentDetails, setAgentDetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchOperator, setSearchOperator] = useState('');
  const [searchSelection, setSearchSelection] = useState('');
  const [minStake, setMinStake] = useState('');
  const [minOdds, setMinOdds] = useState('');
  const [maxStake, setMaxStake] = useState('');
  const [maxOdds, setMaxOdds] = useState('');
  const [selectionType, setSelectionType] = useState('');
  const intervalRef = useRef(null);
  const [isLive, setIsLive] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [totalBets, setTotalBet] = useState(false);
  const [operatorList, setOperatorList] = useState([]);
  const [operator, setOperator] = useState('');
  const [fetchOddGapList, setFetchOddGapList] = useState([]);

  const toggleVisibility = () => {
    setIsVisible(prevIsVisible => !prevIsVisible);
  };

  useEffect(() => {
    if (marketAssignData?.market) {
      const markets = [];
      for (const market of marketAssignData.market) {
        if (typeof market.odds !== 'object') {
          market.odds = JSON.parse(market.odds);
        }
        markets.push({ ...market });
      }
      setLocalMarketData({ ...marketAssignData, market: markets });
    }
  }, []);

  useEffect(() => {
    const getAgentDetails = async () => {
      try {
        let agentData = await getCaller(`api/v1/users/${localMarketData.id}`);
        setAgentDetails(agentData?.data);

        if (agentData?.data?.odd_gap_ids) {
          const response = await getCaller(`api/v1/settings/oddGapSettingByIds?odd_gap_ids=${agentData.data.odd_gap_ids}`);
          setFetchOddGapList(response.data);
        }
      } catch (error) {

      }
    };

    getAgentDetails();
  }, [localMarketData])
  
  const calculateYesOdds = (noOdds, oddsData) => {
    const found = oddsData.find(item => item.odds_no === noOdds);
    return found ? found.odds_yes : null;
  };

  useEffect(() => {
    const getData = async() => {
      const response = await getCaller(`api/v1/results/operatorList`)
        const fetchedOperatorList = response?.data?.operatorList;
        setOperatorList(fetchedOperatorList);
    }
    getData()
  },[] )

  const handleBookmakerResult = async (event) => {
    setFancy(event.value.market_name);
    setResult('');
    setRunnerType(event.value);
    setOperator('');
    if(event?.value){
      const initialOperator  = operatorList?.map(el => ({ value: el, label: el }));
        setOperator(initialOperator);
    }
  }

  const handleResult = (event) => {
    setResult(event) 
  }

  const onSubmit = (e) => {
    e.preventDefault();

    const resultData = JSON.parse(runnerType);
    const data = {
      sport_id: parseInt(marketAssignData?.sport_id),
      match_id: parseInt(marketAssignData?.match_id),
      fancy_id: resultData?.id,
      result: result,
      market_name: resultData?.market_name,
      selection: "",
      operators_list: operator
    };

    Swal.fire({
      title: 'Are you sure?',
      text: `You want to Submit this Result (${resultData.market_name})`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await postCaller(`api/v1/results`, data);
        if (response.success) {
          Swal.fire({
            title: 'Submitted',
            text: response.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1000,
            background: 'white',
            color: 'green'
          }).then(async function () {
            setResult("");
            setFancy("");
            setOperator('');
            const updatedMarketAssignData = await getCaller(`api/v1/users/showAssignMarketAgent`);
            setMarketAssignData(updatedMarketAssignData?.data);
            setLocalMarketData(updatedMarketAssignData?.data);
          })
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            background: "red",
            color: "white",
            showConfirmButton: false,
            timer: 2000,
            width: 300,
            padding: ".5rem"
          });

          Toast.fire({
            icon: 'error',
            iconColor: "white",
            title: response.message
          });
        }
      }
    })
  }

  useEffect(() => {
    if (isSus && localMarketData?.market) {
      const markets = [];
      for (const market of localMarketData.market) {
        market.is_sus = 1;
        markets.push({ ...market });
      }
      let getData = async() => {
      await updateCaller(`api/v1/users/updateUser/${ localMarketData?.id}`, { is_suspend_all: 1 });
      let agentData =  await getCaller(`api/v1/users/${localMarketData.id}`);
      setAgentDetails(agentData?.data);
      }
      getData()
      setLocalMarketData({ ...localMarketData, market: markets });
    } else if ((isSus === null || isSus === 0) && localMarketData?.market) {
      const markets = [];
      for (const market of localMarketData.market) {
        market.is_sus = 0;
        markets.push({ ...market });
      }
      let getData = async() => {
        await updateCaller(`api/v1/users/updateUser/${ localMarketData?.id}`, { is_suspend_all: 0 });
        let agentData =  await getCaller(`api/v1/users/${localMarketData.id}`);
        setAgentDetails(agentData?.data);
      } 
      getData()
      setLocalMarketData({ ...localMarketData, market: markets });
    }

  }, [isSus]);

  useEffect(() => {
    const fetchVoidBets = async () => {
      try {
        if (marketAssignData?.match_id && marketAssignData?.market[0]?.external_id) {
          let response = await getCaller(
            `api/v1/void-bets/3,2,1/4/${marketAssignData.match_id}/fancy/${marketAssignData?.market[0]?.external_id}?page=1&limit=20&child=${searchQuery}&operator=${searchOperator}&selectionType=${selectionType }&selection=${searchSelection}&stakeMin=${minStake}&stakeMax=${maxStake}&oddsMin=${minOdds}&oddsMax=${maxOdds}`
          );
          setTotalBet(response?.pagination?.total || 0);
          setVoidBets(response.data)
        }
      } catch (error) {

      }
    };

    fetchVoidBets();
    if (!intervalRef.current) {
      intervalRef.current = setInterval(fetchVoidBets, 2000);
    }
    return () => clearInterval(intervalRef.current);

  }, [marketAssignData.match_id]);

  const handleSuspendAll = async (field, id, status) => {
    let updateResponse = await updateCaller(`api/v1/users/updateUser/${id}`, { [field]: status });
  
    if (updateResponse.success) {
  
      if (marketAssignData?.event_id) {
        let updateFancyResponse = await updateCaller(`api/v1/fancies/updateFancy/${marketAssignData.event_id}/${id}`, { ['is_sus']: status });
        if (updateFancyResponse.success) {
          const markets = [];
          for (const market of marketAssignData.market) {
            market.is_sus = status;
            markets.push({ ...market });
          }
          setLocalMarketData({ ...marketAssignData, market: markets });
          toasterMessage(updateFancyResponse, 'Fancy Updated Successfully', 'bottom');
        }
      }
    }
  };
  
  const toasterMessage = (response, message) => {
    const Toast = Swal.mixin({
      toast: true,
      background: "black",
      color: "white",
      showConfirmButton: false,
      timer: 2000,
      width: 450,
      position: "bottom",
      padding: ".5rem",
    });
  
    Toast.fire({
      icon: response.success ? 'success' : 'warning',
      title: message || (response.success ? 'Fancy Updated Successfully' : 'Failed!'),
      background: 'white',
      color: response.success ? 'green' : 'red',
    });
  };

  const updateMarket = async (id) => {
    setUpdateTime();
    const updatedMarket = localMarketData.market.find((market) => market.id === id);
    const response = await updateCaller(`api/v1/fancies/${id}/agent`, {
      is_sus: updatedMarket.is_sus,
      is_active: updatedMarket.is_active,
      odd_gap: updatedMarket.odd_gap,
      msg: updatedMarket.msg,
      runNo: updatedMarket.odds.runNo,
      runYes: updatedMarket.odds.runYes,
      oddsNo: updatedMarket.odds.oddsNo,
      oddsYes: updatedMarket.odds.oddsYes,
      size: updatedMarket.odds.size,
      back: updatedMarket.odds.back,
      lay: updatedMarket.odds.lay,
      end_date: updatedMarket.end_date,
    })
    if (response.success === true) {
      const Toast = Swal.mixin({
        toast: true,
        background: "black",
        color: "white",
        showConfirmButton: false,
        timer: 2000,
        width: 450,
        position: "bottom",
        padding: ".5rem"
      })
      Toast.fire({
        icon: 'success',
        title: "Fancy Updated Successfully",
        background: 'white',
        color: 'green'
      })
    }
  }

  const handleClearField = () => {
    setIsLive(true);
    setSearchQuery('');
    setSearchOperator('');
    setSearchSelection('');
    setMinStake('');
    setMaxStake('');
    setMinOdds('');
    setMaxOdds('');
    setSelectionType('');

    const searchQuery= ''
    const searchOperator= '';
    const searchSelection= '';
    const  minStake= '';
    const maxStake= '';
    const  minOdds= '';
    const maxOdds= '';
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    
    const fetchVoidBets = async () => {
      try {
        if (marketAssignData?.match_id && marketAssignData?.market[0]?.external_id) {
          let response = await getCaller(
            `api/v1/void-bets/3,2,1/4/${marketAssignData.match_id}/fancy/${marketAssignData?.market[0]?.external_id}?page=1&limit=20`
          );
          setTotalBet(response?.pagination?.total || 0);
          setVoidBets(response.data)
        }
      } catch (error) {

      }
    };

    fetchVoidBets();
    if (!searchQuery && !searchOperator && !searchSelection && !minStake && !maxStake && !minOdds && !maxOdds ) {
      intervalRef.current = setInterval(fetchVoidBets, 2000);
    }
  }
  const handleSearchBet = async () => {
    setIsLive(false);

    clearInterval(intervalRef.current);
    intervalRef.current = null;
    
    const fetchVoidBets = async () => {
      try {
        if (marketAssignData?.match_id && marketAssignData?.market[0]?.external_id) {
          let response = await getCaller(
            `api/v1/void-bets/3,2,1/4/${marketAssignData.match_id}/fancy/${marketAssignData?.market[0]?.external_id}?page=1&limit=20&child=${searchQuery}&operator=${searchOperator}&selectionType=${selectionType==='all' ? '' :  selectionType  }&selection=${searchSelection}&stakeMin=${minStake}&stakeMax=${maxStake}&oddsMin=${minOdds}&oddsMax=${maxOdds}`
          );
          setTotalBet(response?.pagination?.total || 0);
          setVoidBets(response.data)
        }
      } catch (error) {

      }
    };
    await fetchVoidBets();

    if (!searchQuery && !searchOperator && !searchSelection && !minStake && !maxStake && !minOdds && !maxOdds&& !selectionType ) {
      intervalRef.current = setInterval(fetchVoidBets, 2000);
    }
  };
  const options = [{ value: 'all', label: 'Select All' }, ...operatorList.map((el) => ({ value: el, label: el }))];

  const handleOperator = (selectedOptions) => {
    if (selectedOptions.some(option => option.value === 'all')) {
      if (selectedOptions.length === options.length) {
        setOperator([]);
      } else {
        setOperator(operatorList.map(el => ({ value: el, label: el })));
      }
    } else {
  
      setOperator(selectedOptions);
    }
  };

  const getOptions = (selectedOptions) => {
    if (selectedOptions?.length === operatorList.length) {
      return operatorList.map((el) => ({ value: el, label: el }));
    }
    return options;
  };

  const isSubmitDisabled =  result.length > 0 ? false : true
  return (
    <div>
      {/* <SocketFancy eventID={marketAssignData.event_id}  /> */}
    <div className="book-maker-market pageMain-container">
      <div className="market-match-container pageMain-wrap">
        <div className="pageMain-left">
          <div className="bookMaker-container">
            {isVisible && (				
              <div className="book-maker-match bookMaker-header">
                <div className="span-book">
                  <div className="result-event-select" id='bookmaker-select' >
                    <p>Name</p>
                    <input type="search" autoComplete="off" className="book-maker-column-input" value={searchQuery ?? ''} onChange={(e) => setSearchQuery(e.target.value)} placeholder="User"  />
                  </div>
                  <div className="result-event-select" id='bookmaker-select' >
                  <p>Operator</p>
                  <input type="search" autoComplete="off" className="book-maker-column-input" value={searchOperator ?? ''} onChange={(e) => setSearchOperator(e.target.value)} placeholder="Operator"  />
                  </div>
                  <div className="result-event-select" id='bookmaker-select' >
                  <p>Selection</p>
                  <input type="search" autoComplete="off" className="book-maker-column-input" value={searchSelection ?? ''} onChange={(e) => setSearchSelection(e.target.value)} placeholder="selection"  />
                  </div>
                  <div className="result-event-select" id='bookmaker-select' >
                  <p>Selection Type</p>
                  <select value={selectionType ?? ''} onChange={(e) => setSelectionType(e.target.value)} className="book-maker-column-input">
                    <option value="all"> All </option>
                    <option value="back"> Back </option>
                    <option value="lay"> Lay </option>
                  </select>
                  {/* <input type="search" autoComplete="off" id="" className="book-maker-column-input" value={selectionType} onChange={(e) => setSelectionType(e.target.value)} placeholder="selection Type"  /> */}
                  </div>
                  <div className="result-event-select widthFix" id='bookmaker-select' >
                    <p style={{ display: 'flex',  justifyContent: "space-around"}}>Stake</p>
                    <div style={{ display: 'flex', gap: '10px'}}>
                      <input type="number" autoComplete="off" className="book-maker-column-input" value={minStake ?? ''} onChange={(e) => setMinStake(e.target.value)} placeholder="Min " style={{width: "70px"}} />
                      <input type="number" autoComplete="off" className="book-maker-column-input" value={maxStake ?? ''} onChange={(e) => setMaxStake(e.target.value)} placeholder="Max " style={{width: "70px"}} />
                    </div>
                  </div>
                  <div className="result-event-select widthFix" id='bookmaker-select' >
                    <p style={{ display: 'flex',  justifyContent: "space-around"}}>Odds</p>
                    <div style={{ display: 'flex', gap: '10px'}}>
                      <input type="number" autoComplete="off" id="" className="book-maker-column-input" value={minOdds ?? ''} onChange={(e) => setMinOdds(e.target.value)} placeholder="Min " style={{width: "70px"}} />
                      <input type="number" autoComplete="off" id="" className="book-maker-column-input" value={maxOdds ?? ''} onChange={(e) => setMaxOdds(e.target.value)} placeholder="Max " style={{width: "70px"}} />
                    </div>
                  </div>
                  <Button type='submit' className='submit' name='Search Bet' onClick = { handleSearchBet} />
                  <Button type='submit' className='submit' name='Clear Bet' onClick ={ handleClearField}/>
                </div>
              </div>
            )}
            <div className="bookMaker-body">
              {marketAssignData.show_bet === 1 &&
                <div className="bookMaker-table">
                  <table width="100%">
                    <thead id="fancy-head-coloumn">
                      <tr className="">
                        <th align="left"> OPERATOR </th>
                        <th align="left"> PARENT </th>
                        <th align="left"> USER </th>
                        <th align="left"> SELECTION </th>
                        <th align="left"> ODDS </th>
                        <th align="left"> STAKE </th>
                        {/* <th align="left"> P & L </th> */}
                        <th align="left"> DATE & TIME </th>
                      </tr>
                    </thead>
                    <tbody>
                      {voidBets?.length > 0 ? (
                        voidBets?.map((e, i) => (
                          <tr className={e.selection_type + ' custom-row'} key={i}>
                            <td>{e.operator}</td>
                            <td>{e.parent}</td>
                            <td>{e.child}</td>
                            <td>{e.selection}</td>
                            <td>{e.odds}</td>
                            <td>{e.stake}</td>
                            {/* <td>{e.p_l}</td> */}
                            <td>{timeConversion(e.created_at)}</td>

                          </tr>
                        ))
                      ) : (
                        <tr><td>No data</td></tr>
                      )}
                    </tbody>
                  </table>
                </div>
              }
            </div>
          </div>
          <div className="bookMaker-container">
            <div className="bookMaker-body">
              <div className="bookmaker-table-container" style={{ overflowX: "auto" }}>
                <table width="100%">
                  <thead id="fancy-head-coloumn">
                    <tr className="">
                      <th align="left"> MARKET NAME </th>
                      {/* <th> END DATE </th> */}
                      <th> ODD GAP </th>
                          {["khado_markets", "odd_even_markets"].includes(localMarketData.market_id) ? null : (<th> NO (OUTCOME) </th>)}
                          {["khado_markets", "odd_even_markets"].includes(localMarketData.market_id) ? null : (<th> YES (OUTCOME) </th>)}
                          {["khado_markets", "odd_even_markets"].includes(localMarketData.market_id) ? null : (<th> NO (ODDS) </th>)}
                          {["khado_markets", "odd_even_markets"].includes(localMarketData.market_id) ? null : (<th> YES (ODDS) </th>)}
                          {!["khado_markets"].includes(localMarketData.market_id) ? null : (<th> SIZE </th>)}
                          {!["odd_even_markets"].includes(localMarketData.market_id) ? null : (<th> BACK </th>)}
                          {!["odd_even_markets"].includes(localMarketData.market_id) ? null : (<th> LAY </th>)}
                      <th align="left"> SUSPEND 
                      <ToogleButton
                        defaultChecked={Boolean(agentDetails.is_suspend_all)} onChange={(value) => handleSuspendAll("is_suspend_all", agentDetails.id, value ? 1 : 0)}
                      /> 
                      </th>
                      <th align="left"> ACTIVE </th>
                    </tr>
                  </thead>
                  <tbody>
                    {localMarketData?.market?.length > 0 ? (
                      localMarketData?.market?.map((e, i) => (
                        
                        <tr className="fancy-head-row" key={i}>
                          <td align="left" className='input-group'>
                            <input type="text" autoComplete="off" disabled name="selectionName" value={e.market_name ?? ''} className="book-maker-column-input-1" placeholder="Team Name" />
                            <input
                              type="text" autoComplete="off" name="msg" className="book-maker-column-input-1" placeholder="Message"
                              onChange={(event) => {
                                const newE = { ...e, msg: event.target.value };
                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                              }}
                              onBlur={() => callInInterval(updateMarket, e.id)}
                              value={e.msg ?? ''}
                            />
                          </td>

                          {/* <td>
                            <DatePicker
                              name="end_date"
                              selected={endDate}
                              // onChange={handleEndDateChange}
                              onChange={(event) => {
                                setEndDate(event);
                                const newE = { ...e, end_date: event };
                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                              }}
                              onBlur={() => callInInterval(updateMarket, e.id)}
                              showTimeSelect
                              timeFormat="HH:mm"
                              placeholderText="End Date & Time"
                              timeIntervals={15}
                              dateFormat="yyyy-MM-dd HH:mm:ss"
                              className='fancy-stake-input'
                              value={e?.end_date != null ? moment(e.end_date).format('YYYY-MM-DD HH:mm:ss') : ""}
                            /> <br /></td> */}

                          <td>
                            <input
                              type="text" autoComplete="off" name="gap" className="book-maker-column-input" placeholder="Gap"
                              onChange={(event) => {
                                const rawValue = event.target.value;
                                let value;
                                if (rawValue === '') {
                                  value = null;
                                } else {
                                  value = parseFloat(rawValue);
                                  if (isNaN(value) || value < 0) {
                                    value = 0;
                                  } else if (value > 10) {
                                    value = 10;
                                  }
                                }
                                e.odd_gap = value;
                                e.odds.runYes = parseFloat(e.odds.runNo) + value ;
                                const newE = { ...e, odd_gap:value };
                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                              }}
                              onBlur={() => callInInterval(updateMarket, e.id)}
                              value={e.odd_gap ?? ""}

                            />               
                          </td>

                        {["khado_markets", "odd_even_markets"].includes(localMarketData.market_id) ? null : (
                          <td>
                            <input type="text" autoComplete="off" name="runNo" className="book-maker-column-input" placeholder="NO (OUTCOME)"
                              onChange={(event) => {
                                const rawValue = event.target.value;
                                let value;
                                if (rawValue === '') {
                                  value = 0;
                                } else {
                                  value = parseFloat(rawValue);
                                  if (isNaN(value) || value < 0) {
                                    value = 0;
                                  }
                                }

                                e.odds.runNo =value;
                                e.odds.runYes = e.odd_gap != null && e.odd_gap >= 0 ? parseFloat(e.odds.runNo) + parseFloat(e.odd_gap) : e.odds.runYes;

                                const newE = { ...e, odds: { ...e.odds } };
                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                              }}
                              onBlur={() => callInInterval(updateMarket, e.id)}
                              value={e.odds.runNo ?? ''}

                            />
                          </td>)}

                        {["khado_markets", "odd_even_markets"].includes(localMarketData.market_id) ? null : (
                          <td>
                            <input
                              type="text" autoComplete="off" name="runYes" id="" className="book-maker-column-input" placeholder="YES (OUTCOME)" value={e.odds.runYes ?? ''}

                              onChange={(event) => {
                                const rawValue = event.target.value;
                                let value;
                                if (rawValue === '') {
                                  value = 0;
                                } else {
                                  value = parseFloat(rawValue);
                                  if (isNaN(value) || value < 0) {
                                    value = 0;
                                  }
                                }
                                e.odds.runYes = value;
                                e.odds.runNo = e.odd_gap != null && e.odd_gap >= 0 ? Math.max(0, parseFloat(e.odds.runYes) - parseFloat(e.odd_gap)) : e.odds.runNo
                                const newE = { ...e, odds: { ...e.odds } };
                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                                
                              }}
                              onBlur={() => callInInterval(updateMarket, e.id)}
                            />
                          </td>)}

                          {["khado_markets", "odd_even_markets"].includes(localMarketData.market_id) ? null : (
                          <td>
                            <input
                              type="text" autoComplete="off" name="oddsNo" className="book-maker-column-input" placeholder="NO (ODDS)" value={e.odds.oddsNo ?? ''}
                              onChange={(event) => {
                                const rawValue = event.target.value;
                                let value;
                                if (rawValue === '') {
                                  value = 0;
                                } else {
                                  value = parseFloat(rawValue);
                                  if (isNaN(value) || value < 0) {
                                    value = 0;
                                  }
                                }
                                // if (value > (e.odds.oddsYes || 0)) {
                                //     value = e.odds.oddsYes; 
                                // }
                                e.odds.oddsNo = value;
                                const updatedOddsYes = calculateYesOdds(value, fetchOddGapList);
                                if (updatedOddsYes !== null) {
                                  e.odds.oddsYes = updatedOddsYes;
                                }
                                const newE = { ...e, odds: { ...e.odds } };
                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                              }}
                              onBlur={() => callInInterval(updateMarket, e.id)}

                            />
                          </td>)}

                        {["khado_markets", "odd_even_markets"].includes(localMarketData.market_id) ? null : (
                          <td>
                            <input
                              type="text" autoComplete="off" name="oddsYes" className="book-maker-column-input" placeholder="YES (ODDS)" value={e.odds.oddsYes ?? ''}
                              onChange={(event) => {
                                const rawValue = event.target.value;
                                let value;
                                if (rawValue === '') {
                                  value = 0;
                                } else {
                                  value = parseFloat(rawValue);
                                  if (isNaN(value) || value < 0) {
                                    value = 0;
                                  }
                                }
                                // if (value < (e.odds.oddsNo || 0)) {
                                //   value = e.odds.oddsNo; 
                                // }
                                e.odds.oddsYes = value;
                                const newE = { ...e, odds: { ...e.odds } };
                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                              }}
                              onBlur={() => callInInterval(updateMarket, e.id)}
                            />
                          </td>)}

                          {!["khado_markets"].includes(localMarketData.market_id) ? null : (
                          <td>
                            <input
                              type="text" autoComplete="off" name="size" className="book-maker-column-input" placeholder="SIZE" value={e.odds.size ?? ''}
                              onChange={(event) => {
                                const rawValue = event.target.value;
                                let value;
                                if (rawValue === '') {
                                  value = 0;
                                } else {
                                  value = parseFloat(rawValue);
                                  if (isNaN(value) || value < 0) {
                                    value = 0;
                                  }
                                }
                                // if (value < (e.odds.size || 0)) {
                                //   value = e.odds.size; 
                                // }
                                e.odds.size = value;
                                const newE = { ...e, odds: { ...e.odds } };
                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                              }}
                              onBlur={() => callInInterval(updateMarket, e.id)}
                            />
                          </td>)}

                          {!["odd_even_markets"].includes(localMarketData.market_id) ? null : (
                          <td>
                            <input
                              type="text" autoComplete="off" name="back" className="book-maker-column-input" placeholder="BACK" value={e.odds.back ?? ''}
                              onChange={(event) => {
                                const rawValue = event.target.value;
                                let value;
                                if (rawValue === '') {
                                  value = 0;
                                } else {
                                  value = parseFloat(rawValue);
                                  if (isNaN(value) || value < 0) {
                                    value = 0;
                                  }
                                }
                                // if (value < (e.odds.back || 0)) {
                                //   value = e.odds.back; 
                                // }
                                e.odds.back = value;
                                const newE = { ...e, odds: { ...e.odds } };
                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                              }}
                              onBlur={() => callInInterval(updateMarket, e.id)}
                            />
                          </td>)}

                          {!["odd_even_markets"].includes(localMarketData.market_id) ? null : (
                          <td>
                            <input
                              type="text" autoComplete="off" name="lay" className="book-maker-column-input" placeholder="LAY" value={e.odds.lay ?? ''}
                              onChange={(event) => {
                                const rawValue = event.target.value;
                                let value;
                                if (rawValue === '') {
                                  value = 0;
                                } else {
                                  value = parseFloat(rawValue);
                                  if (isNaN(value) || value < 0) {
                                    value = 0;
                                  }
                                }
                                // if (value < (e.odds.lay || 0)) {
                                //   value = e.odds.lay; 
                                // }
                                e.odds.lay = value;
                                const newE = { ...e, odds: { ...e.odds } };
                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                              }}
                              onBlur={() => callInInterval(updateMarket, e.id)}
                            />
                          </td>)}

                          <td align="left">
                            <ToogleButton
                              defaultChecked={ e?.is_sus === 1 }
                              onChange={(event) => {
                                e.is_sus = event ? 1 : 0;
                                const newE = { ...e, is_sus: e.is_sus };
                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                                updateMarket(e.id);
                              }}
                            />
                          </td>
                          <td align="left">
                            <ToogleButton
                              defaultChecked={Boolean(e.is_active)}
                              onChange={(event) => {
                                e.is_active = event ? 1 : 0
                                const newE = { ...e, is_active: e.is_active };
                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                                updateMarket(e.id);
                              }}
                            />
                          </td>

                        </tr>
                      ))
                    ) : (
                      <tr><td>No data</td></tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          { marketAssignData.allow_declare_result === 1 &&
          <div className="bookMaker-container">				
            <div className="bookMaker-header">
              <div className="bookMaker-heading">
                <p> FANCY RESULT DECLARE </p>
              </div>
            </div>
            <div className="bookMaker-body">
              <form>
                <div className="book-maker-content">
                  <div className='book-maker-select-container'>
                     <div className='book-maker-select'>
                        <label htmlFor='fancy-dropdown'> Select Fancy </label>
                        <Select
                          id='fancy-dropdown'
                          value={fancy}
                          onChange={(selectedOption) => handleBookmakerResult(selectedOption)}
                          options={marketAssignData?.market?.length > 0 ? [
                            ...marketAssignData?.market?.map((el) => ({ value: JSON.stringify(el), label: el.market_name }))
                          ] : []}
                          placeholder="Select Fancy"
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              outline: "none",
                              height: "2.5rem",
                              marginTop: "0.2rem",
                              border: "1px solid #cbcbcb",
                              borderRadius: "0.25rem",
                              width: "100%",
                            }),
                            menu: (provided, state) => ({
                              ...provided,
                              zIndex: 20,
                            }),
                            input: (provided, state) => ({
                              ...provided,
                              marginTop: "-7px"
                            })
                          }}
                        />
                     </div>

                     <div className="book-maker-select" id='bookmaker-select'>
                        <label htmlFor="result-dropdown"> Select Result </label>
                        {
                          (['odd_even_markets']).includes(localMarketData?.market_id) ?
                          <Select
                          id='result-dropdown'
                          value={result ? { value: result, label: result } : null}
                          onChange={(selectedOption) => handleResult(selectedOption.value) }
                          options={[
                            { value: "even", label: "Even" },
                            { value: "odd", label: "Odd" }
                          ]}
                          placeholder="Select Result"
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              outline: "none",
                              height: "2.5rem",
                              marginTop: "0.2rem",
                              border: "1px solid #cbcbcb",
                              borderRadius: "0.25rem",
                              width: "100%",
                            }),
                            menu: (provided, state) => ({
                              ...provided,
                              zIndex: 20,
                            }),
                            input: (provided, state) => ({
                              ...provided,
                              marginTop: "-7px"
                            })
                          }}
                        />

                        : <input type="text" autoComplete="off"
                        name="result"
                        value={result ?? ""}
                      onChange={(e) => handleResult(e.target.value)}
                        className='select-field' id="" />}
                       
                    </div>
                    <div className='book-maker-select'>
                      <label> Select Operators </label>
                      <Select
                          onChange={ handleOperator }
                          options={getOptions(operator)}
                          value={ operator } 
                          placeholder="Select Operators"
                          isMulti
                      />
                    </div>
                    <div className="book-maker-btn-container">
                       <Button className='submit' name='Submit Result' disabled={ isSubmitDisabled } type='submit' onClick={onSubmit} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          }
        </div>
        {marketAssignData.show_bet === 1 &&
          <div className="pageMain-right">
            <div className='filter-container'>
              <div className='filter-title-wrap'>
                <span className='filter-title'>Bets</span>
                <span className='betsCount'>{totalBets}</span>
                </div>
              <div className='filter-wrap'>
                <div className="filter-icon" onClick={toggleVisibility}>
                  <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.94 22.65C10.4537 22.6492 9.97555 22.5254 9.55003 22.29C9.12221 22.0573 8.76529 21.7133 8.51707 21.2943C8.26885 20.8753 8.13857 20.397 8.14003 19.91V14.61C8.08574 14.0169 7.86376 13.4516 7.50003 12.98L3.76003 9.02001C3.10439 8.33721 2.71123 7.44463 2.65003 6.50001V4.20001C2.63964 3.83132 2.70293 3.46426 2.8362 3.12034C2.96947 2.77642 3.17003 2.46256 3.42614 2.19714C3.68225 1.93171 3.98875 1.72007 4.3277 1.57461C4.66664 1.42915 5.0312 1.35279 5.40003 1.35001H18.6C18.9612 1.34974 19.319 1.4207 19.6527 1.5588C19.9865 1.69691 20.2897 1.89946 20.5452 2.15488C20.8006 2.41029 21.0031 2.71355 21.1412 3.04732C21.2793 3.38108 21.3503 3.7388 21.35 4.10001V6.30001C21.2891 7.35753 20.8571 8.35967 20.13 9.13001L15.8 12.96C15.3332 13.4204 15.0642 14.0445 15.05 14.7V19C15.0368 19.4612 14.916 19.913 14.6972 20.3193C14.4784 20.7255 14.1678 21.0751 13.79 21.34L12.41 22.23C11.9689 22.5046 11.4596 22.6501 10.94 22.65ZM5.40003 2.85001C5.22876 2.85486 5.06018 2.89383 4.90417 2.96465C4.74815 3.03546 4.60783 3.13669 4.49142 3.26241C4.37502 3.38813 4.28486 3.53581 4.22624 3.69681C4.16762 3.8578 4.14171 4.02887 4.15003 4.20001V6.50001C4.21548 7.0496 4.45356 7.56428 4.83003 7.97001L8.64003 11.98C9.24265 12.7261 9.59591 13.6425 9.65003 14.6V19.9C9.64844 20.1199 9.70729 20.336 9.82015 20.5246C9.93302 20.7133 10.0956 20.8674 10.29 20.97C10.4917 21.0841 10.7197 21.1432 10.9514 21.1414C11.183 21.1396 11.4101 21.0771 11.61 20.96L13 20.07C13.1621 19.9399 13.2954 19.7776 13.3918 19.5935C13.4882 19.4094 13.5455 19.2073 13.56 19V14.7C13.565 14.1589 13.6801 13.6244 13.8985 13.1292C14.1168 12.6341 14.4338 12.1886 14.83 11.82L19.11 8.03001C19.5424 7.54689 19.8057 6.93608 19.86 6.29001V4.10001C19.86 3.76849 19.7283 3.45054 19.4939 3.21612C19.2595 2.9817 18.9415 2.85001 18.61 2.85001H5.40003Z" fill="currentColor"/>
                    <path d="M6.00001 10.746C5.85952 10.7437 5.7219 10.7059 5.60001 10.636C5.43116 10.5292 5.31126 10.36 5.26633 10.1653C5.2214 9.97061 5.25506 9.76605 5.36001 9.59601L10.29 1.69601C10.3968 1.53214 10.5636 1.41665 10.7546 1.37429C10.9456 1.33193 11.1456 1.36607 11.3117 1.4694C11.4778 1.57272 11.5968 1.73701 11.6432 1.92704C11.6896 2.11708 11.6598 2.31774 11.56 2.48601L6.63001 10.386C6.56489 10.4952 6.47268 10.5857 6.36231 10.6488C6.25193 10.7119 6.12713 10.7453 6.00001 10.746V10.746Z" fill="currentColor"/>
                  </svg> {`${isVisible ? 'Hide' : 'Show'} Filter`}
                </div>
                <div className={`filter-live ${isLive ? 'isLive' : 'isNotLive'}`} >Live Bets<span></span></div>
                <div className={`filter-clear ${!isLive ? 'filterOn' : 'filterOff'}`} onClick ={ handleClearField}>Clear Filter</div>
              </div>
            </div>
            <div className='market-dataWrap'>
              <div className="market-odds-body">
                <div className="">
                  <p>{marketAssignData?.market_id.split("_").join(" ").toUpperCase()}</p>
                </div>
                <div className="market-odds-back">
                  <div style={{ marginRight: ".5rem" }}>
                    <p>BACK</p>
                  </div>
                  <div>
                    <p>LAY</p>
                  </div>
                </div>
              </div>
              <div className="boder-odd">
                {localMarketData?.market?.map((e, i) => (

                  <div className="odds-container" key={i}>
                    <div className="box-odd-name" data-label="#">
                      {e.market_name}
                    </div>

                    <div className="odds-container-1">
                        {e.is_sus === 1 ? (
                          <div className="suspend-box"> Suspended </div>
                        ) : null}
                      <div
                        className="back-box-container-one"
                        data-label="#"
                      >

                        <div
                          className="box"
                          style={{ backgroundColor: "#88cdf4" }}
                        > <p>{e?.odds.runNo}</p>{e?.odds.oddsNo}</div>

                      </div>
                      <div className="back-box-container-one">
                        <div
                          className="box"
                          style={{ backgroundColor: "#d7787d" }}
                        > <p>{e?.odds.runYes}</p>{e?.odds.oddsYes} </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
      </div>
    </div>

    </div>

  )
}

export default AgentGetFancy