import React from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import Swal from "sweetalert2";
import '../user/user.css'
import { AiOutlineClose } from 'react-icons/ai'
import { postCaller } from '../../services/api';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../component/button/Button';
const UserChangePassword = () => {
  const {state} = useLocation()
  const navigate = useNavigate()
  const updatePassData = state?.passData??{}
  const Schema = Yup.object().shape({
    password: Yup.string().required("New Password is required"),
    confirmPassword: Yup.string().required("Confirm Password is required").when("password", {
      is: val => (val && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
    managerPassword: Yup.string().required("Your Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      managerPassword: ''
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      delete values.confirmPassword
      const ChangePasswordResponse = await postCaller(`api/v1/users/changePasswordAgent?id=${updatePassData.id}`, values)
      if (ChangePasswordResponse.success === true) {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          background: "#4cbb17",
          color: "white",
          showConfirmButton: false,
          timer: 2000,
          width: 400,
          padding: ".5rem"
        })

        Toast.fire({
          icon: 'success',
          title: ChangePasswordResponse.message,
          background: 'white',
          color: 'green'
        }).then(function () {
          navigate('/user')
        })
      }
      else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          background: "red",
          color: "white",
          showConfirmButton: false,
          timer: 2000,
          width: 400,
          padding: ".5rem"
        })

        Toast.fire({
          icon: 'error',
          iconColor: "white",
          title: ChangePasswordResponse.message
        })
      }

    },
  });

  const checkButtonState = () => {
    let flag = true;
  
    for (const key in formik?.values) {
      if (formik?.values[key] !== "") {
        flag = false;
      } else {
        flag = true;
        break;
      }
    }

 if (!flag && formik?.values?.confirmPassword !== formik?.values?.password) return flag;
  }
  
  return (
    <>
    {/* <AgentDownline/> */}
     <div className="sport-container">
    <div className="modal-container-one">
    <div className='add-agent-head'>
                <div>
       Change Password <span style={{color:"#018990"}}>{updatePassData.name}</span>
                </div>
               <div  className="ai-close">
               <AiOutlineClose onClick={() => navigate('/user') }
                   
                />
               </div>
            </div>
             <form onSubmit={formik.handleSubmit}>
                <div className='agent-add-form'>
                <div className="agent-input-body">
                    <label htmlFor="date"> NEW PASSWORD <span style={{
                        color: 'red'
                    }}>*</span> </label> <br />
                    <input type="password" autoComplete="off" name='password' onBlur={formik.handleBlur} value={formik.values.password ||""} onChange={formik.handleChange} className='agent_input' />
                    {formik.errors.password && formik.touched.password && <span className="error" style={{ color: "red" }}>
              {formik.errors.password}
            </span>}
                    
                </div>
                <div className="agent-input-body">
                    <label htmlFor="date"> CONFIRM PASSWORD <span style={{
                        color: 'red'
                    }}>*</span> </label> <br />
                    <input type="password" autoComplete="off" name='confirmPassword' value={formik.values.confirmPassword ||""} onBlur={formik.handleBlur} onChange={formik.handleChange}  className='agent_input' />
                    {formik.errors.confirmPassword && formik.touched.confirmPassword && <span className="error" style={{ color: "red" }}>
              {formik.errors.confirmPassword}
            </span>}
                </div>
                <div className="agent-input-body">
                    <label htmlFor="date"> YOUR PASSWORD <span style={{
                        color: 'red'
                    }}>*</span> </label> <br />
                   <input type="password" autoComplete="off" name='managerPassword' onBlur={formik.handleBlur} value={formik.values.managerPassword} onChange={formik.handleChange} className="agent_input" placeholder='Password'  />
                   {formik.errors.managerPassword && formik.touched.managerPassword && <span className="error" style={{ color: "red" }} >
              {formik.errors.managerPassword}
            </span>}
            
                </div>
                <div className="change-password-container">
               <Button disabled={checkButtonState()} type='submit' name="Submit" className="submit" />
               </div>
            </div>
            </form>
    </div>
            </div>
    </>
  )
}

export default UserChangePassword