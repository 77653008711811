import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import '../user/user.css'
import { MdDelete, MdEdit } from 'react-icons/md'
import Button from '../../component/button/Button';
import Pagination from '../../component/pagination/Pagination';
import ToogleButton from '../../component/toggleButton/ToogleButton';
import { deleteCaller,getCaller, updateCaller } from '../../services/api';
import CONSTANTS from '../../utilities/constants';

const ShowAgent = () => {
    const [allAgents, setAllAgents] = useState([])
    const [searchField, setSearchField] = useState('');
    const [totalPage, setTotalPage] = useState(0)
    const [fetchedPages, setFetchedPages] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate()
    let userType = localStorage.getItem('user_role').toLowerCase();
    const limit = 100;
    const showAllAgents = async (page=1) => {
        if (fetchedPages[page]) {
            setAllAgents(fetchedPages[page]);
            return;
        }
        //console.log("page",page)
        const agentShowResponse = await getCaller(`api/v1/users/showAgents?${page}&limit=${limit}`)
        setAllAgents(agentShowResponse.data)
        setTotalPage(agentShowResponse.pagination?.totalPages)
        setFetchedPages((prev) => ({ ...prev, [page]: agentShowResponse.data }));
    }
    const handlePageChange = async(page) => {
 
        if (page >= 1 && page <= totalPage) {
            setCurrentPage(page);
            await showAllAgents(page)
        }
        
      };
    useEffect(() => {
        if (userType === 'manager') {
            showAllAgents()
        }
    }, [userType])

    const onSearchChange = (e) => {
        setSearchField(e.target.value);
    };

    const filteredAgents = allAgents
        .filter((Agents) => {
            return (
                Agents.name.toLowerCase().includes(searchField.toLowerCase()) ||
                Agents.user_id.toLowerCase().includes(searchField.toLowerCase())
            );
        });
    
    const AlertMessage = (declareResponse , message)=>{
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 2000,
            width: 300,
            padding: ".5rem",
            background: "#4cbb17",
            color: "white",
        })
        Toast.fire({
            icon: declareResponse.success ? 'success' : 'warning',
            title: message,
            background: 'white',
            color: declareResponse.success ? 'green' : 'red',
        });  
    }

    const updateFlags = async (field, id, status) => {
        let agent = allAgents.find(a => parseInt(a.id) === parseInt(id));
        agent[field] = status;
        agent = {...agent};
        setAllAgents([...allAgents]);
        
        let declareResponse 
        let message;

        if(field === 'sus_time' && !status){
            return AlertMessage('suspend updated','suspend should not be empty !')
        }

        declareResponse = await updateCaller(`api/v1/users/${id}`, {[field]: status})

        switch (field) {
            case 'allow_declare_result':
            case 'show_bet':
            case 'show_book':
              message = CONSTANTS.AGENT_FIELDS[field].concat(status ? " Enabled" : " Disabled");
              break;
            case 'sus_time':
                message = declareResponse ?  'suspend updated' : 'suspend should not be empty !';
                break;
            default:
              message = declareResponse.success ? status ? 'Locked' : 'Unlocked' : 'Failed!'; 
              break;
        }
            AlertMessage(declareResponse,message)
                      
    }

    const handleDeleteUser= async (item)=>{
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to Delete this User (${item.user_id})`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            allowOutsideClick: () => Swal.isLoading()
          }).then(async (result) => {
            if (result.isConfirmed) {
            const res = await deleteCaller(`api/v1/users/deleteUser?id=${item.id}&user_id=${item.user_id}`)
              if(res.success){
                Swal.fire({
                    title: 'Submitted',
                   text: 'Your User Deleted Successfully',
                   icon: 'success',
                   showConfirmButton:false,
                   timer:1000,
                   background: 'white',
                   color: 'green',
                }).then(response=>{
                    if(response){
                        setAllAgents(allAgents.filter((el)=>el.id!==item.id));
                    }
                  })
              }
            } 
          })
    }
    
    return (
        <>
            <div className="table-border">
                <div className="wrapper-body">
                    <div className="wrapper-content">
                        <div className="">
                            {/* <input type="search" name="" id="" placeholder='Search User' className='result-input' /> */}
                            <input type="search" autoComplete="off" name="" id="" placeholder='Search User' className='result-input' onChange={onSearchChange} value={searchField || ''}/>
                        </div>
                        <div className='agent-btn-container'>
                            {/* <div className="print-btn">
                                <BiDownload style={{ fontSize: "1.5rem", color: "white" }} />
                            </div> */}
                            <div>
                                <Button className={'submit'} name={'ADD AGENT'} type={'button'} onClick={() => navigate('/create_agent')} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ overflowX: "auto" }}>
                    <table width="100%" >
                        <thead>
                            <tr className='custom-row'>
                                <th> # </th>
                                <th> UID </th>
                                <th> NAME </th>
                                <th> P </th>
                                <th> RESULTS </th>
                                <th> SHOW BETS </th>
                                <th> SHOW BOOKS </th>
                                <th> SUSPEND </th>
                                <th> LOCK </th>
                                <th> ACTIONS </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredAgents?.length > 0 ?
                                    filteredAgents?.map((el, i) => (
                                        <tr className='custom-row' key={i}>
                                            <td data-label="#"> {el.id} </td>
                                            <td data-label="UID"> {el.user_id} </td>
                                            <td data-label="NAME"> {el.name} </td>
                                            <td data-label="P" style={{ color: "#11a88d", cursor: "pointer" }} onClick={() => navigate("/user_change_password", {
                                                state: {
                                                    passData: el
                                                }
                                            })} > P </td>
                                            <td data-label="allow_declare_result">
                                                <div className="toggle-password">
                                                    <ToogleButton defaultChecked={el.allow_declare_result ? true : false} onChange={(value) => updateFlags("allow_declare_result", el.id, value? 1: 0)} />
                                                </div>
                                            </td>
                                            <td data-label="show_bet">
                                                <div className="toggle-password">
                                                    <ToogleButton defaultChecked={el.show_bet? true : false} onChange={(value) => updateFlags("show_bet", el.id, value? 1: 0)} />
                                                </div>
                                            </td>
                                            <td data-label="show_book">
                                                <div className="toggle-password">
                                                    <ToogleButton defaultChecked={el.show_book? true : false} onChange={(value) => updateFlags("show_book", el.id, value? 1: 0)} />
                                                </div>
                                            </td>
                                            <td data-label="sus_time">
                                                <div className="toggle-password">
                                                    <input type="number" autoComplete="off" value={el.sus_time} onChange={(event) => updateFlags("sus_time", el.id, event.target.value)} />
                                                </div>
                                            </td>
                                            <td data-label="is_locked">
                                                <div className="toggle-password">
                                                    <ToogleButton defaultChecked={el.is_locked? true : false} onChange={(value) => updateFlags("is_locked", el.id, value? 1: 0)} />
                                                </div>
                                            </td>
                                            <td>
                                                <div className='user-btn-action'>
                                                    <div className='edit-btn' onClick={() => navigate('/user_create_form',
                                                        { state: { passData: el } })}>
                                                        <MdEdit style={{ fontSize: '1.5rem', color: 'white'}}/>
                                                    </div>  
                                                
                                                    <button className='edit-btn delete-btn' onClick={()=> handleDeleteUser(el) }>
                                                        <MdDelete style={{ fontSize: '1.5rem', color: 'white'}}/>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )) : <tr className=''><td className='no-data-coloumn' align='center' colSpan={10}>No Data</td></tr>
                            }

                        </tbody>
                    </table>
                </div>
            </div>
            <Pagination
                    currentPage={currentPage}
                    totalPages={totalPage}
                    onPageChange={handlePageChange}
                />
        </>
    )
}

export default ShowAgent